import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { jwtDecode } from 'jwt-decode';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import Report from './Report';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './HomePage.css';

const Portfolio = () => {
  const [showHome, setShowHome] = useState(false);

  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getIdToken();
  const decodedToken = jwtDecode(accessToken);
  const userPermissions = decodedToken.groupsFusion;

  useEffect(() => {
    setTimeout(() => {
      setShowHome(true);
    }, 10);
  }, []);

  return (
    <div style={{ height: 500, width: '100%', position: 'relative' }}>
      <Header title="Fusion.Portfolio" />
      <div className={'flex w-full justify-start items-start'}>
        <SideBar active={'portfolio'} userPermissions={userPermissions} />
        <div className={'flex pb-32 flex-col items-center w-full h-full ml-[80px] bg-gray-50'}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className={showHome ? 'fade-in' : 'invisible'}>
              <Report />
            </div>
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
