import React, { memo, useEffect, useState } from 'react';
import NoData from './NoData';
import dayjs from 'dayjs';
import { WppSelect, WppListItem } from '@wppopen/components-library-react';
import { ResponsiveBar } from '@nivo/bar'
import './styles/AverageConversionRate.css';

const AverageConversionRate = (props) => {

  const { AverageConversionRateData } = props;

  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [data, setData] = useState([]);

  useEffect(() => {
    if (AverageConversionRateData) {
      setYears(AverageConversionRateData.map(item => item.year));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (years.length) {
      setSelectedYear(Math.max(...years));
    } else {
      setSelectedYear(dayjs().year());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [years]);

  useEffect(() => {
    if (AverageConversionRateData) {
      const selectedData = AverageConversionRateData.filter((a) => a.year === selectedYear);

      if (selectedData.length) {
        setData(selectedData[0].data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear])

  const handleChange = (event) => {
    setSelectedYear(event.detail.value);
  }

  return (
    <>
      <div className="container">
        <div className="header">
          <div className='h2'>
            Average Conversion Rate
          </div>
          <div className='dropdown-wrap'>
            <div className="select-container">
              <WppSelect
                onWppChange={handleChange}
                placeholder={selectedYear}
                value={selectedYear}
              >
                {years.map(year => (
                  <WppListItem value={year} key={year}>
                    <p slot="label">{year}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            </div>
          </div>
        </div>
        {
          data?.length
          ?
            <div className="chart-container">
              <ResponsiveBar
                data={data}
                keys={['value']}
                indexBy="x"
                colors='#C880B2'
                margin={{ top: 30, right: 0, bottom: 40, left: 0 }}
                padding={0.2}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                theme={{
                  axis: {
                    ticks: {
                      text: {
                        fontSize: 12, // Label text size
                        fill: "#444444",
                      }
                    }
                  },
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 0,
                  tickPadding: 5,
                  tickRotation: 0,
                  renderTick: (
                    {
                      textAnchor,
                      textBaseline,
                      value,
                      x,
                      y
                    }
                  ) => {
                    // ticks and labels below the bars
                    const name = value.split(": ");
                    return (
                      <g
                        transform={`translate(${x},${y})`}
                      >
                        <line x1={0} y1={0} x2={0} y2={5} stroke="black" />
                        <text
                          className="tick-text"
                          alignmentBaseline={textBaseline}
                          textAnchor={textAnchor}
                        >
                          <tspan x={0} dy={20}>{name[0]}{name[1] ? ':' : null}</tspan>
                          {name[1] ? <tspan x={0} dy={15}>{name[1]}</tspan> : null}
                        </text>
                      </g>
                    )
                  }
                }}
                axisLeft={null}
                enableGridY={true}
                gridYValues={[0, .01]}
                enableLabel={false}
                layers={[
                  'grid',
                  'axes',
                  'bars',
                  'markers',
                  'legends',
                  'annotations',
                  ({ bars }) => {
                    return (
                      // labels above the bars
                      <g>
                        {bars.map(({ width, height, x, y, data, key }) => {
                          return (
                            <text
                              className="bar-label"
                              transform={`translate(${width / 2 + x - 10}, ${y - 15})`}
                              key={key}
                            >
                              {data.value ? `${data.value}%` : null}
                            </text>
                          );
                        })}
                      </g>
                    );
                  },
                ]}
              />
            </div>
          : <NoData />
        }
      </div>
    </>
  )
}
export default memo(AverageConversionRate);
