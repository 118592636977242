import { useMemo } from "react";

const TICK_LENGTH = 7;

const Axes = ({ xScale, yScale, x, y, width, height }) => {

  const yRange = yScale.range();
  const yTicks = useMemo(() => {
    const numberOfTicksTarget = 10;
    return yScale.ticks(numberOfTicksTarget).map((value) => ({
      value,
      yOffset: yScale(value),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yScale]);
  
  const xRange = xScale.range();
  const xTicks = useMemo(() => {
    const numberOfTicksTarget = 10;
    return xScale.ticks(numberOfTicksTarget).map((value) => ({
      value,
      xOffset: xScale(value),
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xScale]);

  const yAxisLabelX = -45;
  const yAxisLabelY = height / 2;
  const xAxisLabelX = width / 2;
  const xAxisLabelY = height + 38;

  return (
    <>
      {/* Y-axis */}
      <path
        d={["M", 0, yRange[0], "L", 0, yRange[1]].join(" ")}
        fill="none"
        stroke="currentColor"
      />
      {/* Ticks and labels */}
      {yTicks.map(({ value, yOffset }) => (
        <g key={value} transform={`translate(0, ${yOffset})`}>
          <line x2={-TICK_LENGTH} stroke="currentColor" />
          <text
            key={value}
            y={3}
            style={{
              fontSize: "10px",
              textAnchor: "middle",
              transform: "translateX(-20px)",
            }}
          >
            {value}
          </text>
        </g>
      ))}
      {/* X-axis */}
      <g transform={`translate(0, ${height})`}>
        <path
          d={["M", xRange[0], 0.3, "L", xRange[1], 0.3].join(" ")}
          fill="none"
          stroke="currentColor"
        />
        {/* Ticks and labels */}
        {xTicks.map(({ value, xOffset }) => (
          <g key={value} transform={`translate(${xOffset}, 0)`}>
            <line y2={TICK_LENGTH} stroke="currentColor" />
            <text
              key={value}
              style={{
                fontSize: "10px",
                textAnchor: "middle",
                transform: "translateY(20px)",
              }}
            >
              {`${Math.round(value * 100)}%`}
            </text>
          </g>
        ))}
      </g>
      <g>
        {/* MEDIAN vertical and horizontal lines */}
        {x && y
          ?
            <>
              <line
                x1={0}
                y1={y}
                x2={width}
                y2={y}
                stroke="#ababab"
                strokeDasharray="2"
              />
              <line
                x1={x}
                y1={0}
                x2={x}
                y2={height}
                stroke="#ababab"
                strokeDasharray="2"
              />
            </>
          : null
        }
      </g>
      {/* Axis labels */}
      <g transform={`translate(${yAxisLabelX}, ${yAxisLabelY})`}>
        <text
          fill="#121619"
          fontSize={14}
          fontWeight={600}
          textRendering={"optimizeLegibility"}
          dominantBaseline={"Auto"}
          textAnchor={"middle"}
          transform={"rotate(-90)"}
        >
          Growth Potential
        </text>
      </g>
      <g>
        <text
          x={xAxisLabelX}
          y={xAxisLabelY}
          fill="#121619"
          fontSize={14}
          fontWeight={600}
          textRendering={"optimizeLegibility"}
          dominantBaseline={"Hanging"}
          textAnchor={"middle"}
        >
          Retailer Maturity
        </text>
        </g>
    </>
  );
};
export default Axes;