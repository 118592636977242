import _ from 'lodash';
import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { WppButton, WppFileUpload } from '@wppopen/components-library-react';
import DropSelectWppOpenByID from '../../../../components/DropSelectWppOpenByID';
import Image from '../../../assets/Image';
import X from '../../../assets/X';
import DownwardPointer from '../../../assets/DownwardPointer';

const EditRetailer = (props) => {

  const { 
    baseApi,
    accessToken,
    retailersList,
    markets,
    handleDialogClose,
    setSnackMessage,
    setSnackOpen,
    setIsLoading,
    setScoredOnly,
    setDialogOpen,
    setNewRetailers } = props;

  const [retailerValueEdit, setRetailerValueEdit] = useState({ logoPath: '', retailerId: '', retailer: '' });
  const [retailerResponders, setRetailerResponders] = useState([]);
  const [selectedImage, setSelectedImage] = useState();

  const imageChange = (e) => {
    const image = e.detail.value[0];
    if (image) {
      setSelectedImage(image);
    } else {
      setSelectedImage();
    }
  };

  const updateRetailer = () => {

    const responderBody = [];
    _.forEach(retailerResponders, id => {
      const code = markets.filter(a => a.id === id)[0]?.code;
      responderBody.push({ name: code, type: 'markets' })
    });
 
    let formData;
    formData = new FormData();
    formData.append("partner", JSON.stringify({ name: retailerValueEdit.retailer, type: "retailers" }));
    formData.append("responders", JSON.stringify(responderBody));
    if (selectedImage instanceof Blob) {
      formData.append("file", selectedImage, selectedImage.name);
    }
    
    setIsLoading(true);

    fetch(`${baseApi}/partner/create_or_update`, {
      method: 'POST',
      headers: {'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}`},
      body: formData,
    })
    .then(response => {
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }
      return response.json()
    })
    .then(data => {
      setIsLoading(false);
      if (data.Message) {
        setSnackMessage(data.Message)
        setSnackOpen(true);
      }
      setRetailerValueEdit({ logoPath: '', retailerId: '', retailer: '' });
      setRetailerResponders([]);
      setSelectedImage();

      setScoredOnly(false);
      setDialogOpen(false);
      setNewRetailers(data.created);
    })
    .catch((error) => {
      setSnackMessage(error.message);
      setSnackOpen(true);
      setIsLoading(false);
    });
  }

  const getMarketsTypes = (retailer) => {
    if (retailer.retailer) {
      setRetailerResponders(retailer.markets.map(item => item.logoId));
    } else {
      setRetailerResponders([]);
    }
  };

  const selectedImageRemove = () => {
    setSelectedImage();
    retailerValueEdit.logoPath = '';
  }

  const imageError = () => {
    if (typeof selectedImage.name !== 'string') {
      setSelectedImage();
      retailerValueEdit.logoPath = '';
    }
  }

  const updateRetailerDisabled = !retailerValueEdit || !retailerValueEdit.retailerId || !retailerResponders.length || retailerResponders[0] === -1;

  return (
    <>
      <h3 slot="header">Edit Market</h3>
      <div slot="body" className="w-auto">
        <div className="text-sm font-normal">To edit a retailer, please select a retailer name in the dropdown and update the markets and/or logo as necessary.</div>
        <div className="mt-6 text-sm font-semibold">Retailer Name</div>
        <div className="mt-2"> 
          <Autocomplete
            id="editRetailerList"
            autoSelect
            options={retailersList}
            value={retailerValueEdit}
            getOptionLabel={(option) => option.retailer}
            renderInput={(params) => (
              <TextField 
                {...params}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    height: "42px",
                    fontSize: '15px',
                  },
                  "& .MuiInputBase-input": {
                    marginTop: "-6px",
                  },
                }}
              />
            )}
            onChange={(event, newValue) => {
              if (newValue !== null) {
                setRetailerValueEdit(newValue);
                getMarketsTypes(newValue);
                setSelectedImage(newValue.logoPath)
              }
            }}
            isOptionEqualToValue={(option, value) => value.retailerId === null || value.retailerId === "" || option.retailerId === value.retailerId}
            popupIcon={<DownwardPointer />}
          />
        </div>
        {retailerValueEdit.retailerId &&
          <>
            <div className="mt-6 text-sm font-bold text-red-600">Warning: All Markets Will Be Affected.</div>
            <div className="text-sm font-normal text-red-600">Only deselect a market if you’d like to remove it.</div>
            <div className="mt-2 text-sm font-semibold">Markets</div>
            <div className="mt-2">
              <DropSelectWppOpenByID
                type="multiple"
                required
                items={markets}
                id="id"
                value="name"
                selection={retailerResponders}
                setSelection={setRetailerResponders}
              />
            </div>
            <div className="mt-6 text-sm font-semibold">Retailer logo</div>
            <div className="mt-2 flex flex-col">
              {!selectedImage
              ?
                <WppFileUpload
                  acceptConfig={{
                    'image/png': ['.png'],
                  }}
                  size={1}
                  multiple={false}
                  onWppChange={e => imageChange(e)}
                  className="w-full"
                />
              :
                <>
                  <div className="flex justify-center">
                    {retailerValueEdit.logoPath
                    ?
                      <img
                        alt="partner-logo"
                        style={{maxWidth: '160px', maxHeight: '75px'}}
                        src={'https://storage.googleapis.com/fusion-email-image-bucket/partner-logos/' + retailerValueEdit.logoPath + '.png'}
                        onError={imageError}
                      />
                    :
                      <img
                        src={URL.createObjectURL(selectedImage)}
                        style={{maxWidth: '160px', maxHeight: '75px'}}
                        alt="Thumb"
                      />
                    }
                  </div>
                  <div className="flex justify-between mt-2 items-center bg-gray-100 p-1">
                    <div className="flex items-center">
                      <Image />
                      <span className="pl-2">{retailerValueEdit.logoPath ? retailerValueEdit.retailer : selectedImage.name}</span>
                    </div>
                    <button onClick={selectedImageRemove}><X /></button>
                  </div>
                </>
              }
            </div>
          </>
        }
      </div>
      <div slot="actions" className="flex justify-end">
        <WppButton variant="secondary" size="s" onClick={() => {
          setRetailerValueEdit({ logoPath: '', retailerId: '', retailer: '' });
          setRetailerResponders([]);
          setSnackOpen(false);
          handleDialogClose();
        }}>Cancel</WppButton>
        <WppButton disabled={updateRetailerDisabled} variant="primary" size="s" onClick={updateRetailer} className="ml-4">Update Retailer</WppButton>
      </div>
    </>
  )
}
export default EditRetailer;