import DropSelectWppOpen from '../../components/DropSelectWppOpen';
import { DatePicker } from '@mui/x-date-pickers';
import {
  MuiDatePickerTitle,
  MuiDatePickerGeneral,
  WppOpenOverrides,
} from './FilterStyles';
import { UPDATE_VIEW_STYLES } from './styles';

const Filter = (props) => {
  const {
    clientOptions,
    selectedClient,
    setSelectedClient,
    countryOptions,
    selectedCountry,
    setSelectedCountry,
    brandOptions,
    selectedBrands,
    setSelectedBrands,
    classificationOptions,
    selectedClassifications,
    setSelectedClassifications,
    reportTypeOptions,
    selectedReportType,
    setSelectedReportType,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    updateViewButtonActive,
    UpdateView,
  } = props;

  return (
    <div
      className={
        'filters flex-direction-column margin-t-42 padding-lr-64 filters-wrap'
      }
    >
      <div className={'col-1-1 flex-justify-start'}>
        <div className={'padding-16 flex-justify-start col-1-4'}>
          <DropSelectWppOpen
            type="single"
            title="Client"
            items={clientOptions}
            selection={selectedClient}
            setSelection={setSelectedClient}
            style={WppOpenOverrides}
            className="w-60 text-[14px]"
          />
        </div>
        <div className={'padding-16 flex-justify-start col-1-4'}>
          <DropSelectWppOpen
            type="single"
            title="Country"
            items={countryOptions}
            selection={selectedCountry}
            setSelection={setSelectedCountry}
            className="w-[230px] text-[14px]"
          />
        </div>
        <div className={'padding-16 flex-justify-start col-1-4'}>
          <DropSelectWppOpen
            type="multiple"
            title="Brand(s)"
            items={brandOptions}
            selection={selectedBrands}
            setSelection={setSelectedBrands}
            className="w-[230px] text-[14px]"
          />
        </div>
        <div className={'padding-16 flex-justify-start col-1-4'}>
          <DropSelectWppOpen
            type="multiple"
            title="Classification"
            items={classificationOptions}
            selection={selectedClassifications}
            setSelection={setSelectedClassifications}
            className="w-60 text-[14px]"
          />
        </div>
      </div>
      <div className={'col-1-1 flex-justify-start flex-align-end'}>
        <div className={'padding-16 flex-justify-start col-1-4'}>
          <DropSelectWppOpen
            type="single"
            title="Graph"
            items={Object.keys(reportTypeOptions).sort()}
            selection={selectedReportType}
            setSelection={setSelectedReportType}
            className="w-60 text-[14px]"
          />
        </div>
        <div
          className={'padding-16 flex-direction-column flex-align-start col-1-4'}
        >
          <p style={MuiDatePickerTitle}>Starting Month</p>
          <DatePicker
            label=""
            value={selectedStartDate}
            views={['year', 'month']}
            format={'MMM/YY'}
            slotProps={{ textField: { size: 'small' } }}
            onChange={(date) => setSelectedStartDate(date)}
            sx={MuiDatePickerGeneral}
            className="w-[230px]"
          />
        </div>
        <div
          className={
            'padding-16 flex-direction-column flex-align-start col-1-4'
          }
        >
          <p style={MuiDatePickerTitle}>Ending Month</p>
          <DatePicker
            label=""
            value={selectedEndDate}
            views={['year', 'month']}
            format={'MMM/YY'}
            slotProps={{ textField: { size: 'small' } }}
            onChange={(date) => setSelectedEndDate(date)}
            sx={MuiDatePickerGeneral}
            className="w-[230px]"
          />
        </div>
        <div
          className={
            'padding-16 flex-direction-column flex-align-start col-1-4 filter-button-wrap'
          }
        >
          <div onClick={UpdateView} style={updateViewButtonActive ? UPDATE_VIEW_STYLES.active : {...UPDATE_VIEW_STYLES.active, ...UPDATE_VIEW_STYLES.disabled}}>Update view</div>
        </div>
      </div>
    </div>
  );
};
export default Filter;
