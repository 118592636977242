import _ from 'lodash';
import { useState, useEffect } from 'react';
import { WppTab, WppTabs } from '@wppopen/components-library-react';
import FlowVizOverview from './FlowVizOverview';
import FlowVizRetailerSpecific from './FlowVizRetailerSpecific';
import { ModalSpinner } from "../../../components/ModalSpinner";
import { INITIAL_SEGMENT } from '../Utils/constants';

export const FlowViz = ({ insightIdDB, flowSetId, insightName, setInsightName, accessToken, selectedFlowId }) => {

  const baseApi = process.env.REACT_APP_API_ENDPOINT;

  const [currentTab, setCurrentTab] = useState('overview');
  const [segments, setSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState(0);
  const [retailersData, setRetailersData] = useState({});
  const [retailers, setRetailers] = useState([]);
  const [filteredRetailers, setFilteredRetailers] = useState([]);
  
  const [treemapData, setTreemapData] = useState({});
  const [heatmapData, setHeatmapData] = useState([]);
  const [barchartData, setBarchartData] = useState([]);
  const [scatterplotData, setScatterplotData] = useState([]);
  const [piechartData, setPiechartData] = useState([]);
  const [barchartRetailerData, setBarchartRetailerData] = useState([]);
  
  const [selectedRetailer, setSelectedRetailer] = useState('');
  const [specificRetailerData, setSpecificRetailerData] = useState({})
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');
  
  const [isSpinnerSpinning, setIsSpinnerSpinning] = useState(false);

  useEffect(() => {
    if (insightIdDB) {
      setIsSpinnerSpinning(true);
      fetch(`${baseApi}/segment/insight/${insightIdDB}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status}`);
        }
        return response.json()
      })
      .then(data => {
        setInsightName(data.data.name);
        setSegments(data.data.segments);
        setSelectedSegment(data.data.segments.filter(a => a.segment === INITIAL_SEGMENT.GENERAL)[0].id);
        setIsSpinnerSpinning(false);
      })
      .catch((error) => {
        console.log('API FETCH ERROR: ', error.message);
        setIsSpinnerSpinning(false);
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightIdDB])

  useEffect(() => {
    if (selectedSegment && (flowSetId || selectedFlowId)) {
      const payload = {
        "flowSetId": parseInt(flowSetId) ?? selectedFlowId,
        "segmentIds": [selectedSegment]
      }
      setIsSpinnerSpinning(true);
      
      fetch(`${baseApi}/insights/v2/${insightIdDB}/dashboard`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
        body: JSON.stringify(payload)
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status}`);
        }
        return response.json()
      })
      .then((data) => {
        setRetailersData(data.data);
        setRetailers(data.data);
        setFilteredRetailers(data.data.slice(0, 5).map(a => a.partnerId));
        setSelectedRetailer(data.data[0].partnerId)
        setIsSpinnerSpinning(false);
      })
      .catch((error) => {
        console.log('API FETCH ERROR: ', error.message);
        setIsSpinnerSpinning(false);
        setRetailersData({});
        setRetailers([]);
        setFilteredRetailers([]);
        setSelectedRetailer('')
      })
    } else {
      setRetailersData({});
      setRetailers([]);
      setFilteredRetailers([]);
      setSelectedRetailer('')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSegment])

  useEffect(() => {
    if (retailersData.length) {
      // TREEMAP
      const children = [];
      _.forEach(retailersData, a => {
        if (a.score > 0) {
          children.push({ name: a.partner, val: a.score })
        }
      })
      setTreemapData({ name: "retailers", children: children })
    }
  }, [retailersData])

  useEffect(() => {
    chartDataTransform();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRetailers])

  const chartDataTransform = () => {
    if (retailersData.length) {
      const filteredRetailerData = retailersData.filter(a => filteredRetailers.includes(a.partnerId));

      // HEATMAP
      const heatmap = [];
      const categorySet = new Set();
      _.forEach(filteredRetailerData, a => {
        if (a !== undefined) {
          Object.keys(a.categories).forEach(item => categorySet.add(item));
        }
      })
      const categories = Array.from(categorySet);
      let data;
      _.forEach(filteredRetailerData, a => {
        data = []
        _.forEach(categories, category => {
          if (a.categories[category]?.score !== undefined) {
            data.push({ x: category, y: Math.round(a.categories[category].score) })
          }
        })
        heatmap.push({ id: a.partner, data: data })
      })
      setHeatmapData(heatmap);

      // BARCHART
      const barchart = []
      _.forEach(filteredRetailerData, a => {
        if (a.traffic !== undefined && a.revenue !== undefined) {
          barchart.push({ retailer: a.partner, traffic: a.traffic, revenue: a.revenue })
        }
      })
      setBarchartData(barchart);

      // SCATTERPLOT
      const selected = [];
      const unselected = [];
      _.forEach(retailersData, a => {
        if (a.score !== undefined && a.averageGrowthRate !== undefined) {
          if (filteredRetailers.includes(a.partnerId)) {
            selected.push({ retailer: a.partner, retailer_score: a.score, growth: a.averageGrowthRate });
          } else {
            unselected.push({ retailer: a.partner, retailer_score: a.score, growth: a.averageGrowthRate });
          }
        }
      })
      setScatterplotData([{ id: "SELECTED", data: selected }, { id: "UNSELECTED", data: unselected }])
    } else {
      setTreemapData({});
      setHeatmapData([]);
      setBarchartData([]);
      setScatterplotData([]);
      setPiechartData([]);
      setBarchartRetailerData([]);
    }
  }

  // SPECIFIC RETAILER
  useEffect(() => {
    if (selectedRetailer) {
      const payload = {
        partner: selectedRetailer,
        flowSetId: parseInt(flowSetId) ?? selectedFlowId,
        segmentId: selectedSegment,
      };
      
      setIsSpinnerSpinning(true);

      fetch(`${baseApi}/insights/v2/raw_score`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
        body: JSON.stringify(payload)
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status}`);
        }
        return response.json()
      })
      .then(data => {

        const dataAPI = data.data;
        setSpecificRetailerData(dataAPI);

        let categoriesAPI = Object.keys(dataAPI);
        categoriesAPI = [...categoriesAPI, 'All'];
        setCategories(categoriesAPI);
        setSelectedCategory('All');

        // PIECHART
        let total = 0;
        _.forEach(dataAPI, a => {
          total += +a.score;
        });
        const piedata = [];
        if (total > 0) {
          _.forEach(categoriesAPI, category => {
            if (category !== 'All') {
              piedata.push({ id: category, label: category, value: Math.round(+dataAPI[category]?.score/total * 100) })
            }
          })
        }
        setPiechartData(piedata);

        // BARCHART - RETAILER SCORE
        BartchartRetailer(dataAPI);

        setIsSpinnerSpinning(false);
      })
      .catch((error) => {
        console.log('API FETCH ERROR: ', error.message);
        setIsSpinnerSpinning(false);
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRetailer])

  const BartchartRetailer = (dataAPI) => {

    let categoriesAPI = Object.keys(dataAPI);
    categoriesAPI = [...categoriesAPI, 'All'];

    // BARCHART - RETAILER SCORE
    const bardata = [];
    _.forEach(categoriesAPI, category => {
      if (category !== 'All' && dataAPI[category]?.score !== undefined) {
        bardata.push({ id: category, val: dataAPI[category]?.score, benchmark: dataAPI[category]?.benchmark_score })
      }
    })
    setBarchartRetailerData({ type: (selectedCategory === 'All' ? 'category' : 'subcategory'), data: bardata })
  }

  useEffect(() => {
    if (selectedCategory !== 'All') {
      const selectedCategoryData = specificRetailerData[selectedCategory];
      const subcategories = Object.keys(selectedCategoryData.subcategories);
      const bardata = [];
      _.forEach(subcategories, subcategory => {
        bardata.push({ id: subcategory, val: selectedCategoryData.subcategories[subcategory]?.score, benchmark: selectedCategoryData.subcategories[subcategory]?.benchmark_score })
      })
      setBarchartRetailerData({ type: 'subcategory', data: bardata })
    } else {
      BartchartRetailer(specificRetailerData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory])

  const handleTabChange = (event) => {
    setCurrentTab(event.detail.value);
  };

  return (
    <div className={'flex w-full bg-gray-50 mt-4 p-16 flex-col'}>
      {isSpinnerSpinning ? (
        <ModalSpinner/>
      ) : null}
      <div className={'flex w-full'}>
        <div className={'flex justify-start items-end w-1/2 text-[28px] text-gray-900  font-bold'}>{insightName ? insightName : 'Retail Media Capabilities'}</div>
        <div className={'flex justify-end items-end w-1/2 mb-1'}>
          <WppTabs value={currentTab} onWppChange={handleTabChange}>
            <WppTab value="overview" className={'w-48'}>Overview</WppTab>
            <WppTab value="retailer specific" className={'w-48'}>Retailer Specific</WppTab>
          </WppTabs>
        </div>
      </div>
      {currentTab === 'overview' ? (
        <FlowVizOverview
          segments={segments}
          selectedSegment={selectedSegment}
          setSelectedSegment={setSelectedSegment}
          treemapData={treemapData}
          retailers={retailers}
          filteredRetailers={filteredRetailers}
          setFilteredRetailers={setFilteredRetailers}
          heatmapData={heatmapData}
          barchartData={barchartData}
          scatterplotData={scatterplotData}
        />
      ) : (
        <FlowVizRetailerSpecific
          selectedSegmentName={segments.filter(a => a.id === selectedSegment)[0].segment}
          retailers={retailers}
          selectedRetailer={selectedRetailer}
          setSelectedRetailer={setSelectedRetailer}
          categories={categories}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          piechartData={piechartData}
          barchartRetailerData={barchartRetailerData}
        />
      )}
    </div>
  );
};
export default FlowViz;
