import React, { useState } from 'react';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import FlowTabs from './Components/FlowTabs';
import FlowModal from '../../components/Modal';
import { useOktaAuth } from '@okta/okta-react';
import { jwtDecode } from 'jwt-decode';

const FlowPage = () => {
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getIdToken();
  const decodedToken = jwtDecode(accessToken);
  const userPermissions = decodedToken.groupsFusion;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFullModal, setIsFullModal] = useState(true);
  const [modalContent, setModalContent] = useState(null);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className={'page-wrapper h-full'} style={{ marginLeft: 0 }}>
        <Header title="Fusion.Flow" />
        <div className={'flex w-full flex-row h-full'}>
          <SideBar active={'flow'} userPermissions={userPermissions} />
          <div className={'flex p-16 flex-col items-center bg-gray-50 w-full h-full ml-[80px]'}>
            <FlowTabs
              handleOpenModal={handleOpenModal}
              handleCloseModal={handleCloseModal}
              setModalContent={setModalContent}
              setIsFullModal={setIsFullModal}
              userPermissions={userPermissions}
            />
            <FlowModal
              isModalOpen={isModalOpen}
              handleCloseModal={handleCloseModal}
              modalContent={modalContent}
              setModalContent={setModalContent}
              isFullModal={isFullModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlowPage;
