export const Styles = {
  FlowCardStyle: {
    actions: {
      margin: '-12px -16px 0 0',
    },
    progressIndicator: {
      margin: '24px 0 0 0',
    },
    cardSx: {
      margin: '12px 0 0',
      fontSize: '11px',
      opacity: '.6',
    },
    cardM: {
      fontSize: '15px',
      opacity: '.8',
    },
    cardDescription: {
      margin: '-12px 0 24px',
    },
    cardLine: {
      margin: '0 0 12px',
      border: '1px solid #f1f1f1',
    },
  },
  FlowStepperStyle: {
    stepper: {
      '& .MuiStepLabel-root .Mui-completed': {
        color: '#0014CC', // circle color (COMPLETED)
      },
      '& .MuiStepLabel-root .Mui-active': {
        color: '#0014CC', // circle color (ACTIVE)
      },
    },
    stepperControls: {
      position: 'absolute',
      right: '32px',
      bottom: '32px',
    },
    rfiForm: (screenHeight) => {
      return {
        overflow: 'auto',
        width: 'inherit',
        height: `${screenHeight}px`,
      }
    }
  },
  FlowFilterStyle: {
    location: {
      position: 'absolute',
      left: '128px',
      top: '84px',
    },
  },
  FlowButtonStyle: {
    wppButton: {
      position: 'absolute',
      right: '28px',
      top: '128px',
    },
  },
  FlowTabsStyle: {
    tabs: {
      width: '364px',
      marginTop: '22px',
    },
    box: {
      flexGrow: 1,
      marginTop: '32px',
    },
  },
  FlowViewerStyles: {
    title: {
      padding: '45px 0 0 35px',
      fontSize: '24px',
    },
    toggle: {
      padding: '0 0 0 35px',
    },
    stepper: {
      marginTop: '25px',
      maxWidth: 400,
    },
  },
  FlowViewerCardStyles: {
    box: {
      height: '75px',
      overflow: 'hidden',
      mt: 0,
      textAlign: 'center',
    },
    stepper: {
      margin: '25px 0 0 85px',
      maxWidth: 300,
    },
    buttonBox: {
      height: '50px',
      overflow: 'hidden',
      mt: 2,
      mb: 1,
      textAlign: 'center',
    },
  },
};
