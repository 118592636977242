export const ReportType = {
  ConversionRate_NetPPM: 0,
  Revenue_ConversionRate: 1,
  OnHandInventory_NetPPM: 2,
  Traffic_ConversionRate: 3,
};

export const REPORT_TYPES = {
  ConversionRate_NetPPM: 'ConversionRate_NetPPM',
  Revenue_ConversionRate: 'Revenue_ConversionRate',
  OnHandInventory_NetPPM: 'OnHandInventory_NetPPM',
  Traffic_ConversionRate: 'Traffic_ConversionRate',
};

export const REPORT_TYPE_OPTIONS = {
  'Conversion Rate / Net PPM': REPORT_TYPES.ConversionRate_NetPPM,
  'Revenue / Conversion Rate': REPORT_TYPES.Revenue_ConversionRate,
  'On Hand Inventory / Net PPM': REPORT_TYPES.OnHandInventory_NetPPM,
  'Traffic / Conversion Rate': REPORT_TYPES.Traffic_ConversionRate,
};

export const OutputType = {
  ReportAxis: 0,
  HiddenColumns: 1,
  GenerateXYData: 2,
};
