import React, { memo, useEffect, useState } from "react";
import NoData from './NoData';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { 
  hackGrid,
  SortedDescendingIcon,
  SortedAscendingIcon,
  UnsortedIcon,
  SocialMediaSites,
  abbreviatedNumber,
  numberWithPlusMinus
} from '../../utils'
import logoFacebook from "../../../../icons/Facebook.png";
import logoInstagram from "../../../../icons/Instagram.png";
import logoX from "../../../../icons/Twitter.png";
import { cardContentMargin } from '../../styles'
import './styles/SocialMediaMetrics.css';

const logoRenderer = (params) => {
  if (params.value.toUpperCase() === SocialMediaSites.Facebook.toUpperCase()) {
    return (<span><img alt='facebook' src={logoFacebook} /></span>);
  } else if (params.value.toUpperCase() === SocialMediaSites.Instagram.toUpperCase()) {
    return (<span><img alt='instagram' src={logoInstagram} /></span>);
  } else if (params.value.toUpperCase() === SocialMediaSites.X.toUpperCase()) {
    return (<span><img alt='x' src={logoX} /></span>);
  } 
};

const columnDefs: GridColDef[] = [
  {field: 'Platform', headerName: '', headerClassName: 'grid--header', width: 55, hideSortIcons: true, sortable: false, renderCell: logoRenderer},
  {field: 'Account', width: 140 },
  {field: 'Follower', width: 110, renderCell: (params) => abbreviatedNumber(params.value)},
  {field: 'New Follower', width: 150, renderCell: (params) => numberWithPlusMinus(params.value)},
  {field: 'Activity Score', width: 150 },
];

const SocialMediaMetrics = (props) => {

  const { SocialMediaMetricsData } = props;

  const [rows, setRows] = useState([]);
  
  
  useEffect(() => {
    hackGrid();
  })

  
  useEffect(() => {
    if (SocialMediaMetricsData) {
      setRows(SocialMediaMetricsData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div className='h2' style={{ margin: cardContentMargin }}>
        Social Media Metrics
      </div>
      {
        rows.length
        ?
          <div style={{height: '225px', width: '90%', margin: cardContentMargin }}>
            <DataGridPro
              columns={columnDefs}
              rows={rows}
              getRowId={(row) => row.Platform}
              rowHeight={50}
              disableColumnMenu={true}
              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  textTransform: 'none',
                  fontWeight: '800',
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-iconButtonContainer': {
                  marginLeft: '0',
                  visibility: 'visible !important',
                  width: 'auto !important',
                },
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                  margin: '0'
                },
                '& .MuiDataGrid-cell': {
                  borderBottom: 'none',
                },
              }}
              slots={{
                columnSortedDescendingIcon: SortedDescendingIcon,
                columnSortedAscendingIcon: SortedAscendingIcon,
                columnUnsortedIcon: UnsortedIcon,
              }}
              hideFooter={true}
            />
          </div>
        : <NoData />
      }
    </div>
  )
}

export default memo(SocialMediaMetrics);