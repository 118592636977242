export const Styles = {
  InfoOutlinedIcon: {
    color: 'rgb(102, 102, 102)',
    width: '18px'
  },
  ArrowOutwardIcon: {
    position: 'absolute', 
    right: '16px', 
    top: '24px', 
    color: '#1a90ff', 
    fontSize: '24px'
  },
  ModalStyle: {
    iconClose: {
      position: 'absolute',
      right: '24px',
      top: '24px',
      cursor: 'pointer',
    }
  },
}