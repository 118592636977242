export const fileDownloader = (response, fileName = null) => {
  // Convert base64 data to Blob
  const byteCharacters = atob(response.base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: response.contentType });
  // Create URL from Blob
  const url = URL.createObjectURL(blob);
  // Create a temporary anchor element
  const a = document.createElement('a');
  a.href = url;

  if (fileName) {
    a.download = fileName;
  } else {
    a.download = response.storageFilePath.split('/').pop(); // Set the file name
  }
  document.body.appendChild(a);

  // Trigger click event to initiate download
  a.click();

  // Cleanup
  URL.revokeObjectURL(url);
  document.body.removeChild(a);
};
