import { useEffect } from 'react';
import { useFormContext, Controller } from "react-hook-form"
import { WppInput } from '@wppopen/components-library-react';
import '../../../stylesheets/Channel.css'

const Channel = (props) => {

  const { segmentIndex, fields, channel, sum } = props

  const { control } = useFormContext()

  useEffect(() => {
  }, [fields.length])

  return (
  <Controller
      control={control}
      name={`SegmentChannels[${segmentIndex}].channels[${channel.attributeId}-${channel.entityId}]`}
      render={({ field: { onChange, onBlur, value, error } }) => {
        return (
          <WppInput
            required={true}
            placeholder=""
            onWppChange={(e) => {
              sum(`${channel.attributeId}-${channel.entityId}`, e.detail.value);
              onChange(e.detail.value);
            }}
            value={fields[segmentIndex]?.channels[`${channel.attributeId}-${channel.entityId}`]}
            onWppBlur={onBlur}
            type="decimal"
            size="s"
          />
        )
      }}
    />
  )
}
export default Channel;