import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Filter from './Filter';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import { ReportTypeSpecificData } from './utils/utils';
import ScatterPlot from '../../components/ScatterPlot';
import DataGrid from './DataGrid';
import { REPORT_TYPE_OPTIONS } from './utils/constants';
import { ROW_DISPLAY } from './styles';
import { WppSpinner } from '@wppopen/components-library-react';
import LogoLoreal from '../../images/logo-loreal.png';

const Report = () => {
  const [clientsReportTypes, setClientsReportTypes] = useState([]);
  const [selectedClientsReportTypesData, setSelectedClientsReportTypesData] = useState({});
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [brandOptions, setBrandOptions] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [classificationOptions, setClassificationOptions] = useState([]);
  const [selectedClassifications, setSelectedClassifications] = useState([]);
  const [selectedReportType, setSelectedReportType] = useState('Conversion Rate / Net PPM');
  const [selectedStartDate, setSelectedStartDate] = useState(dayjs('2022-04-01').startOf('month'));
  const [selectedEndDate, setSelectedEndDate] = useState(dayjs('2022-06-30').endOf('month'));
  const [updateViewButtonActive, setUpdateViewButtonActive] = useState(false);

  const [reportData, setReportData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [spinnerVisible, setSpinnerVisible] = useState(true);

  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getIdToken();

  const ProcessData = () => {
    let chart = [];
    let grid = [];
    let id = 0;
    if (selectedBrands?.length) {
      _.forEach(selectedBrands, (brand) => {
        id += 10000;
        if (brand === -1) return;
        const brandData = reportData?.filter((a) => a.brand === brand);

        if (selectedClient === 'MARS') {
          _.forEach(brandData, a => {
            a.price = (Math.random() * 100).toFixed(2);
            a.netPpm = Math.random().toFixed(2);
            a.revenue = (Math.random() * 1000).toFixed(2);
          })
        }

        const xy = brandData?.map(
          (item) => ReportTypeSpecificData(REPORT_TYPE_OPTIONS[selectedReportType], item).GenerateXYData
        );

        chart.push({ id: brand, data: xy });
        grid = grid.concat(brandData.map((item, index) => ({ ...item, id: index + id })));
      });
    }
    setChartData(chart);
    setGridData(grid.sort((a, b) => (a.brand > b.brand ? 1 : -1)));
  };

  const baseApi = process.env.REACT_APP_API_ENDPOINT;
  useEffect(() => {
    setSpinnerVisible(true);
    fetch(`${baseApi}/portfolio/clients_and_report_types`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setClientsReportTypes(data);
        setClientOptions(data?.clients.map((a) => a.name).sort());
        setSelectedClient(data?.clients.map((a) => a.name).sort()[0]);
        setSpinnerVisible(false);
      })
      .catch((error) => {
        console.log('API FETCH ERROR: ', error);
        setSpinnerVisible(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selected = clientsReportTypes.clients?.filter((a) => a.name === selectedClient)[0];
    setSelectedClientsReportTypesData(selected);
    setCountryOptions(selected?.country.map((a) => a.name).sort());
    setSelectedCountry('');

    if (selectedClient === 'NESTLE') {
      setSelectedStartDate(dayjs('2024-02-01'));
      setSelectedEndDate(dayjs('2024-02-29'))
    } else if (selectedClient === 'MARS') {
      setSelectedStartDate(dayjs('2024-04-01'));
      setSelectedEndDate(dayjs('2024-04-30'))
    } else if (selectedClient === 'LOREAL') {
      setSelectedStartDate(dayjs('2022-04-01'));
      setSelectedEndDate(dayjs('2022-06-30'))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  useEffect(() => {
    if (selectedCountry === '') {
      setBrandOptions([]);
      setClassificationOptions([]);
      return;
    }
    const selected = selectedClientsReportTypesData.country?.filter((a) => a.name === selectedCountry)[0];
    setBrandOptions(selected?.brands.map((a) => a.name).sort());
    setClassificationOptions(selected?.classifications.map((a) => a.name).sort());
    setSelectedClassifications(selected?.classifications.map((a) => a.name).sort());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedStartDate.isAfter(selectedEndDate)) {
      setSelectedEndDate(selectedStartDate.endOf('month'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate]);

  useEffect(() => {
    if (selectedStartDate.isAfter(selectedEndDate)) {
      setSelectedStartDate(selectedEndDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEndDate]);

  useEffect(() => {
    if (selectedBrands.length && selectedClassifications.length) {
      setUpdateViewButtonActive(true);
    } else {
      setUpdateViewButtonActive(false);
    }
  }, [selectedBrands, selectedClassifications]);

  useEffect(() => {
    setChartData([]);
    setGridData([]);
  }, [selectedReportType])

  const UpdateView = () => {
    setSpinnerVisible(true);
    const payload = {
      client: selectedClient,
      country: selectedCountry,
      start_date: selectedStartDate.format('YYYY-MM-DD'),
      end_date: selectedEndDate.endOf('month').format('YYYY-MM-DD'),
      asins: [],
      brands: selectedBrands,
      classifications: selectedClassifications,
      report_type: REPORT_TYPE_OPTIONS[selectedReportType],
    };

    fetch(`${baseApi}/portfolio/report`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data !== undefined && data.data?.length) {
          setReportData(data.data);
        } else {
          setReportData([]);
          setSpinnerVisible(false);
        }
      })
      .catch((error) => {
        console.log('API POST ERROR: ', error);
        setSpinnerVisible(false);
      });
  };

  useEffect(() => {
    if (reportData?.length) {
      setSpinnerVisible(true);
      ProcessData();
      setSpinnerVisible(false);
    } else {
      setChartData([]);
      setGridData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);

  return (
    <>
      {selectedClient === 'LOREAL' ? (
        <img
          src={LogoLoreal}
          alt="Loreal Logo"
          style={{
            width: '100px',
            position: 'absolute',
            top: '24px',
            left: '108px',
          }}
        />
      ) : null}

      <div
        className="col-1-1"
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '5px',
        }}
      >
        {spinnerVisible && (
          <div className="mainSpinner ml-4">
            <div>
              <WppSpinner size="l" />
            </div>
          </div>
        )}
        <Box
          sx={{
            ...ROW_DISPLAY,
            alignItems: 'flex-start',
            width: '90vw',
            height: 'auto',
            boxShadow: 0,
            borderRadius: 3,
          }}
        >
          <Box sx={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '85vw', height: '17vh' }}>
                <Filter
                  countryOptions={countryOptions}
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                  clientOptions={clientOptions}
                  selectedClient={selectedClient}
                  setSelectedClient={setSelectedClient}
                  brandOptions={brandOptions}
                  selectedBrands={selectedBrands}
                  setSelectedBrands={setSelectedBrands}
                  classificationOptions={classificationOptions}
                  selectedClassifications={selectedClassifications}
                  setSelectedClassifications={setSelectedClassifications}
                  reportTypeOptions={REPORT_TYPE_OPTIONS}
                  selectedReportType={selectedReportType}
                  setSelectedReportType={setSelectedReportType}
                  selectedStartDate={selectedStartDate}
                  setSelectedStartDate={setSelectedStartDate}
                  selectedEndDate={selectedEndDate}
                  setSelectedEndDate={setSelectedEndDate}
                  updateViewButtonActive={updateViewButtonActive}
                  UpdateView={UpdateView}
                />
              </div>
            </div>
            {
              gridData.length
              ? (
                  <>
                    <div style={{ height: '540px', marginTop: '136px' }} className={'col-1-1'}>
                      <ScatterPlot data={chartData} reportType={REPORT_TYPE_OPTIONS[selectedReportType]} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '30px 0 20px 0', overflowY: 'auto' }}>
                      <div style={{ width: '80vw', height: 'auto' }}>
                        <DataGrid data={gridData} reportType={REPORT_TYPE_OPTIONS[selectedReportType]} />
                      </div>
                    </div>
                  </>
                )
              : <div className={'flex w-full justify-center mt-[232px] pb-64 text-sm text-gray-500'}>
                  No data to display
                </div>
            }
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Report;
