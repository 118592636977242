import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useFormContext } from "react-hook-form"
import { Typography } from '@mui/material';
import { Styles } from './styles'


const ReviewInformation = (props) => {

  const { rfiCardsData, partnersRespondersList } = props;

  const [partnersResponders, setPartnersResponders] = useState([]);

  const { getValues } = useFormContext();
  const values = getValues();
  const FlowAdmins = values?.FlowAdmins;
  const PartnerAdmins = values?.PartnerAdmins;
  const ReviewInformation = Styles.ReviewInformation;

  useEffect(() => {
    if (partnersRespondersList.length) {
      const partnerResponderNames = [];
      _.forEach(PartnerAdmins, (selectedPartner) => {
        const selectedPartnerObj = partnersRespondersList.find(partner => partner.id === selectedPartner.partnerId);
        const responderName = selectedPartnerObj.responders.find(responder => responder.responderId === selectedPartner.responder)?.name
        partnerResponderNames.push({ partner: selectedPartnerObj?.name, responder: responderName, email: selectedPartner?.email });
      });
      setPartnersResponders(partnerResponderNames)
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnersRespondersList])

  return (
    <>
      <Typography sx={ReviewInformation.RFIHeader}>RFI:</Typography>
      <Typography sx={{ ...ReviewInformation.RFIHeader, ...ReviewInformation.RFI }}>{rfiCardsData?.find(rfi => rfi.rfiId === values?.RFI).title}</Typography>
      {
        <table style={ReviewInformation.table}>
          <tbody>
            <tr style={ReviewInformation.headerRow} key={-1}>
              <td style={ReviewInformation.col1}>Flow Name:</td>
              <td style={Styles.spacer} />
              <td style={ReviewInformation.col2}>Additional Admins:</td>
              <td style={Styles.spacer} />
              <td style={ReviewInformation.col3}>Role:</td>
            </tr>
            {FlowAdmins.map((item, index) => (
              <tr style={ReviewInformation.row} key={index}>
                <td>{index === 0 ? values?.FlowName : null}</td>
                <td style={ReviewInformation.spacer} />
                <td>{FlowAdmins[index]?.name}</td>
                <td style={ReviewInformation.spacer} />
                <td>{FlowAdmins[index]?.role}</td>
              </tr>
            ))}
            <tr style={ReviewInformation.headerRow} key={100}>
              <td style={ReviewInformation.col1}>Partners:</td>
              <td style={Styles.spacer} />
              <td style={ReviewInformation.col2}>Responders:</td>
              <td style={Styles.spacer} />
              <td style={ReviewInformation.col3}>Partner Admins:</td>
            </tr>
            {partnersResponders?.map((item, index) => (
              <tr style={ReviewInformation.row} key={index + 100}>
                <td>{item.partner}</td>
                <td style={ReviewInformation.spacer} />
                <td>{item.responder}</td>
                <td style={ReviewInformation.spacer} />
                <td>{item.email.join(" ")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      }
    </>
  )
}
export default ReviewInformation;