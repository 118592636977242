import { REPORT_TYPES } from './constants';

export const ReportTypeSpecificData = (reportType, item) => {
  if (reportType === REPORT_TYPES.ConversionRate_NetPPM) {
    return {
      report_type: 'ConversionRate_NetPPM',
      ReportAxis: { xAxis: 'Net PPM (%)', yAxis: 'Conversion Rate (%)' },
      HiddenColumns: {
        revenue: false,
        sellableOnHandInventoryUnits: false,
        glanceViews: false,
      },
      GenerateXYData: {
        ASIN: item?.asin,
        Item: item?.itemName?.length > 15 ? `${item?.itemName.slice(0, 15)}...` : item?.itemName,
        Conversion_Rate: Math.round((Number(item?.conversionRate * 100) + Number.EPSILON) * 100) / 100, // Up to 2 decimal places
        NET_PPM: Math.round((Number(item?.netPpm * 100) + Number.EPSILON) * 100) / 100,
        x: item?.netPpm * 100,
        y: item?.conversionRate * 100,
      },
    };
  } else if (reportType === REPORT_TYPES.Revenue_ConversionRate) {
    return {
      report_type: 'Revenue_ConversionRate',
      ReportAxis: { xAxis: 'Revenue', yAxis: 'Conversion Rate (%)' },
      HiddenColumns: {
        glanceViews: false,
        sellableOnHandInventoryUnits: false,
        netPpm: false,
      },
      GenerateXYData: {
        ASIN: item?.asin,
        Item: item?.itemName?.length > 15 ? `${item?.itemName.slice(0, 15)}...` : item?.itemName,
        Conversion_Rate: Math.round((Number(item?.conversionRate * 100) + Number.EPSILON) * 100) / 100,
        Revenue: item?.revenue,
        x: item?.revenue,
        y: item?.conversionRate * 100,
      },
    };
  } else if (reportType === REPORT_TYPES.OnHandInventory_NetPPM) {
    return {
      report_type: 'OnHandInventory_NetPPM',
      ReportAxis: { xAxis: 'On-Hand Inventory', yAxis: 'Net PPM (%)' },
      HiddenColumns: {
        revenue: false,
        glanceViews: false,
        conversionRate: false,
      },
      GenerateXYData: {
        ASIN: item?.asin,
        Item: item?.itemName?.length > 15 ? `${item?.itemName.slice(0, 15)}...` : item?.itemName,
        NET_PPM: Math.round((Number(item?.netPpm * 100) + Number.EPSILON) * 100) / 100,
        On_Hand_Inventory: item?.sellableOnHandInventoryUnits,
        x: item?.sellableOnHandInventoryUnits,
        y: item?.netPpm * 100,
      },
    };
  } else if (reportType === REPORT_TYPES.Traffic_ConversionRate) {
    return {
      report_type: 'Traffic_ConversionRate',
      ReportAxis: { xAxis: 'Traffic', yAxis: 'Conversion Rate (%)' },
      HiddenColumns: {
        revenue: false,
        netPpm: false,
        sellableOnHandInventoryUnits: false,
      },
      GenerateXYData: {
        ASIN: item?.asin,
        Item: item?.itemName?.length > 15 ? `${item?.itemName.slice(0, 15)}...` : item?.itemName,
        Conversion_Rate: Math.round((Number(item?.conversionRate * 100) + Number.EPSILON) * 100) / 100,
        Traffic: item?.glanceViews,
        x: item?.glanceViews,
        y: item?.conversionRate * 100,
      },
    };
  }
};
