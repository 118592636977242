import { useState } from 'react';
import { WppCard, WppButton } from '@wppopen/components-library-react';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import { Styles } from './styles';
import { FlowStepsPerStatus } from '../../Utils/utils';
import { READY_FOR_REVIEW } from '../../Utils/constants';
import FlowScoring from './FlowScoring';
import FlowScoringModal from "../../../../components/Modal";

const FlowViewerCardStyles = Styles.FlowViewerCardStyles;

const FlowViewerCard = (props) => {

  const { flowName, flowSetId, flow, userPermissions } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const customStyle = { '--wpp-full-screen-modal-height': '101%', '--wpp-full-screen-modal-width': '101%' }


  const handleCloseModal = () => {
    setIsModalOpen(false);
  }
  
  const handleScore = () => {
    setModalContent(<FlowScoring flowName={flowName}
      flowSetId={flowSetId}
      task={flow}
      userPermissions={userPermissions} />)
    setIsModalOpen(true);
  }

  const steps = FlowStepsPerStatus(flow.taskStatus);

  return (
    <>
      <WppCard interactive={true} size={'1.75xl'} key={flow.flowId}>
        <Box sx={FlowViewerCardStyles.box}>
          {flow.logoPath.split('|').map((image, index) => {
            return <img key={index}
              alt={index === 0 ? '' : ''}
            style={{width: '112px', height: 'auto'}}
              src={'https://storage.googleapis.com/fusion-email-image-bucket/partner-logos/' + image.split('.')[0] + '.png'}
            />
          })}
        </Box>
        <Box sx={FlowViewerCardStyles.stepper}>
          <Stepper activeStep={parseInt(flow.taskStatus)} orientation="vertical">
            {steps.items.map((step, index) => (
              <Step key={index}>
                <StepLabel>
                  {step}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box sx={FlowViewerCardStyles.buttonBox}>
          <WppButton onClick={handleScore} disabled={parseInt(flow.taskStatus) > 0 ? false : true}>
            {parseInt(flow.taskStatus) >= READY_FOR_REVIEW.ACTIVE_STEP ? "Review" : "Score"}
          </WppButton>
        </Box>
      </WppCard>
      <FlowScoringModal
        flowName={flowName}
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        modalContent={modalContent}
        setModalContent={setModalContent}
        isFullModal={true}
        customStyle={customStyle}
      />
    </>
  );
}
export default FlowViewerCard;