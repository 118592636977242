export const Styles = {
  FormMain: {
    padding: "84px",
  },
  SelectRFIType: {
    title: {},
    radio: { marginBottom: "24px" },
    radioWidth: {},
    table: {},
    spacer: {},
    newRFI: {},
    newRFIName: { minWidth: "220px" },
    td: {},
    errorFeedback: {
      fontSize: '14px',
      color: 'red',
    },
    message: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'left',
      backgroundColor: '#FDEAE2',
      width: '550px',
      margin: '40px 0 0 20px'
    },
  },
  FlowStepperStyle: {
    stepper: {
      '& .MuiStepLabel-root .Mui-completed': {
        color: '#0014CC', // circle color (COMPLETED)
      },
      '& .MuiStepLabel-root .Mui-active': {
        color: '#0014CC', // circle color (ACTIVE)
      },
    },
    stepperControls: {
      position: 'absolute',
      right: '32px',
      bottom: '32px',
    },
    rfiForm: (screenHeight) => {
      return {
        overflow: 'auto',
        width: 'inherit',
        height: `${screenHeight}px`,
      }
    }
  },
  SelectPartners: {
    autoComplete: {},
    selections: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {},
      },
    },
    addPartner: {},
    PARTNERS: {
      selectPartners: {},
      table: {},
      headerRow: {},
      row: {},
      spacer: {},
      partner: { minWidth: "220px", maxHeight: "40px", borderRadius: "8px", marginTop: "2px", font: 'normal 400 14px Inter' },
      region: {},
      market: {},
      delete: { background: "white", border: "none", cursor: "pointer", minHeight: "40px"},
      invalidFeedback: {
        fontSize: '12px',
        color: 'red',
      },
      zeroCountFeedback: {
        margin: '30px 0 0 185px',
        fontSize: '14px',
        color: 'red',
      }
    },
  },
  ReviewInformation: {
    RFIHeader: {
      width: '500px',
      margin: '80px 0 0 180px',
      fontWeight: 600,
      fontSize: '18px',
      color: '#404252',
    },
    RFI: {
      margin: '7px 0 0 180px',
      fontWeight: 400,
    },
    table: {
      margin: '0 0 0 180px',
      borderCollapse: 'collapse',
    },
    headerRow: {
      height: '100px',
      fontWeight: 600,
      fontSize: '18px',
      color: '#404252',
      verticalAlign: 'bottom',
    },
    col1: {
      width: '300px',
      paddingBottom: '5px',
    },
    col2: {
      width: '350px',
      paddingBottom: '5px',
    },
    col3: {
      width: '350px',
      paddingBottom: '5px',
    },
    row: {
      height: '40px',
      fontWeight: 400,
      fontSize: '18px',
      color: '#404252',
    },
    spacer: {
      width: '12px',
    },
  },
};