import React, { useState } from 'react'
import { useOktaAuth } from '@okta/okta-react';
import { levelNames } from "../../okta/OktaRoles";
import { IsRendered } from "../../utils/RolesUtils";
import { useSearchParams } from "react-router-dom";
import { WppTabs, WppTab } from '@wppopen/components-library-react'
import { TabsChangeEventDetail } from '@wppopen/components-library'
import InsightStepper from './InsightStepper'
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import { jwtDecode } from 'jwt-decode';
import FlowViz from './Charts/FlowViz';
import { BackHomeButton } from '../../components/BackHomeButton';

const InsightDetails = () => {

  const [searchParams] = useSearchParams();

  const { level10 } = levelNames;

  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getIdToken();
  const decodedToken = jwtDecode(accessToken);
  const userPermissions = decodedToken.groupsFusion;

  const [projectId] = useState(searchParams.get("projectId"));
  const [insightIdDB] = useState(searchParams.get("insightId"))
  const [flowSetId] = useState(searchParams.get("flowsetId"))
  const [insightName, setInsightName] = useState('');
  const [selectedFlowId, setSelectedFlowId] = useState(null);

  const isAdmin = userPermissions && IsRendered(level10, userPermissions);
  const [currentTab, setCurrentTab] = useState('dashboard');

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    setCurrentTab(event.detail.value)
  }

  return (
    <>
      <Header title="" />
      <SideBar active={'insights'} userPermissions={userPermissions} />
      {!!projectId && (
        <div className={'flex ml-[80px] flex-col mb-8 bg-gray-50'}>
          <BackHomeButton link={'/home'}/>
          <div className={'px-16 pt-12'}>
          <WppTabs value={currentTab} onWppChange={handleTabChange} className="mb-10">
            <WppTab value='dashboard' className="w-48">Dashboard</WppTab>
            {isAdmin && (
              <WppTab value='adminMode' className="w-48">Admin Mode</WppTab>
            )}
          </WppTabs>
          </div>
          {
            {
              dashboard: (
                <FlowViz
                  insightIdDB={insightIdDB}
                  flowSetId={flowSetId}
                  insightName={insightName}
                  setInsightName={setInsightName}
                  accessToken={accessToken}
                  selectedFlowId={selectedFlowId}
                />
              ),
              adminMode: (
                <InsightStepper 
                  projectId={projectId}
                  insightIdDB={insightIdDB}
                  setInsightName={setInsightName}
                  selectedFlowId={selectedFlowId}
                  setSelectedFlowId={setSelectedFlowId}
                />
              ),
            }[currentTab]
          }
        </div>
      )}
    </>
  )
}

export default InsightDetails;