import DropSelectWppOpenByID from '../../../components/DropSelectWppOpenByID';
import Treemap from './Treemap';
import Heatmap from './Heatmap';
import BarchartTrafficRevenue from './BarchartTrafficRevenue';
import ScatterPlot from './ScatterPlot';

const FlowVizOverview = (props) => {

  const {
    segments,
    selectedSegment,
    setSelectedSegment,
    treemapData,
    retailers,
    filteredRetailers,
    setFilteredRetailers,
    heatmapData,
    barchartData,
    scatterplotData,
  } = props;

  return (
    <>
      <div>
        <div className={'flex mt-12'}>
          {!!segments.length && (
            <DropSelectWppOpenByID
              type="single"
              title="Choose Segment"
              className="w-60 text-[14px]"
              items={segments}
              id="id"
              value="segment"
              selection={selectedSegment}
              setSelection={setSelectedSegment}
            />
          )}
        </div>
        <div
          className={
            'flex w-full mt-6 min-h-24 bg-white shadow-[0_0_2px_0_rgba(0,0,0,0.1)] rounded-[8px] p-8 flex-col'
          }
        >
          <div className={'text-[24px] text-gray-900 font-bold'}>Retailer Performance Treemap</div>
          <div className={'text-sm text-gray-900 mt-1'}>Displays the total maturity score for top retailers within your selected segment, providing an overview of their overall performance</div>
          <div className={'w-full h-80 border-b-[1px] pb-8 mt-8 flex justify-center'}>
            {!!treemapData.children?.length
              ? <Treemap data={treemapData} />
              : <div>No data to display</div>
            }
          </div>
          <div className={'flex w-full mt-8'}>
            {!!retailers.length && (
              <DropSelectWppOpenByID
                type="multiple"
                title="Filter Retailers"
                className="w-60 text-[14px]"
                items={retailers.sort((a,b) => (a.partner > b.partner) ? 1 : ((b.partner > a.partner) ? -1 : 0))}
                id="partnerId"
                value="partner"
                selection={filteredRetailers}
                setSelection={setFilteredRetailers}
                maxSelection={5}
                withSearch={true}
                withFolder={false}
              />
            )}
          </div>
          <div className={'mt-1 text-xs text-gray-900'}>Top 5 retailers selected by default</div>
          <div className={'text-[24px] text-gray-900 mt-8 font-bold'}>Retailer Channel & Category Maturity</div>
          <div className={'text-sm text-gray-900 mt-1'}>Showcases the retailer score on evaluated channels and categories, giving you a detailed understanding of their strengths and weaknesses across different areas</div>
          <div className={'w-full h-96 border-b-[1px] mt-8 flex justify-center'}>
            {!!heatmapData?.length
              ? <Heatmap data={heatmapData} />
              : <div>No data to display</div>
            }
          </div>
          <div className={'text-[24px] text-gray-900 mt-8 font-bold'}>Monthly Traffic & Revenue</div>
          <div className={'text-sm text-gray-900 mt-1'}>Visualizes retailer traffic and revenue on a monthly basis, allowing you to track their performance over time and identify trends</div>
          <div className={'w-full h-96 border-b-[1px] mt-8 flex justify-center'}>
            {!!barchartData?.length
              ? <BarchartTrafficRevenue data={barchartData} />
              : <div>No data to display</div>
            }
          </div>
          <div className={'text-[24px] text-gray-900 mt-8 font-bold'}>Retailer Quadrant Analysis</div>
          <div className={'text-sm text-gray-900 mt-1'}>Visualizes retailer maturity score against forecasted growth rate, helping you understand which retailers are poised for future success</div>
          <div className={'w-full h-96 border-b-[1px] mt-4 flex justify-center'}>
            {!!scatterplotData[0]?.data?.length && !!scatterplotData[1].data.length
              ? <ScatterPlot data={scatterplotData} />
              : <div>No data to display</div>
            }
          </div>
        </div>
      </div>
    </>
  )
}
export default FlowVizOverview;