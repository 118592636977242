import { WppIconChevron } from '@wppopen/components-library-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const BackHomeButton = (props) => {
  const { link } = props;
  const navigate = useNavigate();
  return (
    <div
      className={'mt-4 ml-4 flex items-center cursor-pointer'}
      onClick={() => {
        navigate(link);
      }}
    >
      <WppIconChevron direction={'left'} />
      <div className={'text-xs ml-[-2px] text-gray-800'}>Back</div>
    </div>
  );
};
