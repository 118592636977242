import { useState } from 'react';
import { ResponsiveLine } from '@nivo/line'
import { DotsItem, useTheme } from '@nivo/core'


const data = [
  {
    "id": "Amazon QoQ",
    "data": [
      {
        "x": "2023 Q4",
        "y": 90
      },
      {
        "x": "2024 Q1",
        "y": 282
      },
    ]
  },
  {
    "id": "Target QoQ",
    "data": [
      {
        "x": "2023 Q4",
        "y": 5
      },
      {
        "x": "2024 Q1",
        "y": 141
      },
    ]
  },
  {
    "id": "Walmart QoQ",
    "data": [
      {
        "x": "2023 Q4",
        "y": 90
      },
      {
        "x": "2024 Q1",
        "y": 53
      },
    ]
  },
  {
    "id": "Go Puff QoQ",
    "data": [
      {
        "x": "2023 Q4",
        "y": 282
      },
      {
        "x": "2024 Q1",
        "y": 59
      },
    ]
  },
  {
    "id": "Shipt QoQ",
    "data": [
      {
        "x": "2023 Q4",
        "y": 165
      },
      {
        "x": "2024 Q1",
        "y": 89
      },
    ]
  }
]





const MyResponsiveLine = () => {
  
  const [width, setWidth] = useState(100);
  
  const Lines = (props) => {
    const { series, lineGenerator } = props;
    return series.map((s) => {
      const { data, color } = s;
      return (
        <path key={s.id} d={lineGenerator(data.map(d => (
          { x: d.position.x + (d.position.x === 0 ? width * .15 : -width * .15), y: d.position.y }
        )))} stroke={color} style={{strokeWidth: '3px'}}/>
      )
    })
  }

  const Points = ({ points, width, ...props }) => {
    setWidth(width);
    const theme = useTheme()
    return (
      <g>
        {points.map((point) => (
          <DotsItem
            key={point.id}
            x={point.x + (point.x === 0 ? width * .15 : -width * .15)}
            y={point.y}
            datum={point.data}
            symbol={props.pointSymbol}
            size={props.pointSize}
            color={point.color}
            borderWidth={props.pointBorderWidth}
            borderColor={point.borderColor}
            label={point.label}
            labelYOffset={props.pointLabelYOffset}
            theme={theme}
          />
        ))}
      </g>
    )
  }

  return (
  <ResponsiveLine
      data={data}
      margin={{ top: 40, right: 40, bottom: 80, left: 60 }}
      xScale={{ type: 'point' }}
      yScale={{
          type: 'linear',
          min: 0,
          max: 'auto',
          stacked: false,
          reverse: false
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        renderTick: (
          {
            textAnchor,
            textBaseline,
            value,
            x,
            y,
            tickIndex,
          }
        ) => {
          return (
            <g>
              <line x1={x + (tickIndex === 0 ? width * .15 : -width * .15)} y1={y} x2={x + (tickIndex === 0 ? width * .15 : -width * .15)} y2={5} stroke="gray" />
              <text
                className="tick-text"
                alignmentBaseline={textBaseline}
                textAnchor={textAnchor}
              >
                <tspan x={x + (tickIndex === 0 ? width * .15 : -width * .15)} dy={25}>{value}</tspan>
              </text>
            </g>
          )
        }
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Performance',
        legendOffset: -50,
        legendPosition: 'middle',
        truncateTickAt: 0
      }}
      enableGridX={false}
      colors={['#AAAAAA']}
      pointSize={10}
      areaOpacity={0}
      enableCrosshair={false}
      // useMesh={true}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 10,
          translateY: 65,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 120,
          itemHeight: 33,
          itemOpacity: 0.75,
          symbolSize: 14,
          symbolShape: 'circle',
          symbolBorderColor: '#0014CC',
          // effects: [
          //   {
          //     on: 'hover',
          //     style: {
          //       itemBackground: '#0014CC',
          //       itemOpacity: 1
          //     }
          //   }
          // ]
        }
      ]}
      layers={[
        'grid',
        'markers',
        'axes',
        'crosshair',
        Lines,
        Points,
        'slices',
        'mesh',
        'legends',
      ]}
  />
  )
}
export default MyResponsiveLine;