import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@wppopen/components-library/dist/platform-ui-kit/wpp-theme.css';
import '@wppopen/components-library/dist/platform-ui-kit/global.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<App />
);
