import React from 'react';
import { useFormContext } from "react-hook-form"
import { Typography } from '@mui/material';
import { RFI_OPTIONS } from '../../../Utils/constants'
import { Styles } from './styles'

const Review = (props) => {

  const { getValues } = useFormContext();
  const values = getValues();
  const ReviewInformationStyle = Styles.ReviewInformation;

  return (
    <div >   
      {values.RFIType === RFI_OPTIONS.EXISTING_RFI.value &&
        <>
          <Typography sx={ReviewInformationStyle.RFIHeader}>Cloned from RFI:</Typography>
          <Typography
            sx={{ ...ReviewInformationStyle.RFIHeader, ...ReviewInformationStyle.RFI }}>{values.RFIExisting.title}</Typography>
        </>
      }
      <Typography sx={ReviewInformationStyle.RFIHeader}>RFI Name:</Typography>
      <Typography
        sx={{ ...ReviewInformationStyle.RFIHeader, ...ReviewInformationStyle.RFI }}>{values?.clonedRFIName || values?.newRFIName}</Typography>
    </div>
  )
}
export default Review;