import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useFormContext, useFieldArray } from "react-hook-form"
import { 
  WppButton,
  WppActionButton,
  WppTabs,
  WppTab,
  WppModal,
  WppInput } from '@wppopen/components-library-react'
import { TabsChangeEventDetail } from '@wppopen/components-library'
import SegmentChannels from './SegmentChannels';
import GearIcon from '../../assets/GearIcon';
import TrashIcon from '../../assets/TrashIcon';
import { INITIAL_SEGMENT } from '../Utils/constants';

const Segments = (props) => {
  const { flowSetId, insightId, baseApi, accessToken, setIsSpinnerSpinning, tabTotal100, rerender, setRerender } = props;
  
  const { control, formState } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'SegmentChannels',
    shouldUnregister: false,
  });

  const [segments, setSegments] = useState(insightId && fields.length ? fields.map(a => a.segment) : [INITIAL_SEGMENT.GENERAL]);
  const [channels, setChannels] = useState([]);
  const [initialChannelValues, setInitialChannelValues] = useState({});
  const [currentTab, setCurrentTab] = useState(INITIAL_SEGMENT.GENERAL);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [segmentsCopy, setSegmentsCopy] = useState([...segments]);
  const [newSegment, setNewSegment] = useState(null);

  useEffect(() => {
    setIsSpinnerSpinning(true);
    fetch(`${baseApi}/flow/${flowSetId}/channels `, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.status}`);
      }
      return response.json()
    })
    .then(data => {
      if (data?.data) {
        setChannels(data.data);
        setIsSpinnerSpinning(false);
      }
    })
    .catch((error) => {
      console.log('API FETCH ERROR: ', error);
      setIsSpinnerSpinning(false);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (channels.length) {
      const initialValue = (Math.round(100 / channels.length * 100) / 100).toFixed(2);
      const initialChannels = {};
      _.forEach(channels, channel => {
        initialChannels[`${channel.attributeId}-${channel.entityId}`] = initialValue;
      })
      setInitialChannelValues({...initialChannels});
    }
  }, [channels])

  useEffect(() => {
    if (insightId && fields.length) {
      const segmentsDB = fields.map(a => a.segment);
      setSegments(segmentsDB);
      setSegmentsCopy([...segmentsDB])
      _.forEach(segmentsDB, segment => {
        tabTotal100.current[segment] = true;
      })
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightId])
  
  useEffect(() => {

    if (!Object.keys(initialChannelValues).length) return;

    // ADD NEW SEGMENT
    _.forEach(segments, segment => {
      if (!fields.map(a => a.segment).includes(segment)) {
        append({
          segment,
          channels: {...initialChannelValues},
          retailerIds: [],
        })
        tabTotal100.current[segment] = true;
      }
    });

    // DELETE REMOVED
    let index = 0;
    _.forEach(fields, item => {
      if (segments.length && !segments.includes(item.segment)) {
        remove(index);
        delete tabTotal100.current[item.segment];
      } else {
        index++;
      }
    });
    
    setCurrentTab(segments[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segments, initialChannelValues])

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    setCurrentTab(event.detail.value);
  }

  const segmentOnChange = (event) => {
    setNewSegment(event.detail.value);
  }

  const addSegment = (newSegment) => {
    const segmentsCopyLowerCase = segmentsCopy.map(a => a.toLowerCase());
    const newSegmentUnique = [];
    _.forEach(newSegment.split(',').map(a => a.trim()), a => {
      if (!segmentsCopyLowerCase.includes(a.toLowerCase())) {
        newSegmentUnique.push(a);
      }
    });

    if (newSegmentUnique?.length) {
      setSegmentsCopy([...segmentsCopy, ...newSegmentUnique]);
      setNewSegment(null);
    }
  }

  const deleteSegment = (segment) => {
    setSegmentsCopy(_.without([...segmentsCopy], segment));
  }

  const applySegments = () => {
    setSegments([...segmentsCopy]);
    setIsModalOpen(false);
  }

  const discard = () => {
    setSegmentsCopy([...segments]);
  }

  return (
    <>
      <div className="w-auto rounded-lg border-solid border-2 border-gray-200 h-auto">
        <div className="text-xl font-semibold mb-6 mt-6 ml-6">Segments</div>
        <div className="w-80 ml-6 mb-6">
          <WppButton
            variant='secondary'
            style={null}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <div className="flex items-center">
              <GearIcon slot='icon-start' />
              <span className="ml-2">Manage Segments</span>
            </div>
          </WppButton>
        </div>
        <div className="ml-6 mr-6">
          <WppTabs value={currentTab} onWppChange={handleTabChange} className="mb-8">
            {!!segments.length && segments.map((segment, index) => {
              return (
                <WppTab key={segment} value={segment} className="w-40">
                  <span style={{ color: 
                    tabTotal100.current[segment] 
                    && 
                    (index === 0 || (index > 0 && !formState?.errors?.SegmentChannels?.[index]?.retailerIds?.message))
                    ? null 
                    : 'red' }}>{segment}</span>
                </WppTab>
              )}
           )}
          </WppTabs>
          <SegmentChannels
            baseApi={baseApi}
            accessToken={accessToken}
            currentTab={currentTab}
            channels={channels}
            fields={fields}
            tabTotal100={tabTotal100}
            setIsSpinnerSpinning={setIsSpinnerSpinning}
            rerender={rerender}
            setRerender={setRerender}
          />
        </div>
        <div>
          <WppModal open={isModalOpen}>
            <h3 slot="header">Manage Segments</h3>
            <div slot="body">
              <div className="flex mt-2 mb-6">
                <WppInput
                  name="segmentName"
                  style={{ width: '500px'}}
                  placeholder="Create New Segment"
                  value={newSegment}
                  onWppChange={segmentOnChange}
                />
                <WppButton className="ml-2" variant="primary" size="s" onClick={() => addSegment(newSegment)}>Add</WppButton>
              </div>
              <table className="mb-6 flex justify-center">
                <tbody>
                  {!!segmentsCopy.length && segmentsCopy.map((segment, index) => (

                    <tr key={index + 100} className="border-solid border-b border-gray-200 h-14 pt-4 pb-4">
                      <td className="w-48 text-sm font-normal pl-4">{segment}</td>
                      <td className="w-20 text-sm font-normal">
                        {segment !== INITIAL_SEGMENT.GENERAL ? <div onClick={() => deleteSegment(segment)}><TrashIcon /></div> : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="text-right" slot="actions">
              {segmentsCopy.length > 1 && (
                <WppActionButton onClick={() => discard()} variant="secondary">
                  Discard Changes
                </WppActionButton>
              )}
              <WppActionButton variant="primary" size="s" onClick={() => applySegments()}>
                {segmentsCopy.length > 1 ? 'Save' : 'Done'}
              </WppActionButton>
            </div>
          </WppModal>
        </div>
      </div>
    </>
  );
}
export default Segments;