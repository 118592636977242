import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import OktaSignIn from '@okta/okta-signin-widget';
import OktaConfig from './OktaConfig';
import '../../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import logo from '../images/fusionlogo.png';

const CustomLoginComponent = () => {

  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const { issuer, clientId, redirectUri, scopes, baseUrl } = OktaConfig.oidc;
    const oktaSignIn = new OktaSignIn({
      baseUrl,
      clientId,
      redirectUri,
      logo,
      features: {
        registration: false,
      },
      i18n: {
        en: {
          'primaryauth.title': 'Sign In With Your WPP OKTA Credentials',
        },
      },
      authParams: {
        issuer,
        scopes,
      },
    });
    
    oktaSignIn.renderEl(
      {
        el: '#okta-signin-container',
        features: {
          hideSignOutLinkInMFA: false,
          rememberMe: true,
        },
      },
      (res) => {
        if (res.status === 'SUCCESS') {
          navigate('/home');
        }
      },
      (err) => {
        console.log('!!!', err)
        throw new Error("Okta signin error");
      }
    );

    return () => oktaSignIn.remove();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth]);

  return <div id="okta-signin-container" />;
};

export default CustomLoginComponent;
