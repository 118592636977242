import React, { memo } from 'react';
import {
  DataGridPro,
  GridRow,
  GridColumnHeaders,
  gridStringOrNumberComparator
} from '@mui/x-data-grid-pro';
import { SortedDescendingIcon, SortedAscendingIcon, UnsortedIcon} from '../../utils'

const MemoizedRow = memo(GridRow);
const MemoizedColumnHeaders = memo(GridColumnHeaders);

const reverseCompactNumberFormat = (val) => {
  if (val.endsWith('K')) {
    return +(val.slice(0, -1)) * 1000;
  } else if (val.endsWith('M')) {
    return +(val.slice(0, -1)) * 1000000;
  } else if (val.endsWith('B')) {
    return +(val.slice(0, -1)) * 1000000000;
  } else {
    return +val;
  }
}

export const columns = [
  {
    field: 'retailer',
    headerName: 'Retailer',
    width: 125,
    headerClassName: 'data-grid',
  },
  {
    field: 'market',
    headerName: 'Market',
    width: 93,
    headerClassName: 'data-grid',
  },
  {
    field: 'fusion_score',
    headerName: 'Fusion Score',
    width: 129,
    headerClassName: 'data-grid',
    getSortComparator: (sortDirection) => {
      const modifier = sortDirection === 'desc' ? -1 : 1;
      return (value1, value2, cellParams1, cellParams2) => {
        if (value1 === 'N/A') {
          return 1;
        }
        if (value2 === 'N/A') {
          return -1;
        }
        return (
          modifier *
          gridStringOrNumberComparator(value1, value2, cellParams1, cellParams2)
        );
      };
    },
  },
  {
    field: 'ecdb_revenue',
    type: 'string',
    renderHeader: (params) => (
      <>
        <span className="font-semibold text-xs">
          ECDB % share
        </span>
        <span className="font-black text-black text-lg ml-1">
          *
        </span>
      </>
    ),
    headerClassName: 'data-grid',
    width: 147,
    getSortComparator: (sortDirection) => {
      const modifier = sortDirection === 'desc' ? -1 : 1;
      return (value1, value2, cellParams1, cellParams2) => {
        if (value1 === 'N/A') {
          return 1;
        }
        if (value2 === 'N/A') {
          return -1;
        }
        return (
          modifier *
          gridStringOrNumberComparator(+value1.slice(0, -1), +value2.slice(0, -1), cellParams1, cellParams2)
        );
      };
    },
  },
  {
    field: 'similarweb_traffic',
    headerName: 'SimilarWeb Traffic',
    headerClassName: 'data-grid',
    width: 159,
    getSortComparator: (sortDirection) => {
      const modifier = sortDirection === 'desc' ? -1 : 1;
      return (value1, value2, cellParams1, cellParams2) => {
        if (value1 === 'N/A') {
          return 1;
        }
        if (value2 === 'N/A') {
          return -1;
        }
        return (
          modifier *
          gridStringOrNumberComparator(reverseCompactNumberFormat(value1), reverseCompactNumberFormat(value2), cellParams1, cellParams2)
        );
      };
    },
  },
];

const DataGrid = (props) => {
  const { data } = props;

  return (
    <div className="h-[600px] w-[633px] ml-[-8px]">
      <DataGridPro
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            textTransform: 'none',
            fontSize: '12px',
            fontWeight: '600',
          },
          '& .MuiDataGrid-iconButtonContainer': {
            marginLeft: '-6px',
            visibility: 'visible !important',
            width: 'auto !important',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            margin: '0'
          },
          '&, [class^=MuiDataGrid-footerContainer]': {
            borderTop: 'none'
          },
        }}
        columns={columns}
        rows={data ?? []}
        getRowId={(row) => row.retailer + row.market}
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10]}
        rowHeight={58}
        disableRowSelectionOnClick
        components={{
          Row: MemoizedRow,
          ColumnHeaders: MemoizedColumnHeaders,
        }}
        slotProps={{
          pagination: {
            showFirstButton: true,
            showLastButton: true,
          },
        }}
        disableColumnMenu={true}
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
          columnUnsortedIcon: UnsortedIcon,
        }}
      />
      <div className="ml-0 mt-[-36px] text-gray-600 font-bold text-sm">*: of net sales based on category selected. N/A = 0%</div>
    </div>
  );
}
export default DataGrid;