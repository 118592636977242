import React from 'react';
import { ResponsiveBar } from '@nivo/bar'

const COLOR_OPTIONS = { traffic: '#85A3FF', revenue: '#ED78C6' };

const MyResponsiveBar = ({ data }) => {

  const maxValue = Math.max(...data.map(o => o.traffic, ...data.map(o => o.revenue)));

  const Axes: React.FC = (props) => {
    return (
      <g>
        <line x1={0} y1={props.innerHeight} x2={props.innerWidth} y2={props.innerHeight} stroke="black" />
        <line x1={0} y1={0} x2={0} y2={props.innerHeight} stroke="black" />
      </g>
    )
  };

  return (
    <ResponsiveBar
      data={data}
      keys={[
        'traffic',
        'revenue',
      ]}
      indexBy="retailer"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      innerPadding={6}
      groupMode="grouped"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={a => COLOR_OPTIONS[a.id]}
      borderColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            1.6
          ]
        ]
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: 32,
        truncateTickAt: 0
      }}
      axisLeft={false}
      maxValue={maxValue * 1.5}
      enableGridX={true}
      enableGridY={true}
      gridXValues={[]}
      gridYValues={[0]}
      enableLabel={false}
      legends={[
        {
          dataFrom: 'keys',
          anchor: 'top',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: -32,
          itemWidth: 85,
          itemHeight: 10,
          itemsSpacing: 6,
          symbolSize: 15,
          itemDirection: 'left-to-right'
        }
      ]}
      layers={[
        'axes',
        'bars',
        'legends',
        Axes,
      ]}
    />
  )
}
export default MyResponsiveBar;