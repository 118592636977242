export const GlobalCards = {
  name: 'Global',
  insights: [
    {
      name: 'Retail Media Capabilities',
      subhead: 'Analysis of 185 retail media platforms, weighted and scored by our staff.',
      path: '/insight/rtmV2',
    },
    {
      name: 'Retail Prioritization Matrix',
      subhead:
        'A visualization matrix shedding light on where a brand in a specific category should prioritize their dollars.',
      path: '/insight/rpm',
    },
  ],
};
