import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { FusionRoutes } from './FusionRoutes';
import '@wppopen/components-library/dist/platform-ui-kit/wpp-theme.css';
import './App.css';
import './stylesheets/Grid.css';
import './stylesheets/Typography.css'

import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_DATAGRID_LICENSE);

const App = () => {
  return (
    <BrowserRouter>
      <FusionRoutes />
    </BrowserRouter>
  );
}

export default App;
