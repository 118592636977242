import React from 'react';
import { WppInput, WppSelect, WppListItem } from '@wppopen/components-library-react';
import TrashIcon from '../../../../assets/TrashIcon';
import { Styles } from './styles'

const adminRoles = ['Super Admin', 'Admin', 'Flow Editor'];

const FlowName_Admin = (props) => {

  const { Controller, control, item, index, remove, errors } = props

  const FLOW_ADMINS = Styles.FlowName.FLOW_ADMINS;

  return (
    <React.Fragment key={item.id}>
      <tr style={FLOW_ADMINS.row}>
        <td style={FLOW_ADMINS.name}>
          <Controller
            control={control}
            name={`FlowAdmins[${index}].name`}
            render={({ field: { onChange, onBlur, value, error } }) => (
              <WppInput
                required={true}
                placeholder=""
                onWppChange={onChange}
                value={value}
                onWppBlur={onBlur}
              />
            )}
          />
          <div style={FLOW_ADMINS.invalidFeedback}>{errors.FlowAdmins?.[index]?.name?.message}</div>
        </td>
        <td />
        <td style={FLOW_ADMINS.email}>
          <Controller
            control={control}
            name={`FlowAdmins[${index}].email`}
            render={({ field: { onChange, onBlur, value, error } }) => (
              <WppInput
                required={true}
                placeholder=""
                onWppChange={onChange}
                value={value}
                onWppBlur={onBlur}
              />
            )}
          />
          <div style={FLOW_ADMINS.invalidFeedback}>{errors.FlowAdmins?.[index]?.email?.message}</div>
        </td>
        <td />
        <td style={FLOW_ADMINS.role}>
          <Controller
            name={`FlowAdmins[${index}].role`}
            control={control}
            render={({ field: { onChange, onBlur, value, error } }) => (
              <WppSelect
                onWppChange={(e) => {
                  onChange(e);
                }}
                placeholder=""
                value={value}
                onBlur={onBlur}
              >
                {adminRoles.map(role => (
                  <WppListItem value={role} key={role}>
                    <p className="text-[16px]" slot="label">{role}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            )}
          />
          <div style={FLOW_ADMINS.invalidFeedback}>{errors.FlowAdmins?.[index]?.role?.message}</div>
        </td>
        <td />
        <td>
          <button onClick={() => remove(index)} style={FLOW_ADMINS.delete}>
            <TrashIcon />
          </button>
        </td>
      </tr>
    </React.Fragment>
  )
}

export default FlowName_Admin;