import React from 'react';
import { WppButton, WppIconAdd } from '@wppopen/components-library-react';
import { IsRendered } from '../../../../utils/RolesUtils';

const FlowButton = (props) => {
  const { permissionLevel, handleOpenModal, setModalContent, modalContent, title, userPermissions, variant } = props;

  return (
    <>
      {userPermissions && IsRendered(permissionLevel, userPermissions) && (
        <WppButton
          variant={variant}
          className="-mt-12"
          onClick={() => {
            handleOpenModal();
            setModalContent(modalContent);
          }}
        >
          <WppIconAdd slot="icon-end" />
          {title}
        </WppButton>
      )}
    </>
  );
};

export default FlowButton;
