export const columns = [
  {
    field: 'area',
    headerName: 'Evaluation Area',
    width: 260,
  },
  {
    field: 'pacvue',
    headerName: 'Pacvue',
    flex: 1,
    sortable: false,
    renderHeader: (GridColumnHeaderParams) => (
      <div className={'col-1-1 flex-center-all'}>
        <img
          src="https://global-uploads.webflow.com/5f15d340c06f693ca3c94281/5f2339a8737ec733d8eb5070_logo_pacvue_main.svg"
          alt=""
          style={{ width: '80px', height: 'auto' }}
        />
        <div className={'status-flag'} style={{ backgroundColor: '#8cff8f' }}>
          Preferred
        </div>
      </div>
    ),
  },
  {
    field: 'skai',
    headerName: 'Skai',
    flex: 1,
    sortable: false,
    renderHeader: (GridColumnHeaderParams) => (
      <div className={'col-1-1 flex-center-all'}>
        <img
          src="data:image/svg+xml;charset=utf8, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 323.53 146.01'%3E%3Cg data-name='Layer 2'%3E%3Cpath d='M37.1 144.49H.76v-21.58c9.27 14 18.73 20.07 29 20.07 9.65 0 17.6-5.11 17.6-13.63C47.32 108.53 0 107.58 0 77.11c0-16.28 13.44-27.25 33.88-27.25h30.47v21.57c-10.6-15-19.11-20.06-29.15-20.06-8.89 0-14.57 4.35-14.57 11.55 0 23.65 51.29 18.54 51.29 54.32 0 17.41-12.11 27.25-34.82 27.25zM89 30.74L115.23.25c.35-.41.63-.3.63.23v144H89zm55.08 55.65l43 58.1h-33.36L125 104.93zm35.58-36.53L149.17 79c2.46-14-5.68-25.36-20.63-29.14zM188.35 119.32c0-36.34 60.57-25.74 60.57-53.38 0-8.7-6.25-14.57-15.52-14.57-9.84 0-20.06 7-29.15 20.06V49.86h34.64c23.11 0 36.72 12.14 36.72 32.74v61.89h-26.88V75C241.35 90.36 216 92.25 216 115.53c0 11.93 6.62 19.5 17 19.5a18.3 18.3 0 0 0 13.25-5.49c-5.49 11-14.76 16.47-27.63 16.47-18.34-.01-30.27-10.41-30.27-26.69zM319.56 144.49V49.86h-26.88v94.63zM323.42 19.42a16 16 0 0 0-18-14 15.65 15.65 0 0 0-12.67 8.85.36.36 0 0 0 .51.46 16 16 0 0 1 8.6-2.49 16.3 16.3 0 0 1 16.26 16.25 16.7 16.7 0 0 1-.68 4.71.35.35 0 0 0 .57.36 16.39 16.39 0 0 0 5.41-14.14z'/%3E%3C/g%3E%3C/svg%3E"
          alt=""
          style={{ width: '40px', height: 'auto' }}
        />
        <div className={'status-flag'} style={{ backgroundColor: '#8cff8f' }}>
          Preferred
        </div>
      </div>
    ),
  },
  {
    field: 'epsilo',
    headerName: 'Epsilo',
    flex: 1,
    sortable: false,
    renderHeader: (GridColumnHeaderParams) => (
      <div className={'col-1-1 flex-center-all'}>
        <img
          src={
            'https://assets.website-files.com/61cee65363a6d118502e331c/61cee65363a6d1c3b52e337d_LOGO%20EPSILO%20LIGHT%20BG%20VERSION%201.png'
          }
          alt=""
          style={{ width: '56px', height: 'auto' }}
        />
        <div className={'status-flag'} style={{ backgroundColor: '#8cff8f' }}>
          Preferred
        </div>
      </div>
    ),
  },
  {
    field: 'intentwise',
    headerName: 'Intentwise',
    flex: 1,
    sortable: false,
    renderHeader: (GridColumnHeaderParams) => (
      <div className={'col-1-1 flex-center-all'}>
        <img
          src={
            'https://assets-global.website-files.com/608431e7927a12b3efd486f3/60e47f4515c4d8458c2e8ae0_intentwise%20light%20logo.svg'
          }
          alt=""
          style={{ width: '92px', height: 'auto' }}
        />
        <div className={'status-flag'} style={{ backgroundColor: '#d2ff69' }}>
          Controlled Testing
        </div>
      </div>
    ),
  },
  {
    field: 'commerce_iq',
    headerName: 'Commerce IQ',
    flex: 1,
    sortable: false,
    renderHeader: (GridColumnHeaderParams) => (
      <div className={'col-1-1 flex-center-all'}>
        <img
          src={
            'https://commerceiq.ai/wp-content/themes/ciq2/assets/img/logo.svg'
          }
          alt=""
          style={{ width: '110px', height: 'auto' }}
        />
        <div className={'status-flag'} style={{ backgroundColor: '#d2ff69' }}>
          Controlled Testing
        </div>
      </div>
    ),
  },
  {
    field: 'perpetua',
    headerName: 'Perpetua',
    flex: 1,
    sortable: false,
    renderHeader: (GridColumnHeaderParams) => (
      <div className={'col-1-1 flex-center-all'}>
        <img
          src={
            'https://lever-client-logos.s3.us-west-2.amazonaws.com/07fffee5-0149-485c-a113-38a276fececa-1637075800335.png'
          }
          alt=""
          style={{ width: '110px', height: 'auto' }}
        />
        <div className={'status-flag'} style={{ backgroundColor: '#ffb52b' }}>
          Unapproved
        </div>
      </div>
    ),
  },
];

export const rows = [
  {
    id: 1,
    area: 'Commercials',
    pacvue_title: 'Self Serve Avg',
    pacvue_ssa: '1.75%',
    skai_title: 'Self Serve Avg',
    skai_ssa: '1.75%',
    epsilo_title: 'Self Serve Avg / Store',
    epsilo_ssa: '$300',
    intentwise_title: 'Self Serve Avg',
    intentwise_ssa: '2.00%',
    commerce_iq_title: 'Self Serve Avg',
    commerce_iq_ssa: '2.00%',
    perpetua_title: 'Self Serve Avg',
    perpetua_ssa: '1.5%',
  },
  {
    id: 2,
    area: 'Market and Retails Coverage',
    pacvue_overall: 34,
    pacvue_latam: 24,
    pacvue_apac: 34,
    pacvue_emea: 12,
    pacvue_na: 44,
    skai_overall: 42,
    skai_latam: 12,
    skai_apac: 49,
    skai_emea: 23,
    skai_na: 16,
    epsilo_overall: 23,
    epsilo_latam: 34,
    epsilo_apac: 12,
    epsilo_emea: 49,
    epsilo_na: 23,
    intentwise_overall: 23,
    intentwise_latam: 34,
    intentwise_apac: 12,
    intentwise_emea: 49,
    intentwise_na: 23,
    commerce_iq_overall: 42,
    commerce_iq_latam: 12,
    commerce_iq_apac: 49,
    commerce_iq_emea: 23,
    commerce_iq_na: 16,
    perpetua_overall: 42,
    perpetua_latam: 12,
    perpetua_apac: 49,
    perpetua_emea: 23,
    perpetua_na: 16,
  },
  {
    id: 3,
    area: 'Search Capabilities',
    pacvue_overall: 55,
    pacvue_bid_rules: 23,
    pacvue_optimization: 34,
    skai_overall: 23,
    skai_bid_rules: 12,
    skai_optimization: 49,
    epsilo_overall: 12,
    epsilo_bid_rules: 49,
    epsilo_optimization: 23,
    intentwise_overall: 44,
    intentwise_bid_rules: 65,
    intentwise_optimization: 36,
    commerce_iq_overall: 83,
    commerce_iq_bid_rules: 22,
    commerce_iq_optimization: 67,
    perpetua_overall: 25,
    perpetua_bid_rules: 88,
    perpetua_optimization: 32,
  },
  {
    id: 4,
    area: 'DSP Capabilities',
    pacvue_overall: 55,
    pacvue_bid_rules: 23,
    pacvue_optimization: 34,
    skai_overall: 23,
    skai_bid_rules: 12,
    skai_optimization: 49,
    epsilo_overall: 12,
    epsilo_bid_rules: 49,
    epsilo_optimization: 23,
    intentwise_overall: 43,
    intentwise_bid_rules: 49,
    intentwise_optimization: 23,
    commerce_iq_overall: 76,
    commerce_iq_bid_rules: 49,
    commerce_iq_optimization: 23,
    perpetua_overall: 12,
    perpetua_bid_rules: 49,
    perpetua_optimization: 23,
  },
  {
    id: 5,
    area: 'Reporting',
    pacvue_overall: 33,
    skai_overall: 55,
    epsilo_overall: 76,
    intentwise_overall: 36,
    commerce_iq_overall: 76,
    perpetua_overall: 56,
  },
  {
    id: 6,
    area: 'Models & Methodologies',
    pacvue_title: 'Rules Based Automation',
    pacvue_description: 'Advanced SOV scraping, up to 12x/day and weighting',
    skai_title: 'Rules Based Automation',
    skai_description: 'Advanced SOV scraping, up to 12x/day and weighting',
    epsilo_title: 'Rules Based Automation',
    epsilo_description: 'Advanced SOV scraping, up to 12x/day and weighting',
    intentwise_title: 'Rules Based Automation',
    intentwise_description:
      'Advanced SOV scraping, up to 12x/day and weighting',
    commerce_iq_title: 'Rules Based Automation',
    commerce_iq_description:
      'Advanced SOV scraping, up to 12x/day and weighting',
    perpetua_title: 'Rules Based Automation',
    perpetua_description: 'Advanced SOV scraping, up to 12x/day and weighting',
  },
  {
    id: 7,
    area: 'Platform UX',
    pacvue_overall: 65,
    skai_overall: 21,
    epsilo_overall: 44,
    intentwise_overall: 54,
    commerce_iq_overall: 89,
    perpetua_overall: 13,
  },
  {
    id: 8,
    area: 'X Factor Differentiators',
    pacvue_description:
      'GroupM JBP in place: Market-leading bid rule and automation capabilities: Pacvue Commerce available for retail operations',
    skai_description:
      'GroupM JBP in place: Market-leading bid rule and automation capabilities: Pacvue Commerce available for retail operations',
    epsilo_description:
      'GroupM JBP in place: Market-leading bid rule and automation capabilities: Pacvue Commerce available for retail operations',
    intentwise_description:
      'GroupM JBP in place: Market-leading bid rule and automation capabilities: Pacvue Commerce available for retail operations',
    commerce_iq_description:
      'GroupM JBP in place: Market-leading bid rule and automation capabilities: Pacvue Commerce available for retail operations',
    perpetua_description:
      'GroupM JBP in place: Market-leading bid rule and automation capabilities: Pacvue Commerce available for retail operations',
  },
];

export const mockData = {
  data: [
    {
      asin: 'B08GGCQCCB',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0291262136,
      glanceViews: 103,
      itemName:
        'Dell S2721H 27 Inch Full HD (1920x1080) Monitor, 75Hz, IPS, 4ms, AMD FreeSync, Built-in Speakers, Ultrathin Bezel, 2x HDMI, 3 Year Warranty, Silver',
      netPpm: 1.0,
      orderedUnits: 3,
      price: 199.99,
      revenue: 445.36,
      salesRank: 836,
    },
    {
      asin: 'B003XLEDYM',
      brand: 'Supa',
      classification: 'Bird Feeders (Garden)',
      conversionRate: 0.1452702703,
      glanceViews: 2072,
      itemName:
        'Supa Wild Bird Caged Seed Feeder Deters Squirrels and Larger Birds Such As Doves And Pigeons.',
      netPpm: 0.7447,
      orderedUnits: 301,
      price: 15.99,
      revenue: 3722.13,
      salesRank: 923,
    },
    {
      asin: 'B003XLJK4A',
      brand: 'Supa',
      classification: 'Backyard Bird Care',
      conversionRate: 0.0859375,
      glanceViews: 128,
      itemName:
        'Supa Caged Fat Ball Wild Bird Feeder | Designed To Deter Squirrels And Also Larger Garden Birds Such As Pigeons And Doves',
      netPpm: 0.6987,
      orderedUnits: 11,
      price: 13.08,
      revenue: 119.9,
      salesRank: 1301,
    },
    {
      asin: 'B003XLEDSS',
      brand: 'Supa',
      classification: 'Bird Feeders (Garden)',
      conversionRate: 0.1465346535,
      glanceViews: 1010,
      itemName:
        'Supa Wild Bird Caged Peanut Feeder, Deters Squirrels And Larger Birds Such As Doves & Pigeons.',
      netPpm: 0.6704,
      orderedUnits: 148,
      price: 15.99,
      revenue: 1403.04,
      salesRank: 822,
    },
    {
      asin: 'B093RJF8KR',
      brand: 'Alienware',
      classification: 'Desktop PCs',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Alienware Aurora R12 Gaming Desktop, Intel Core i7-11700KF, NVIDIA GeForce RTX 3070 8GB, 16GB HyperX FURY RAM, 512GB SSD + 1TB HDD, Liquid Cooling, Windows 10 Home (Lunar Light)',
      netPpm: 0.5835,
      orderedUnits: 0,
      price: 1499.0,
      revenue: 0.0,
      salesRank: 323,
    },
    {
      asin: 'B07D6W5FHW',
      brand: 'Supa',
      classification: 'Pond Fish Food',
      conversionRate: 0.2,
      glanceViews: 5,
      itemName:
        'Supa Koi Silkworm Pupae, 5 Litre Bucket | A Highly Nutritious 100% Natural Protein Rich Treat Food For Koi And Other Pond Fish.',
      netPpm: 0.3816,
      orderedUnits: 1,
      price: 46.99,
      revenue: 27.1,
      salesRank: 471,
    },
    {
      asin: 'B07W2XJPDS',
      brand: 'Dell',
      classification: 'Keyboards (Computers & Accessories)',
      conversionRate: 0.2846975089,
      glanceViews: 843,
      itemName: 'Dell Multimedia Keyboard-KB216 - UK (QWERTY) - Black',
      netPpm: 0.3322,
      orderedUnits: 240,
      price: 23.37,
      revenue: 4521.06,
      salesRank: 20,
    },
    {
      asin: 'B0B64CC5MH',
      brand: 'Dell',
      classification: 'Traditional Laptops',
      conversionRate: 0.0111244738,
      glanceViews: 6652,
      itemName:
        'Dell Inspiron 3511 15.6" FHD Laptop, Intel Core i5-1135G7, 8GB RAM, 512GB SSD, Windows 11 Home (Platinum Silver)',
      netPpm: 0.3225,
      orderedUnits: 74,
      price: 529.99,
      revenue: 32221.66,
      salesRank: 41,
    },
    {
      asin: 'B09WNDRL71',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: -0.036036036,
      glanceViews: 222,
      itemName:
        'Dell G2723HN 27 Inch Full HD (1920x1080) Gaming Monitor, 165Hz, Fast IPS, 1ms, AMD FreeSync Premium, NVIDIA G-SYNC Compatible, 99% sRGB, DisplayPort, 2x HDMI, 3 Year Warranty, Black',
      netPpm: 0.3175,
      orderedUnits: -8,
      price: 219.99,
      revenue: -1051.36,
      salesRank: 885,
    },
    {
      asin: 'B07PT4XJJL',
      brand: 'Dell',
      classification: 'Laptop Sleeves',
      conversionRate: 0.125,
      glanceViews: 8,
      itemName:
        'Dell Essential Sleeve 15 - ES1520V - Fits Most Laptops up to 15 inch',
      netPpm: 0.3046,
      orderedUnits: 1,
      price: 21.9,
      revenue: 19.73,
      salesRank: 409,
    },
    {
      asin: 'B081774RZ9',
      brand: 'Alienware',
      classification: 'PC Gaming Keyboards',
      conversionRate: 0.0062034739,
      glanceViews: 806,
      itemName:
        'Alienware 510K Low-Profile RGB Mechanical Gaming Keyboard, US Layout - AW510K (Lunar Light)',
      netPpm: 0.2917,
      orderedUnits: 5,
      price: 139.99,
      revenue: 433.74,
      salesRank: 688,
    },
    {
      asin: 'B0B6G3V851',
      brand: 'Dell',
      classification: '2 in 1 Laptops',
      conversionRate: 0.007120743,
      glanceViews: 3230,
      itemName:
        'Dell Inspiron 7420 14" FHD+ 2-in-1 Laptop, Intel Core i5-1235U, 8GB RAM, 512GB SSD, Touchscreen, Backlit Keyboard, Fingerprint Reader, Windows 11 Home (Platinum Silver)',
      netPpm: 0.2739,
      orderedUnits: 23,
      price: 829.0,
      revenue: 12408.32,
      salesRank: 59,
    },
    {
      asin: 'B0BVWCP8RK',
      brand: 'Dell',
      classification: null,
      conversionRate: 0.0242529233,
      glanceViews: 2309,
      itemName:
        'Dell S3221QSA 31.5 Inch 4K UHD (3840x2160) 1800R Curved Monitor, 60Hz, VA, 4ms, AMD FreeSync, 99% sRGB, Built-in Speakers, DisplayPort, 2x HDMI, 3x USB 3.0, 3 Year Warranty',
      netPpm: 0.2584,
      orderedUnits: 56,
      price: 389.99,
      revenue: 15142.72,
      salesRank: null,
    },
    {
      asin: 'B08MSR3BCB',
      brand: 'Dell',
      classification: 'Laptop Chargers & Power Supplies',
      conversionRate: 0.0555555556,
      glanceViews: 18,
      itemName: 'Dell EMC DELL Slim SB521A - Sound Bar',
      netPpm: 0.2556,
      orderedUnits: 1,
      price: 40.0,
      revenue: 38.12,
      salesRank: 2510,
    },
    {
      asin: 'B08MSR3BCB',
      brand: 'Dell',
      classification: 'Laptop Chargers & Power Supplies',
      conversionRate: 0.0555555556,
      glanceViews: 18,
      itemName: 'Dell EMC DELL Slim SB521A - Sound Bar',
      netPpm: 0.2556,
      orderedUnits: 1,
      price: 40.0,
      revenue: 38.12,
      salesRank: 2510,
    },
    {
      asin: 'B07D9DNLP1',
      brand: 'Dell',
      classification: null,
      conversionRate: 0.0638297872,
      glanceViews: 47,
      itemName:
        'Dell Wired Mouse with Fingerprint Reader - MS819 *Same as 570-AARY*',
      netPpm: 0.254,
      orderedUnits: 3,
      price: 36.6,
      revenue: 74.58,
      salesRank: null,
    },
    {
      asin: 'B09WNCZ35L',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0047874825,
      glanceViews: 8564,
      itemName:
        'Dell G3223D 31.5 Inch QHD (2560x1440) Gaming Monitor, 165Hz, Fast IPS, 1ms, AMD FreeSync Premium Pro, G-SYNC Compatible, 95% DCI-P3, HDR 400, USB-C, DisplayPort, 2x HDMI, 2x USB, 3 Year Warranty',
      netPpm: 0.2539,
      orderedUnits: 41,
      price: 409.99,
      revenue: 11663.77,
      salesRank: 829,
    },
    {
      asin: 'B00O0M46KO',
      brand: 'Dell',
      classification: 'Docking Stations',
      conversionRate: 0.0491942324,
      glanceViews: 3537,
      itemName:
        'Dell D3100 Docking Station, USB 3.0 Ultra HD Triple Video (DisplayPort, 2x HDMI, 6x USB, RJ45) Black - UK Version',
      netPpm: 0.251,
      orderedUnits: 174,
      price: 222.9,
      revenue: 22920.27,
      salesRank: 8,
    },
    {
      asin: 'B09CGYHJ84',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0244089317,
      glanceViews: 9136,
      itemName:
        'Dell S2722DC USB-C 27 Inch QHD (2560x1440) Monitor, 75Hz, IPS, 4ms, AMD FreeSync, 99% sRGB, Built-in Speakers, USB-C, 2x HDMI, 2x USB, 3 Year Warranty',
      netPpm: 0.2492,
      orderedUnits: 223,
      price: 299.99,
      revenue: 44699.1,
      salesRank: 98,
    },
    {
      asin: 'B09CPXQSXS',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.03307393,
      glanceViews: 2056,
      itemName:
        'Dell S2422HZ 24 Inch Full HD (1920x1080) Video Conferencing Monitor, 75Hz, IPS, 4ms, AMD FreeSync, 99% sRGB, Built-in Speakers, 5MP Camera, Mic, USB-C, DisplayPort, HDMI, 2x USB, 3 Year Warranty',
      netPpm: 0.2477,
      orderedUnits: 68,
      price: 299.99,
      revenue: 12294.8,
      salesRank: 171,
    },
    {
      asin: 'B0B64DCFFX',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: 0.0103466115,
      glanceViews: 1933,
      itemName:
        'Dell Inspiron 3520 15.6" FHD 120Hz Laptop, Intel Core i7-1255U, 16GB RAM, 512GB SSD, Windows 11 Home (Platinum Silver)',
      netPpm: 0.2463,
      orderedUnits: 20,
      price: 879.0,
      revenue: 13743.57,
      salesRank: 78,
    },
    {
      asin: 'B0BDG2WY42',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0894736842,
      glanceViews: 380,
      itemName:
        'Dell E2423H 24 Inch Full HD (1920x1080) Monitor, 60Hz, VA, 5ms, DisplayPort, VGA, 3 Year Warranty, Black',
      netPpm: 0.2423,
      orderedUnits: 34,
      price: 134.4,
      revenue: 3490.8,
      salesRank: 560,
    },
    {
      asin: 'B0BDG2WY42',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0894736842,
      glanceViews: 380,
      itemName:
        'Dell E2423H 24 Inch Full HD (1920x1080) Monitor, 60Hz, VA, 5ms, DisplayPort, VGA, 3 Year Warranty, Black',
      netPpm: 0.2423,
      orderedUnits: 34,
      price: 134.4,
      revenue: 3490.8,
      salesRank: 560,
    },
    {
      asin: 'B0BDG2WY42',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0894736842,
      glanceViews: 380,
      itemName:
        'Dell E2423H 24 Inch Full HD (1920x1080) Monitor, 60Hz, VA, 5ms, DisplayPort, VGA, 3 Year Warranty, Black',
      netPpm: 0.2423,
      orderedUnits: 34,
      price: 134.4,
      revenue: 3490.8,
      salesRank: 560,
    },
    {
      asin: 'B0BDG2WY42',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0894736842,
      glanceViews: 380,
      itemName:
        'Dell E2423H 24 Inch Full HD (1920x1080) Monitor, 60Hz, VA, 5ms, DisplayPort, VGA, 3 Year Warranty, Black',
      netPpm: 0.2423,
      orderedUnits: 34,
      price: 134.4,
      revenue: 3490.8,
      salesRank: 560,
    },
    {
      asin: 'B082X8JN8R',
      brand: 'Alienware',
      classification: 'PC Gaming Keyboards',
      conversionRate: 0.0091743119,
      glanceViews: 218,
      itemName:
        'Alienware 510K Low-Profile RGB Mechanical Gaming Keyboard, US Layout - AW510K (Dark Side Of The Moon)',
      netPpm: 0.2384,
      orderedUnits: 2,
      price: 139.99,
      revenue: 213.39,
      salesRank: 480,
    },
    {
      asin: 'B09XBPCKSG',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.04181855,
      glanceViews: 9876,
      itemName:
        'Dell S2421H 24 Inch Full HD (1920x1080) Monitor, 75Hz, IPS, 4ms, AMD FreeSync, Built-in Speakers, Ultrathin Bezel, 2x HDMI, 3 Year Warranty, Silver',
      netPpm: 0.2364,
      orderedUnits: 413,
      price: 149.99,
      revenue: 42531.83,
      salesRank: 38,
    },
    {
      asin: 'B0BF5SJFN8',
      brand: 'Alienware',
      classification: 'Monitors',
      conversionRate: 0.0070019096,
      glanceViews: 3142,
      itemName:
        'Alienware AW2523HF 24.5 Inch Full HD (1920x1080) Gaming Monitor, 360Hz, Fast IPS, 0.5ms, AMD FreeSync Premium, VESA AdaptiveSync, 99% sRGB, HDR 10, DisplayPort, 2x HDMI, 5x USB, 3 Year Warranty',
      netPpm: 0.2282,
      orderedUnits: 22,
      price: 399.99,
      revenue: 7183.04,
      salesRank: 186,
    },
    {
      asin: 'B01GUQIUI6',
      brand: 'Dell',
      classification: 'DVI to HDMI Adapters',
      conversionRate: 0.0625,
      glanceViews: 16,
      itemName:
        'Dell Adapter - Display Port to VGA 470-ABEL *Same as 470-ABEL*',
      netPpm: 0.2202,
      orderedUnits: 1,
      price: 22.99,
      revenue: 22.8,
      salesRank: 614,
    },
    {
      asin: 'B09WNFPGGF',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.1381578947,
      glanceViews: 456,
      itemName:
        'Dell S2723HC USB-C 27 Inch Full HD (1920x1080) Monitor, 75Hz, IPS, 4ms, AMD FreeSync, 99% sRGB, Built-in Speakers, USB-C, HDMI, 2x USB, 3 Year Warranty',
      netPpm: 0.2176,
      orderedUnits: 63,
      price: 250.0,
      revenue: 11854.0,
      salesRank: 657,
    },
    {
      asin: 'B0BJ2QNP6G',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell G15 5520 15.6" FHD 165Hz Gaming Laptop, Intel Core i9-12900H, NVIDIA GeForce RTX 3070 8GB, 16GB RAM, 1TB SSD, Backlit Keyboard, Windows 11 Home (Dark Shadow Grey)',
      netPpm: 0.2123,
      orderedUnits: 0,
      price: 1649.0,
      revenue: 0.0,
      salesRank: 1701,
    },
    {
      asin: 'B095749V5V',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.1603745092,
      glanceViews: 3311,
      itemName:
        'Dell SE2222H 21.5 Inch Full HD (1920x1080) Monitor, 60 Hz, VA, HDMI, VGA, 3 Year Warranty, Black',
      netPpm: 0.2112,
      orderedUnits: 531,
      price: 104.99,
      revenue: 43083.02,
      salesRank: 32,
    },
    {
      asin: 'B0BW128B19',
      brand: 'Dell',
      classification: null,
      conversionRate: 0.0211930926,
      glanceViews: 1274,
      itemName:
        'Dell S2721QSA 27 Inch 4K UHD (3840x2160) Monitor, 60Hz, IPS, 4ms, AMD Radeon FreeSync, Built-in Speakers, 99% sRGB, DisplayPort, 2x HDMI, 3 Year Warranty, Silver',
      netPpm: 0.2084,
      orderedUnits: 27,
      price: 329.99,
      revenue: 7265.7,
      salesRank: null,
    },
    {
      asin: 'B01ALB3BW6',
      brand: 'Dell',
      classification: 'Mice',
      conversionRate: 0.25,
      glanceViews: 8,
      itemName: 'Dell Wireless Mouse WM126 Black p/n 570-AAMH',
      netPpm: 0.2077,
      orderedUnits: 2,
      price: 15.0,
      revenue: 25.51,
      salesRank: 114,
    },
    {
      asin: 'B09WNDM5WP',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0066847335,
      glanceViews: 5535,
      itemName:
        'Dell Alienware AW3423DW 34 Inch WQHD 21:9 1800R Curved Gaming Monitor, 175Hz, QD OLED, 0.1ms, NVIDIA G-SYNC Ultimate, 99.3% DCI-P3, HDR400, DisplayPort, 2x HDMI, 5x USB, 3 Year Warranty,White',
      netPpm: 0.2048,
      orderedUnits: 37,
      price: 1099.99,
      revenue: 33830.84,
      salesRank: 168,
    },
    {
      asin: 'B0BHTBK38K',
      brand: 'Alienware',
      classification: 'Monitors',
      conversionRate: 0.0108864697,
      glanceViews: 643,
      itemName:
        'Alienware AW2723DF 27 Inch QHD (2560x1440) Gaming Monitor, 280Hz (OC), Fast IPS, 1ms, AMD FreeSync Premium Pro, NVIDIA G-SYNC Compatible, 95% DCI-P3, HDR, DisplayPort, 2x HDMI, 5x USB, 3 Year Warranty',
      netPpm: 0.203,
      orderedUnits: 7,
      price: 539.99,
      revenue: 3362.12,
      salesRank: 273,
    },
    {
      asin: 'B0BHTDDMDN',
      brand: 'Alienware',
      classification: 'Monitors',
      conversionRate: 0.0080932341,
      glanceViews: 3089,
      itemName:
        'Alienware AW3423DWF 34 Inch WQHD (3440x1440) 21:9 1800R Curved Gaming Monitor, 165Hz, QD OLED, 0.1ms, AMD FreeSync Premium Pro, 99.3% DCI-P3, HDR400, 2x DisplayPort, 1x HDMI, 5x USB, 3 Year Warranty',
      netPpm: 0.203,
      orderedUnits: 25,
      price: 879.99,
      revenue: 19305.68,
      salesRank: 83,
    },
    {
      asin: 'B095C423Z4',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.008073197,
      glanceViews: 1858,
      itemName:
        'Dell S3222DGM 31.5 inch QHD (2560x1440) 1800R Curved Gaming Monitor, 165Hz, VA, 2ms, AMD FreeSync Premium, DisplayPort, 2x HDMI, 3 Year Warranty',
      netPpm: 0.202,
      orderedUnits: 15,
      price: 349.99,
      revenue: 4374.9,
      salesRank: 848,
    },
    {
      asin: 'B09RWQ34ZG',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: -0.0101574403,
      glanceViews: 1969,
      itemName:
        'Dell G2422HS 24 Inch Full HD (1920x1080) Gaming Monitor, 165Hz, Fast IPS, 1ms, AMD FreeSync Premium, NVIDIA G-SYNC Compatible, Height Adjust, 99% sRGB, DisplayPort, 2x HDMI, 3 Year Warranty, Black',
      netPpm: 0.199,
      orderedUnits: -20,
      price: 199.99,
      revenue: -1716.86,
      salesRank: 327,
    },
    {
      asin: 'B0BBGJWDHQ',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0010718114,
      glanceViews: 933,
      itemName:
        'Dell G2723H 27 Inch Full HD (1920x1080) Gaming Monitor, 280Hz (OC), Fast IPS, 0.5ms, AMD FreeSync Premium, NVIDIA G-SYNC Compatible, 99% sRGB, DisplayPort, 2x HDMI, 5x USB, 3 Year Warranty, Grey',
      netPpm: 0.1946,
      orderedUnits: 1,
      price: 279.99,
      revenue: 233.32,
      salesRank: 1630,
    },
    {
      asin: 'B09WNG9QWR',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0105613426,
      glanceViews: 6912,
      itemName:
        'Dell G3223Q 32 Inch 4K UHD (3840x2160) Gaming Monitor, 144Hz, Fast IPS, 1ms, AMD FreeSync Premium Pro, 95% DCI-P3, HDR 600, DisplayPort, 2x HDMI, 3x USB, 3 Year Warranty, Black',
      netPpm: 0.1944,
      orderedUnits: 73,
      price: 659.99,
      revenue: 37007.28,
      salesRank: 104,
    },
    {
      asin: 'B0BJ2RMR4S',
      brand: 'Alienware',
      classification: 'Traditional Laptops',
      conversionRate: 0.0002949853,
      glanceViews: 3390,
      itemName:
        'Alienware x17 R2 17.3" FHD 480Hz Gaming Laptop, Intel Core i9-12900HK, NVIDIA GeForce RTX 3080 Ti 16GB, 32GB RAM, 1TB SSD, Windows 11 Home (Lunar Light), WWMDX',
      netPpm: 0.1942,
      orderedUnits: 1,
      price: 3799.0,
      revenue: 2666.66,
      salesRank: 188,
    },
    {
      asin: 'B094S75LQW',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0410958904,
      glanceViews: 2117,
      itemName:
        'Dell S3422DW 34 inch WQHD (3440x1440) 21:9 1800R Curved Monitor, 100Hz, VA, 4ms, AMD Radeon FreeSync, Built-in Speakers, DisplayPort, 2x HDMI, 3x USB, 3 Year Warranty',
      netPpm: 0.1892,
      orderedUnits: 87,
      price: 439.99,
      revenue: 30452.49,
      salesRank: 622,
    },
    {
      asin: 'B0BDFYLZ1N',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.2424242424,
      glanceViews: 33,
      itemName:
        'Dell E2423HN 24 Inch Full HD (1920x1080) Monitor, 60Hz, VA, 5ms, HDMI, VGA, 3 Year Warranty, Black',
      netPpm: 0.1879,
      orderedUnits: 8,
      price: 129.6,
      revenue: 864.0,
      salesRank: 1688,
    },
    {
      asin: 'B0BDFYLZ1N',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.2424242424,
      glanceViews: 33,
      itemName:
        'Dell E2423HN 24 Inch Full HD (1920x1080) Monitor, 60Hz, VA, 5ms, HDMI, VGA, 3 Year Warranty, Black',
      netPpm: 0.1879,
      orderedUnits: 8,
      price: 129.6,
      revenue: 864.0,
      salesRank: 1688,
    },
    {
      asin: 'B0BDFYLZ1N',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.2424242424,
      glanceViews: 33,
      itemName:
        'Dell E2423HN 24 Inch Full HD (1920x1080) Monitor, 60Hz, VA, 5ms, HDMI, VGA, 3 Year Warranty, Black',
      netPpm: 0.1879,
      orderedUnits: 8,
      price: 129.6,
      revenue: 864.0,
      salesRank: 1688,
    },
    {
      asin: 'B0B64BHW4X',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: 0.0036900369,
      glanceViews: 1084,
      itemName:
        'Dell Inspiron 5425 14" FHD+ Laptop, AMD Ryzen 7 5825U, 8GB RAM, 512GB SSD, Backlit Keyboard, Fingerprint Reader, Windows 11 Home (Platinum Silver)',
      netPpm: 0.1856,
      orderedUnits: 4,
      price: 749.0,
      revenue: 2109.28,
      salesRank: 3428,
    },
    {
      asin: 'B0B64B8Q6T',
      brand: 'Dell',
      classification: 'Traditional Laptops',
      conversionRate: 0.0150773707,
      glanceViews: 7561,
      itemName:
        'Dell Inspiron 3525 15.6" FHD 120Hz Laptop, AMD Ryzen 5 5500U, 8GB RAM, 256GB SSD, Windows 11 Home (Carbon Black)',
      netPpm: 0.1855,
      orderedUnits: 114,
      price: 529.0,
      revenue: 38117.45,
      salesRank: 141,
    },
    {
      asin: 'B0B1528T5Z',
      brand: 'Dell',
      classification: 'Desktop PCs',
      conversionRate: 0.0053859964,
      glanceViews: 557,
      itemName:
        'Dell Inspiron 5410 23.8" FHD All-In-One Desktop, Intel Core i5-1235U, 8GB RAM, 512GB SSD + 1TB HDD, Windows 11 Home (Pearl White)',
      netPpm: 0.1803,
      orderedUnits: 3,
      price: 849.0,
      revenue: 1872.51,
      salesRank: 91,
    },
    {
      asin: 'B08NFDFBQZ',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0020380435,
      glanceViews: 1472,
      itemName:
        'Alienware AW2521H 24.5 Inch Full HD (1920x1080) Gaming Monitor, 360Hz, Fast IPS, 1ms, NVIDIA G-SYNC, 99% sRGB, HDR 10, DisplayPort, 2x HDMI, 5x USB, 3 Year Warranty',
      netPpm: 0.1785,
      orderedUnits: 3,
      price: 599.99,
      revenue: 1448.78,
      salesRank: 1371,
    },
    {
      asin: 'B09WND7Y7J',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0155733837,
      glanceViews: 2119,
      itemName:
        'Dell SE3223Q 31.5 Inch 4K UHD (3840x2160) Monitor, 60Hz, VA, 4ms, AMD FreeSync, 99% sRGB, DisplayPort, 2x HDMI, 3 Year Warranty, Black',
      netPpm: 0.1775,
      orderedUnits: 33,
      price: 309.99,
      revenue: 8556.2,
      salesRank: 101,
    },
    {
      asin: 'B0957KH8X7',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.1121008038,
      glanceViews: 4603,
      itemName:
        'Dell SE2722HX 27 inch Full HD (1920 x 1080) Monitor, 75Hz, VA, 4ms, AMD FreeSync, HDMI, VGA, 3 Year Warranty, Black',
      netPpm: 0.1771,
      orderedUnits: 516,
      price: 139.99,
      revenue: 59991.94,
      salesRank: 15,
    },
    {
      asin: 'B0B64CHM2R',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: 0.0068119891,
      glanceViews: 734,
      itemName:
        'Dell Inspiron 3511 15.6" FHD Laptop, Intel Core i3-1115G4, 4GB RAM, 128GB SSD, Windows 11 Home (Carbon Black)',
      netPpm: 0.1702,
      orderedUnits: 5,
      price: 429.0,
      revenue: 1787.5,
      salesRank: 563,
    },
    {
      asin: 'B0957LFHTM',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.2087575368,
      glanceViews: 10117,
      itemName:
        'Dell SE2422HX 24 Inch Full HD (1920 x 1080) Monitor, 75Hz, VA, 5ms, AMD FreeSync, HDMI, VGA, 3 Year Warranty',
      netPpm: 0.1684,
      orderedUnits: 2112,
      price: 124.99,
      revenue: 191577.37,
      salesRank: 2,
    },
    {
      asin: 'B086RYL2C9',
      brand: 'Dell',
      classification: 'Computer Monitor Arms',
      conversionRate: 0.0566572238,
      glanceViews: 353,
      itemName:
        'Dell Single Monitor Arm - MSA20 - Mounting kit - for LCD display (adjustable arm) - black - screen size: 19"-38" - mounting interface: 100 x 100 mm - desk-mountable',
      netPpm: 0.1668,
      orderedUnits: 20,
      price: 107.5,
      revenue: 2407.12,
      salesRank: 203,
    },
    {
      asin: 'B0B6FP6YHQ',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: 0.0011918951,
      glanceViews: 839,
      itemName:
        'Dell XPS 17 9720 17" UHD+ Laptop, Intel Core i7-12700H, 16GB RAM, 1TB SSD, NVIDIA GeForce RTX 3060 6GB, Touchscreen, Backlit Keyboard, Fingerprint Reader, Windows 11 Home (Silver)',
      netPpm: 0.1663,
      orderedUnits: 1,
      price: 2699.0,
      revenue: 2249.17,
      salesRank: 2990,
    },
    {
      asin: 'B09TWB4FVQ',
      brand: 'Alienware',
      classification: 'Laptops',
      conversionRate: 0.0038910506,
      glanceViews: 257,
      itemName:
        'Alienware m15 R7 15.6" QHD 240Hz Gaming Laptop, Intel Core i7-12700H, NVIDIA GeForce RTX 3060 6GB, 16GB RAM, 1TB SSD, NVIDIA G-SYNC, Windows 11 Home (Dark side of the moon)',
      netPpm: 0.1663,
      orderedUnits: 1,
      price: 1899.0,
      revenue: 1582.5,
      salesRank: 2692,
    },
    {
      asin: 'B0B6G1VX3G',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: 0.00125,
      glanceViews: 800,
      itemName:
        'Dell XPS 17 9720 17" FHD+ Laptop, Intel Core i7-12700H, 16GB RAM, 512GB SSD, NVIDIA GeForce RTX 3050 4GB, Backlit Keyboard, Fingerprint Reader, Windows 11 Home (Silver)',
      netPpm: 0.166,
      orderedUnits: 1,
      price: 2099.0,
      revenue: 1749.17,
      salesRank: 1967,
    },
    {
      asin: 'B0B6FP9DKQ',
      brand: 'Dell',
      classification: 'Traditional Laptops',
      conversionRate: 0.0015812062,
      glanceViews: 4427,
      itemName:
        'Dell G15 5520 15.6" FHD 165Hz Gaming Laptop, Intel Core i7-12700H, NVIDIA GeForce RTX 3060 6GB, 16GB RAM, 512GB SSD, Backlit Keyboard, Windows 11 Home (Grey)',
      netPpm: 0.1636,
      orderedUnits: 7,
      price: 1249.0,
      revenue: 6416.62,
      salesRank: 807,
    },
    {
      asin: 'B09CPYZRPD',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.02,
      glanceViews: 100,
      itemName:
        'Dell S2722DZ 27 Inch QHD (2560x1440) Video Conferencing Monitor, 75Hz, IPS, 4ms, AMD FreeSync, 99% sRGB, Built-in Speakers, 5MP Camera, Mic, USB-C, DisplayPort, HDMI, 2x USB, 3 Year Warranty',
      netPpm: 0.1626,
      orderedUnits: 2,
      price: 389.99,
      revenue: 578.08,
      salesRank: 135,
    },
    {
      asin: 'B09TXZL3YC',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0661764706,
      glanceViews: 136,
      itemName:
        'Dell P2223HC 22 Inch FHD (1920 x 1080) USB-C Monitor, 60Hz, 5ms, IPS, HDMI, DisplayPort x2, USB-C, USB-A x4, 3 Year Warranty, Black',
      netPpm: 0.1612,
      orderedUnits: 9,
      price: 213.6,
      revenue: 1527.12,
      salesRank: 777,
    },
    {
      asin: 'B09TXZL3YC',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0661764706,
      glanceViews: 136,
      itemName:
        'Dell P2223HC 22 Inch FHD (1920 x 1080) USB-C Monitor, 60Hz, 5ms, IPS, HDMI, DisplayPort x2, USB-C, USB-A x4, 3 Year Warranty, Black',
      netPpm: 0.1612,
      orderedUnits: 9,
      price: 213.6,
      revenue: 1527.12,
      salesRank: 777,
    },
    {
      asin: 'B09TXZL3YC',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0661764706,
      glanceViews: 136,
      itemName:
        'Dell P2223HC 22 Inch FHD (1920 x 1080) USB-C Monitor, 60Hz, 5ms, IPS, HDMI, DisplayPort x2, USB-C, USB-A x4, 3 Year Warranty, Black',
      netPpm: 0.1612,
      orderedUnits: 9,
      price: 213.6,
      revenue: 1527.12,
      salesRank: 777,
    },
    {
      asin: 'B09TXZL3YC',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0661764706,
      glanceViews: 136,
      itemName:
        'Dell P2223HC 22 Inch FHD (1920 x 1080) USB-C Monitor, 60Hz, 5ms, IPS, HDMI, DisplayPort x2, USB-C, USB-A x4, 3 Year Warranty, Black',
      netPpm: 0.1612,
      orderedUnits: 9,
      price: 213.6,
      revenue: 1527.12,
      salesRank: 777,
    },
    {
      asin: 'B09TWB362S',
      brand: 'Alienware',
      classification: 'Laptops',
      conversionRate: 0.0009917355,
      glanceViews: 3025,
      itemName:
        'Alienware m15 R7 15.6" QHD 240Hz Gaming Laptop, Intel Core i7-12700H, NVIDIA GeForce RTX 3080 Ti 16GB, 32GB RAM, 1TB SSD, NVIDIA G-SYNC, Windows 11 Home (Dark side of the moon)',
      netPpm: 0.161,
      orderedUnits: 3,
      price: 2999.0,
      revenue: 7555.67,
      salesRank: 2102,
    },
    {
      asin: 'B09WYWLD8L',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0801308258,
      glanceViews: 3669,
      itemName:
        'Dell SE2723DS 27 Inch QHD (2560x1440) Monitor, 75Hz, IPS, 4ms, AMD FreeSync, Height Adjust, 99% sRGB, DisplayPort, 2x HDMI, 3 Year Warranty,Black',
      netPpm: 0.147,
      orderedUnits: 294,
      price: 209.99,
      revenue: 46754.25,
      salesRank: 208,
    },
    {
      asin: 'B0BJ2SR9YQ',
      brand: 'Dell',
      classification: 'Traditional Laptops',
      conversionRate: 0.0032467532,
      glanceViews: 924,
      itemName:
        'Dell Inspiron 3521 15.6" FHD 120Hz Laptop, Intel Pentium, 4GB RAM, 128GB SSD, Windows 11 Home S Mode (Carbon Black)',
      netPpm: 0.1457,
      orderedUnits: 3,
      price: 399.0,
      revenue: 997.5,
      salesRank: 102,
    },
    {
      asin: 'B09CGY99X5',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0504749488,
      glanceViews: 5369,
      itemName:
        'Dell S2722QC USB-C 27 Inch 4K UHD (3840x2160) Monitor, 60Hz, IPS, 4ms, AMD FreeSync, 99% sRGB, HDR, Built-in Speakers, USB-C, 2x HDMI, 2x USB, 3 Year Warranty',
      netPpm: 0.1448,
      orderedUnits: 271,
      price: 369.99,
      revenue: 72849.49,
      salesRank: 26,
    },
    {
      asin: 'B0BJ2PRTMP',
      brand: 'Dell',
      classification: '2 in 1 Laptops',
      conversionRate: 0.0097911227,
      glanceViews: 1532,
      itemName:
        'Dell Inspiron 7425 14" FHD+ 2-in-1 Laptop, AMD Ryzen 7 5825U, 16GB RAM, 512GB SSD, Touchscreen, Backlit Keyboard, Fingerprint Reader, Windows 11 Home (Pebble Green)',
      netPpm: 0.1431,
      orderedUnits: 15,
      price: 979.0,
      revenue: 9925.8,
      salesRank: 103,
    },
    {
      asin: 'B07PMRMGFK',
      brand: 'Dell',
      classification: 'Laptop Backpacks',
      conversionRate: 0.0129032258,
      glanceViews: 155,
      itemName: 'Dell Timbuk2 Authority Backpack',
      netPpm: 0.14,
      orderedUnits: 2,
      price: 100.0,
      revenue: 166.66,
      salesRank: 1145,
    },
    {
      asin: 'B095721RV6',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0454545455,
      glanceViews: 22,
      itemName:
        'Dell SE2422H 24 Inch Full HD (1920x1080) Monitor, 75Hz, VA, 5ms, AMD FreeSync, HDMI, VGA, 3 Year Warranty, Black',
      netPpm: 0.1388,
      orderedUnits: 1,
      price: 0.0,
      revenue: 104.27,
      salesRank: 34,
    },
    {
      asin: 'B071HL4MVB',
      brand: 'Dell',
      classification: 'Keyboards (Computers & Accessories)',
      conversionRate: 0.1487603306,
      glanceViews: 121,
      itemName:
        'Dell KB-522 Wired Business Multimedia USB Keyboard Black 580-17669 *Same as 580-17669*',
      netPpm: 0.1352,
      orderedUnits: 18,
      price: 26.99,
      revenue: 355.24,
      salesRank: 442,
    },
    {
      asin: 'B0BJ2PNDQ7',
      brand: 'Dell',
      classification: 'Desktop PCs',
      conversionRate: 0.0045248869,
      glanceViews: 442,
      itemName:
        'Dell XPS 8950 Desktop PC, Intel Core i7-12700, NVIDIA GeForce RTX 3060 Ti 8GB, 16GB RAM, 512GB SSD + 1TB HDD, DVD Drive, Windows 11 Home (Black)',
      netPpm: 0.1322,
      orderedUnits: 2,
      price: 1599.0,
      revenue: 2566.48,
      salesRank: 240,
    },
    {
      asin: 'B0B6G46WYQ',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: 0.0454545455,
      glanceViews: 22,
      itemName:
        'Dell XPS 13 9315 13.4" FHD+ Laptop, Intel Evo Core i5-1230U, 8GB RAM, 256GB SSD, Backlit Keyboard, Fingerprint Reader, Windows 11 Home',
      netPpm: 0.1315,
      orderedUnits: 1,
      price: 999.0,
      revenue: 777.51,
      salesRank: 450,
    },
    {
      asin: 'B013I6KOEY',
      brand: 'Dell',
      classification: 'Mice',
      conversionRate: 1.1235955056,
      glanceViews: 178,
      itemName:
        'Dell MS116 - Mouse - Optical - 2 Buttons - Wired - USB - Black - Retail - for Inspiron 17R 57XX, 17R 7720, Latitude D630, Optiplex 50XX, 5250, 90XX, XPS One 27XX',
      netPpm: 0.1306,
      orderedUnits: 200,
      price: 11.99,
      revenue: 2135.78,
      salesRank: 91,
    },
    {
      asin: 'B09XBNQQTG',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0887070376,
      glanceViews: 3055,
      itemName:
        'Dell S2721H 27 Inch Full HD (1920x1080) Monitor, 75Hz, IPS, 4ms, AMD FreeSync, Built-in Speakers, Ultrathin Bezel, 2x HDMI, 3 Year Warranty, Silver',
      netPpm: 0.1293,
      orderedUnits: 271,
      price: 169.99,
      revenue: 28231.08,
      salesRank: 77,
    },
    {
      asin: 'B0B6G5BGDR',
      brand: 'Dell',
      classification: '2 in 1 Laptops',
      conversionRate: 0.0057803468,
      glanceViews: 692,
      itemName:
        'Dell Inspiron 7425 14" FHD+ 2-in-1 Laptop, AMD Ryzen 5 5625U, 8GB RAM, 256GB SSD, Touchscreen, Backlit Keyboard, Fingerprint Reader, Windows 11 Home (Pebble Green)',
      netPpm: 0.1227,
      orderedUnits: 4,
      price: 679.0,
      revenue: 2267.46,
      salesRank: 19,
    },
    {
      asin: 'B09ZF3XZS7',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: 0.005988024,
      glanceViews: 3006,
      itemName:
        'Dell XPS 13 Plus 9320 13.4" FHD+ Laptop, Intel Evo Core i7-1260P, 16GB RAM, 512GB SSD, Backlit Keyboard, Fingerprint Reader, Windows 11 Home (Silver)',
      netPpm: 0.1202,
      orderedUnits: 18,
      price: 1599.0,
      revenue: 21201.71,
      salesRank: 175,
    },
    {
      asin: 'B095C2X5MC',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0141242938,
      glanceViews: 708,
      itemName:
        'Dell S2522HG 24.5 inch Full HD (1920x1080) Gaming Monitor, 240Hz, IPS, 1ms, AMD FreeSync Premium, NVIDIA G-SYNC Compatible, DisplayPort, 2x HDMI, 5x USB, 3 Year Warranty',
      netPpm: 0.1168,
      orderedUnits: 10,
      price: 259.99,
      revenue: 1974.03,
      salesRank: 196,
    },
    {
      asin: 'B0B6G1W3LK',
      brand: 'Dell',
      classification: 'Traditional Laptops',
      conversionRate: 0.0027027027,
      glanceViews: 1480,
      itemName:
        'Dell XPS 13 9315 13.4" UHD+ Laptop, Intel Evo Core i7-1250U, 16GB RAM, 512GB SSD, Touchscreen, Backlit Keyboard, Fingerprint Reader, Windows 11 Home',
      netPpm: 0.1102,
      orderedUnits: 4,
      price: 1619.0,
      revenue: 4391.06,
      salesRank: 61,
    },
    {
      asin: 'B0846YK78D',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0772532189,
      glanceViews: 466,
      itemName:
        'Dell E2020H 19.5 Inch HD+ (1600x900) Monitor, 60Hz, TN, 5ms, DisplayPort, VGA, 3 Year Warranty',
      netPpm: 0.1034,
      orderedUnits: 36,
      price: 105.0,
      revenue: 3064.65,
      salesRank: 402,
    },
    {
      asin: 'B086RLM49L',
      brand: 'Dell',
      classification: 'Mice',
      conversionRate: null,
      glanceViews: 0,
      itemName: 'Dell Mobile Wireless Mouse MS3320 Black, MS3320W-BLK (Black)',
      netPpm: 0.1013,
      orderedUnits: 1,
      price: 22.5,
      revenue: 16.35,
      salesRank: 271,
    },
    {
      asin: 'B0B6G61Q58',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: 0.0014836795,
      glanceViews: 1348,
      itemName:
        'Dell XPS 17 9720 17" UHD+ Laptop, Intel Core i9-12900HK, 32GB RAM, 1TB SSD, NVIDIA GeForce RTX 3060 6GB, Touchscreen, Backlit Keyboard, Fingerprint Reader, Windows 11 Home (Silver)',
      netPpm: 0.0985,
      orderedUnits: 2,
      price: 3499.0,
      revenue: 5499.94,
      salesRank: 325,
    },
    {
      asin: 'B018P9HJRE',
      brand: 'Dell',
      classification: 'DVI to HDMI Adapters',
      conversionRate: 0.0555555556,
      glanceViews: 18,
      itemName: 'Dell Adapter - USB-C to HDMI 2.0 470-ABMZ *Same as 470-ABMZ*',
      netPpm: 0.0943,
      orderedUnits: 1,
      price: 38.0,
      revenue: 30.64,
      salesRank: 239,
    },
    {
      asin: 'B01NH5KUCX',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: null,
      glanceViews: 0,
      itemName: 'Dell 091N324 P2418HT 24-Inch Touch LED Monitor - Black',
      netPpm: 0.0942,
      orderedUnits: 0,
      price: 342.0,
      revenue: 0.0,
      salesRank: 573,
    },
    {
      asin: 'B08KWFX2DD',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: 0.0208333333,
      glanceViews: 48,
      itemName:
        'Dell G3 15.6 inch FHD 120Hz 250nits WVA LED Backlit Narrow Border Display Gaming laptop, Intel Core i5-10300H, 8 GB RAM, 512 GB SSD, NVIDIA GTX 1650 4GB GDDR6, Nahimic 3D Audio, Win 10 Home',
      netPpm: 0.0911,
      orderedUnits: 1,
      price: 649.0,
      revenue: 666.66,
      salesRank: 1982,
    },
    {
      asin: 'B08BCNQRN5',
      brand: 'Dell',
      classification: 'Computer Keyboard & Mouse Combos',
      conversionRate: 0.0545454545,
      glanceViews: 220,
      itemName:
        'Dell Multi-Device Wireless Keyboard and Mouse Combo KM7120W - Tastatur-und-Maus-Set - UK QWERTY - T',
      netPpm: 0.087,
      orderedUnits: 12,
      price: 72.3,
      revenue: 609.72,
      salesRank: 154,
    },
    {
      asin: 'B0BFRSZXBZ',
      brand: 'Dell',
      classification: 'Traditional Laptops',
      conversionRate: 0.05,
      glanceViews: 20,
      itemName:
        'Dell Latitude 5530 - Intel Core i7 1265U / 1.8 GHz - vPro Enterprise - Win 10 Pro (includes Win 11 Pro Licence) - Iris X',
      netPpm: 0.0566,
      orderedUnits: 1,
      price: null,
      revenue: 1083.31,
      salesRank: 1216,
    },
    {
      asin: 'B07CZS87ML',
      brand: 'Dell',
      classification: 'Laptop Backpacks',
      conversionRate: 0.05,
      glanceViews: 100,
      itemName: 'Dell Targus Drifter Backpack 17 *Same as 460-BCKM*',
      netPpm: 0.0559,
      orderedUnits: 5,
      price: 61.99,
      revenue: 258.3,
      salesRank: 837,
    },
    {
      asin: 'B09ZF3N58Y',
      brand: 'Dell',
      classification: 'Traditional Laptops',
      conversionRate: 0.0011537352,
      glanceViews: 3467,
      itemName:
        'Dell XPS 13 Plus 9320 13.4" OLED 3.5K Laptop, Intel Evo Core i7-1260P, 16GB RAM, 512GB SSD, Touchscreen, Backlit Keyboard, Fingerprint Reader, Windows 11 Home (Silver)',
      netPpm: 0.0551,
      orderedUnits: 4,
      price: 1699.0,
      revenue: 4549.17,
      salesRank: 734,
    },
    {
      asin: 'B0BJ2RM258',
      brand: 'Alienware',
      classification: 'Traditional Laptops',
      conversionRate: 0.0,
      glanceViews: 1005,
      itemName:
        'Alienware m15 R7 15.6" FHD 165Hz Gaming Laptop, Intel Core i7-12700H, NVIDIA GeForce RTX 3070 Ti 8GB, 16GB RAM, 1TB SSD, G-SYNC, Windows 11 Home (Dark side of the moon), WK20R',
      netPpm: 0.0522,
      orderedUnits: 0,
      price: 2099.0,
      revenue: -201.32,
      salesRank: 859,
    },
    {
      asin: 'B09TXZ916P',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0669642857,
      glanceViews: 224,
      itemName:
        'Dell P2423D 24 Inch QHD (2560x1440) Monitor, 60Hz, IPS, 5ms, 99% sRGB, DisplayPort, HDMI, 5x USB, 3 Year Warranty',
      netPpm: 0.0447,
      orderedUnits: 15,
      price: 274.8,
      revenue: 3047.55,
      salesRank: 1309,
    },
    {
      asin: 'B093RVKLTW',
      brand: 'Dell',
      classification: 'Desktop PCs',
      conversionRate: 0.0046296296,
      glanceViews: 216,
      itemName:
        'Dell XPS 8940 Desktop, Intel Core i5-11400, NVIDIA GeForce GTX 1650 SUPER 4GB, 8GB RAM, 256GB SSD, DVD Drive, Windows 10 Home (Black)',
      netPpm: 0.0393,
      orderedUnits: 1,
      price: 899.99,
      revenue: 710.57,
      salesRank: 1675,
    },
    {
      asin: 'B01CPT7GEQ',
      brand: 'Dell',
      classification: 'Docking Stations',
      conversionRate: 0.1621621622,
      glanceViews: 37,
      itemName: 'Dell MK 15 - Docking Station Mounting Kit (Holder), Black',
      netPpm: 0.024,
      orderedUnits: 6,
      price: 30.6,
      revenue: 129.32,
      salesRank: 274,
    },
    {
      asin: 'B014DT83K2',
      brand: 'Dell',
      classification: 'Keyboards (Computers & Accessories)',
      conversionRate: 0.7371349096,
      glanceViews: 719,
      itemName:
        'Dell 091A682 KB216 USB QWERTY English( UK Layout ) Black Keyboard - Keyboards (Standard, Wired, USB, QWERTY, Black)',
      netPpm: 0.0182,
      orderedUnits: 530,
      price: 19.5,
      revenue: 7987.06,
      salesRank: 106,
    },
    {
      asin: 'B0183T3TNO',
      brand: 'Dell',
      classification: 'Laptop Chargers & Power Supplies',
      conversionRate: 0.0,
      glanceViews: 2,
      itemName: 'Dell Power Supply : UK/Irish 65W AC Adapter with Power Cord',
      netPpm: 0.0099,
      orderedUnits: 0,
      price: 49.0,
      revenue: 0.0,
      salesRank: 15144,
    },
    {
      asin: 'B0B883MXPL',
      brand: 'Dell',
      classification: 'CPUs',
      conversionRate: 0.0769230769,
      glanceViews: 13,
      itemName:
        'Dell OptiPlex 7090 i7-10700 SFF Intel® Core™ i7 16 GB DDR4-SDRAM 512 GB SSD Windows 10 Pro PC Black',
      netPpm: 0.0002,
      orderedUnits: 1,
      price: null,
      revenue: 883.61,
      salesRank: 1298,
    },
    {
      asin: 'B0B883MXPL',
      brand: 'Dell',
      classification: 'CPUs',
      conversionRate: 0.0769230769,
      glanceViews: 13,
      itemName:
        'Dell OptiPlex 7090 i7-10700 SFF Intel® Core™ i7 16 GB DDR4-SDRAM 512 GB SSD Windows 10 Pro PC Black',
      netPpm: 0.0002,
      orderedUnits: 1,
      price: null,
      revenue: 883.61,
      salesRank: 1298,
    },
    {
      asin: 'B09TXZQGBM',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0192307692,
      glanceViews: 52,
      itemName:
        'Dell P3223DE 32 Inch QHD (2560 x 1440) USB-C Hub Monitor, 60Hz, 5ms, IPS, HDMI, DisplayPort x2, USB-C, USB-A x4, 3 Year Warranty, Black',
      netPpm: -0.0036,
      orderedUnits: 1,
      price: 364.0,
      revenue: 303.33,
      salesRank: 2136,
    },
    {
      asin: 'B07H5N9FYW',
      brand: 'Dell',
      classification: 'Graphic Tablet Pens',
      conversionRate: 0.0588235294,
      glanceViews: 17,
      itemName:
        'Dell Premium Active Pen - PN579X Stylus Black 19.5g DELL-PN579X',
      netPpm: -0.0449,
      orderedUnits: 1,
      price: 74.5,
      revenue: 59.64,
      salesRank: 44,
    },
    {
      asin: 'B010OC6VTW',
      brand: 'Dell',
      classification: 'External CD & DVD Drives',
      conversionRate: 0.1304347826,
      glanceViews: 115,
      itemName:
        'Dell Slim DW316 - Disk drive - DVD±RW (±R DL) / DVD-RAM - 8x/8x/5x - USB 2.0 - External - for Latitude 5310, Optiplex 5480, Precision Mobile Workstation 35XX, 5750, 77XX, Vostro 35XX',
      netPpm: -0.0454,
      orderedUnits: 15,
      price: 51.0,
      revenue: 485.14,
      salesRank: 71,
    },
    {
      asin: 'B09NJKF2RC',
      brand: 'Dell',
      classification: 'Computer Keyboard & Mouse Combos',
      conversionRate: 0.2096774194,
      glanceViews: 868,
      itemName:
        'Dell KM5221W Pro Wireless Keyboard and Mouse, UK (QWERTY), 2.4GHz, 128-bit AES Encryption, 4000 dpi, Windows, Apple, Android, Linux and Chrome, (Black)',
      netPpm: -0.0922,
      orderedUnits: 182,
      price: 42.3,
      revenue: 5744.58,
      salesRank: 48,
    },
    {
      asin: 'B0999KFRD2',
      brand: 'Dell',
      classification: 'Traditional Laptops',
      conversionRate: 0.0005268704,
      glanceViews: 1898,
      itemName:
        'Dell XPS 17 9710 17" UHD+ Laptop, Intel Core i7-11800H, 16GB RAM, 1TB SSD, NVIDIA GeForce RTX 3060 6GB, Touchscreen, Backlit Keyboard, Fingerprint Reader, Windows 10 Home, (Platinum Silver)',
      netPpm: -0.1022,
      orderedUnits: 1,
      price: 2699.0,
      revenue: 1833.44,
      salesRank: 1469,
    },
    {
      asin: 'B07KDS4MPF',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0101010101,
      glanceViews: 99,
      itemName:
        'Dell UltraSharp 49 Curved USB-C Monitor U4919DW 124.5cm 49" Black',
      netPpm: -0.1126,
      orderedUnits: 1,
      price: 1146.0,
      revenue: 749.97,
      salesRank: 666,
    },
    {
      asin: 'B0842XB4BN',
      brand: 'Dell',
      classification: 'Laptop Backpacks',
      conversionRate: 0.0322580645,
      glanceViews: 93,
      itemName:
        'DELL Gaming Backpack 17 - Laptop Backpack - 43.2 cm (17 Inches)',
      netPpm: -0.2401,
      orderedUnits: 3,
      price: 32.0,
      revenue: 80.01,
      salesRank: 584,
    },
    {
      asin: 'B084BTQBW4',
      brand: 'Dell',
      classification: 'Mice',
      conversionRate: 0.0357142857,
      glanceViews: 28,
      itemName: 'Dell MS5320W Multi-Device Wireless Mouse',
      netPpm: -0.3008,
      orderedUnits: 1,
      price: 33.6,
      revenue: 28.13,
      salesRank: 176,
    },
    {
      asin: 'B07ZQMCTL7',
      brand: 'Dell',
      classification: 'Graphic Tablet Pens',
      conversionRate: null,
      glanceViews: 0,
      itemName: 'Dell part active pen PN350M, DELL-PN350M-BK',
      netPpm: null,
      orderedUnits: 0,
      price: 22.5,
      revenue: 0.0,
      salesRank: 129,
    },
    {
      asin: 'B0B64GZ732',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell Inspiron 5420 14" FHD+ Laptop, Intel Core i7-1255U, 8GB RAM, 512GB SSD, Backlit Keyboard, Fingerprint Reader, Windows 11 Home (Platinum Silver)',
      netPpm: null,
      orderedUnits: 0,
      price: 799.0,
      revenue: 0.0,
      salesRank: 598,
    },
    {
      asin: 'B08BWNJVLG',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: 0.0,
      glanceViews: 10,
      itemName:
        'DELL - WRKST LPB PRECI 5550 I7-10850H 16GB 512GB SSD 15.6IN W10PRO',
      netPpm: null,
      orderedUnits: 0,
      price: null,
      revenue: 0.0,
      salesRank: 6537,
    },
    {
      asin: 'B09VGZYHMV',
      brand: 'Alienware',
      classification: 'Desktop PCs',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Alienware Aurora R13 Gaming Desktop PC, Intel Core i7-12700KF, NVIDIA GeForce RTX 3070 8GB, 16GB RAM, 512GB SSD + 1TB HDD, Liquid Cooling, Windows 11 Home (Lunar Light)',
      netPpm: null,
      orderedUnits: 0,
      price: 2249.0,
      revenue: 0.0,
      salesRank: 1707,
    },
    {
      asin: 'B07PNNBF6Z',
      brand: 'Dell',
      classification: 'Laptop Messenger & Shoulder Bags',
      conversionRate: 0.25,
      glanceViews: 4,
      itemName:
        'Dell Pro Slim BriefCase 15 - PO1520CS - Fits Most Laptops up to 15',
      netPpm: null,
      orderedUnits: 1,
      price: 42.3,
      revenue: 43.9,
      salesRank: 336,
    },
    {
      asin: 'B082G7DZ3N',
      brand: 'Alienware',
      classification: 'Mice',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Alienware 610M Wired/Wireless Gaming Mouse - AW610M (Lunar Light)',
      netPpm: null,
      orderedUnits: 0,
      price: 79.99,
      revenue: 0.0,
      salesRank: 1100,
    },
    {
      asin: 'B07QD9J7S5',
      brand: 'Dell',
      classification: 'Laptop Backpacks',
      conversionRate: 0.0074074074,
      glanceViews: 135,
      itemName:
        'Dell Premier Slim Backpack 15 PE1520PS Fits Most Laptops up to 15 inch',
      netPpm: null,
      orderedUnits: 1,
      price: 63.0,
      revenue: 52.5,
      salesRank: 988,
    },
    {
      asin: 'B08FRHFXWW',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell S3221QS 31.5 Inch 4K UHD (3840x2160) 1800R Curved Monitor, 60Hz, VA, 4ms, AMD FreeSync, 99% sRGB, Built-in Speakers, DisplayPort, 2x HDMI, 3x USB 3.0, 3 Year Warranty',
      netPpm: null,
      orderedUnits: 0,
      price: 389.99,
      revenue: 0.0,
      salesRank: 683,
    },
    {
      asin: 'B0B6FYRQ1V',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: 0.0,
      glanceViews: 735,
      itemName:
        'Dell XPS 15 9520 15.6" UHD+ Laptop, Intel Core i7-12700H, 16GB RAM, 1TB SSD, NVIDIA GeForce RTX 3050Ti 4GB, Touchscreen, Backlit Keyboard, Fingerprint Reader, Windows 11 Home (Silver)',
      netPpm: null,
      orderedUnits: 0,
      price: 2249.0,
      revenue: 0.0,
      salesRank: 2756,
    },
    {
      asin: 'B0B6HZ6Y3B',
      brand: 'Dell',
      classification: 'CPUs',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell Inspiron 3910 Desktop PC, Intel Core i5-12400, 8GB RAM, 512GB SSD, DVD Drive, Windows 11 Home (Black)',
      netPpm: null,
      orderedUnits: 0,
      price: 549.0,
      revenue: 0.0,
      salesRank: 93,
    },
    {
      asin: 'B0BJ2TPLXV',
      brand: 'Dell',
      classification: 'Desktop PCs',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell Inspiron 7710 27" FHD All-In-One Desktop, Intel Core i7-1255U, 16GB RAM, 512GB SSD + 1TB HDD, Windows 11 Home (Pearl White)',
      netPpm: null,
      orderedUnits: 0,
      price: 1149.0,
      revenue: 0.0,
      salesRank: 383,
    },
    {
      asin: 'B0B64G7BNL',
      brand: 'Dell',
      classification: 'Traditional Laptops',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell Inspiron 5620 16" FHD+ Laptop, Intel Core i5-1235U, 8GB RAM, 512GB SSD, Backlit Keyboard, Fingerprint Reader, Windows 11 Home (Platinum Silver)',
      netPpm: null,
      orderedUnits: 0,
      price: 719.0,
      revenue: 0.0,
      salesRank: 88,
    },
    {
      asin: 'B07XTVYPYF',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell SE2417HGX 23.6 Inch Full HD (1920 x 1080) Gaming Monitor, 75 Hz, TN, 1 ms, AMD Radeon FreeSync, Thin Bezel, 2x HDMI, VGA, 3 Years Warranty, Black',
      netPpm: null,
      orderedUnits: 0,
      price: 99.99,
      revenue: 0.0,
      salesRank: 745,
    },
    {
      asin: 'B09HVB6NBZ',
      brand: 'Dell',
      classification: 'Traditional Laptops',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell Inspiron 3515 15.6" FHD Laptop, AMD Ryzen 5 3500U, 8GB RAM, 256GB SSD, Windows 11 Home (Carbon Black)',
      netPpm: null,
      orderedUnits: 0,
      price: 519.0,
      revenue: 0.0,
      salesRank: 254,
    },
    {
      asin: 'B08NFBKNMY',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell S2721DGFA 27 Inch QHD (2560x1440) Gaming Monitor, 165Hz, IPS, 1ms, AMD FreeSync Premium Pro, NVIDIA G-SYNC Compatible, HDR 400, DisplayPort, 2x HDMI, 5x USB 3.0, 3 Year Warranty',
      netPpm: null,
      orderedUnits: 0,
      price: 379.99,
      revenue: 0.0,
      salesRank: 164,
    },
    {
      asin: 'B09HVC869M',
      brand: 'Dell',
      classification: '2 in 1 Laptops',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell Inspiron 7415 14" FHD 2-in-1 Laptop, AMD Ryzen 7 5700U, 16GB RAM, 512GB SSD, Touchscreen, Windows 11 Home (Mist Blue)',
      netPpm: null,
      orderedUnits: 0,
      price: 899.0,
      revenue: 0.0,
      salesRank: 107,
    },
    {
      asin: 'B06Y19QRBX',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0,
      glanceViews: 47,
      itemName:
        'Dell 22 Monitor E2216HV 54.6cm 21.5" Black UK SAME AS 210-ALFY *Same as 210-ALFY*',
      netPpm: null,
      orderedUnits: 0,
      price: 90.0,
      revenue: 0.0,
      salesRank: 1831,
    },
    {
      asin: 'B07D6WN5M9',
      brand: 'Supa',
      classification: 'Small Animal Food',
      conversionRate: 0.0,
      glanceViews: 1,
      itemName:
        'Supa Small Animal Banana Chips, 500 ml, Pack of 2 | Made From Human Grade Food Material | Excellent Treat For Your Pet',
      netPpm: null,
      orderedUnits: 0,
      price: 13.78,
      revenue: 0.0,
      salesRank: 1451,
    },
    {
      asin: 'B07HS74H5P',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell SE2219H 21.5 Inch Full HD (1920 x 1080) Monitor, 60 Hz, IPS, 5 ms, Thin Bezel, HDMI, VGA, 3 Years Warranty, Black',
      netPpm: null,
      orderedUnits: 0,
      price: 99.99,
      revenue: 0.0,
      salesRank: 600,
    },
    {
      asin: 'B015P6O1US',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell SE2416H 24 Inch Full HD (1920 x 1080) Monitor, 60 Hz, IPS, 6 ms, Thin Bezel, HDMI, VGA, 3 Years Warranty, Black',
      netPpm: null,
      orderedUnits: 0,
      price: 99.99,
      revenue: 0.0,
      salesRank: 459,
    },
    {
      asin: 'B095P71TK1',
      brand: 'Dell',
      classification: 'Computer Keyboard & Mouse Combos',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell KM7321W Premier Multi-Device Wireless Keyboard and Mouse, UK (QWERTY), 2.4GHz, Bluetooth 5.0, 128-bit AES Encryption, 4000 dpi, Compatible with Windows, Mac, Linux, Chrome and Android, (Grey)',
      netPpm: null,
      orderedUnits: 0,
      price: 82.5,
      revenue: 0.0,
      salesRank: 52,
    },
    {
      asin: 'B09HVDQBH2',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell Inspiron 3511 15.6" FHD Laptop, Intel Core i3-1005G1, 4GB RAM, 128GB SSD, Windows 11 Home S Mode (Platinum Silver)',
      netPpm: null,
      orderedUnits: 0,
      price: 469.0,
      revenue: 0.0,
      salesRank: 1336,
    },
    {
      asin: 'B09HVDTYDN',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell Inspiron 3511 15.6" FHD Laptop, Intel Core i5-1135G7, 8GB RAM, 256GB SSD, Windows 11 Home (Carbon Black)',
      netPpm: null,
      orderedUnits: 0,
      price: 599.0,
      revenue: 0.0,
      salesRank: 397,
    },
    {
      asin: 'B08FRJ9RJ9',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell S2721QS 27 Inch 4K UHD (3840x2160) Monitor, 60Hz, IPS, 4ms, AMD Radeon FreeSync, Built-in Speakers, 99% sRGB, DisplayPort, 2x HDMI, 3 Year Warranty, Silver, Platinum Silver',
      netPpm: null,
      orderedUnits: 0,
      price: 329.99,
      revenue: 0.0,
      salesRank: 214,
    },
    {
      asin: 'B095C2QLK2',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell S3422DWG 34 inch WQHD (3440x1440) 21:9 1800R Curved Gaming Monitor, 144Hz, VA, 1ms MPRT, AMD FreeSync Premium Pro, HDR 400, DisplayPort, 2x HDMI, 5x USB, 3 Year Warranty',
      netPpm: null,
      orderedUnits: 0,
      price: 459.99,
      revenue: 0.0,
      salesRank: 412,
    },
    {
      asin: 'B06XP7VJJP',
      brand: 'Dell',
      classification: 'Laptop Briefcases',
      conversionRate: 1.0,
      glanceViews: 1,
      itemName:
        'Dell Premier Slim BriefCase 14 - Notebook Carrying Case - 15" - matte Black - for Latitude 7200 2-in-1, 7290, 7390 2-in-1, 73XX, 7400 2-in-1, 7490, XPS 13 93XX, 15 95XX',
      netPpm: null,
      orderedUnits: 1,
      price: 85.99,
      revenue: 73.28,
      salesRank: 210,
    },
    {
      asin: 'B07QHHHV5Y',
      brand: 'Dell',
      classification: 'Laptop Backpacks',
      conversionRate: 0.0,
      glanceViews: 10,
      itemName:
        'Dell Premier Backpack 15 PE1520P Fits Most Laptops up to 15 inch',
      netPpm: null,
      orderedUnits: 0,
      price: 87.0,
      revenue: -9.74,
      salesRank: 1060,
    },
    {
      asin: 'B0B6G1PH9C',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell XPS 15 9520 15.6" OLED 3.5K Laptop, Intel Core i9-12900HK, 32GB RAM, 1TB SSD, NVIDIA GeForce RTX 3050Ti 4GB, Touchscreen, Backlit Keyboard, Fingerprint Reader, Windows 11 Home (Silver)',
      netPpm: null,
      orderedUnits: 0,
      price: 2949.0,
      revenue: 0.0,
      salesRank: 330,
    },
    {
      asin: 'B095C3341T',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0526315789,
      glanceViews: 19,
      itemName:
        'Dell S2722DGM 27 Inch QHD (2560x1440) 1500R Curved Gaming Monitor, 165Hz, VA, 1ms MPRT, AMD FreeSync Premium, 99% sRGB, DisplayPort, 2x HDMI, 3 Year Warranty',
      netPpm: null,
      orderedUnits: 1,
      price: 269.99,
      revenue: 223.91,
      salesRank: 478,
    },
    {
      asin: 'B09TWB8X5G',
      brand: 'Alienware',
      classification: 'Laptops',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Alienware m15 R7 15.6" QHD 240Hz Gaming Laptop, Intel Core i7-12700H, NVIDIA GeForce RTX 3070 Ti 8GB, 16GB RAM, 1TB SSD, NVIDIA G-SYNC, Windows 11 Home (Dark side of the moon)',
      netPpm: null,
      orderedUnits: 0,
      price: 2249.0,
      revenue: 0.0,
      salesRank: 657,
    },
    {
      asin: 'B07QBC1TG8',
      brand: 'Dell',
      classification: 'Laptop Briefcases',
      conversionRate: 0.0,
      glanceViews: 16,
      itemName:
        'Dell Premier BriefCase 15 PE1520C Fits Most Laptops up to 15 inch',
      netPpm: null,
      orderedUnits: 0,
      price: 75.0,
      revenue: 0.0,
      salesRank: 438,
    },
    {
      asin: 'B001W3MO2G',
      brand: 'Dell',
      classification: 'Inkjet Printer Ink Cartridges',
      conversionRate: null,
      glanceViews: 0,
      itemName: 'Dell V105 , V305 Photo Ink Cartridge',
      netPpm: null,
      orderedUnits: 1,
      price: 25.77,
      revenue: 21.01,
      salesRank: 15712,
    },
    {
      asin: 'B0727ZQ21F',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell UltraSharp 32 PremierColor UltraHD 8K Monitor UP3218K 80.1cm 31.5" Black UK *Same as 210-AMFJ*',
      netPpm: null,
      orderedUnits: 0,
      price: 3615.0,
      revenue: 0.0,
      salesRank: 2512,
    },
    {
      asin: 'B07D74SBT9',
      brand: 'Supa',
      classification: 'Bird Feeders (Garden)',
      conversionRate: 0.0,
      glanceViews: 3,
      itemName:
        'Supa Wild Bird Food Dried Insectivore Food, 3 Litre Bucket, Highly Nutritious Treat For All Insect Loving Wild Birds.',
      netPpm: null,
      orderedUnits: 0,
      price: 39.99,
      revenue: 0.0,
      salesRank: 5694,
    },
    {
      asin: 'B0B649XH7K',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell Inspiron 7620 16 Plus 16" 3K Laptop, Intel Core i7-12700H, 16GB RAM, 1TB SSD, NVIDIA GeForce RTX 3060 6GB, Backlit Keyboard, Fingerprint Reader, Windows 11 Home (Dark Green)',
      netPpm: null,
      orderedUnits: 0,
      price: 1369.0,
      revenue: 0.0,
      salesRank: 1551,
    },
    {
      asin: 'B008QN9HRA',
      brand: 'Dell',
      classification: 'Toner Cartridges',
      conversionRate: null,
      glanceViews: 0,
      itemName: 'DELL 7XDTM 593-BBEL Toner Cartridge Service Set',
      netPpm: null,
      orderedUnits: -1,
      price: 173.01,
      revenue: -178.51,
      salesRank: 18337,
    },
    {
      asin: 'B09NL85DGZ',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell Inspiron 3502 15.6" FHD Laptop, Intel Pentium, 4GB RAM, 128GB SSD, Windows 11 Home S Mode, Includes 1 Year Microsoft 365 Subscription',
      netPpm: null,
      orderedUnits: 0,
      price: 349.0,
      revenue: 0.0,
      salesRank: 818,
    },
    {
      asin: 'B09HCZ7SCC',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell XPS 13 9310 13.4 inch FHD+ Laptop, Intel Evo Core i7-1185G7, 16GB RAM, 512GB SSD, Backlit Keyboard, Fingerprint Reader, Windows 11 Home, (Platinum Silver)',
      netPpm: null,
      orderedUnits: 0,
      price: 1299.0,
      revenue: 0.0,
      salesRank: 1733,
    },
    {
      asin: 'B08NFCG6TX',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: 0.0,
      glanceViews: 75,
      itemName:
        'Alienware AW2521HFA 24.5 Inch Full HD (1920x1080) Gaming Monitor, 240Hz, IPS, 1ms, AMD FreeSync Premium, NVIDIA G-SYNC Compatible, DisplayPort, 2x HDMI, 5x USB 3.0, 3 Year Warranty',
      netPpm: null,
      orderedUnits: 0,
      price: 319.99,
      revenue: 0.0,
      salesRank: 836,
    },
    {
      asin: 'B09HDK7Q27',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell XPS 15 9510 15.6" FHD+ Laptop, Intel Core i7-11800H, 16GB RAM, 512GB SSD, NVIDIA GeForce RTX 3050Ti 4GB, Backlit Keyboard, Fingerprint Reader, Windows 11 Home, (Silver)',
      netPpm: null,
      orderedUnits: 0,
      price: 1849.0,
      revenue: 0.0,
      salesRank: 282,
    },
    {
      asin: 'B09HCXQGNF',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell Inspiron 3000 15.6" FHD Laptop, AMD Ryzen 5 3500U, 8GB RAM, 256GB SSD, Windows 11 Home (Black)',
      netPpm: null,
      orderedUnits: 0,
      price: 499.0,
      revenue: 0.0,
      salesRank: 893,
    },
    {
      asin: 'B08NFBBTTL',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell Alienware AW2721D 27 Inch QHD (2560x1440) Gaming Monitor, 240Hz, Fast IPS, 1ms, NVIDIA G-SYNC Ultimate, 98% DCI-P3, HDR 600, DisplayPort, 2x HDMI, 5x USB, 3 Year Warranty, Lunar Light',
      netPpm: null,
      orderedUnits: 0,
      price: 669.99,
      revenue: 0.0,
      salesRank: 656,
    },
    {
      asin: 'B07H3FW5YQ',
      brand: 'Dell',
      classification: 'Security Locks',
      conversionRate: 4.25,
      glanceViews: 4,
      itemName:
        'Dell N17 Dual Headed Keyed Laptop Lock for Dell Devices *Same as 461-AAFE*',
      netPpm: null,
      orderedUnits: 17,
      price: 53.99,
      revenue: 592.62,
      salesRank: 259,
    },
    {
      asin: 'B07F8XZN69',
      brand: 'Dell',
      classification: 'Climate Pledge Friendly: Shop All',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell P2419H 24 Inch Full HD (1920 x 1080) Monitor, 60 Hz, IPS, 5ms, Ultrathin Bezel, DisplayPort, HDMI, VGA, 5 x USB, Adjustable Stand (Height, Pivot, Swivel, Tilt), 3-Year Warranty, black',
      netPpm: null,
      orderedUnits: 0,
      price: 189.6,
      revenue: 0.0,
      salesRank: 6626,
    },
    {
      asin: 'B0999HKJHS',
      brand: 'Dell',
      classification: 'Laptops',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell Inspiron 7415 14 Inch FHD (1920 x 1080) 2-in-1 Laptop, AMD Ryzen 7-5700U, Touch Narrow Border Display, 16 GB RAM, 512 GB SSD, Win 10 Home (Mist Blue)',
      netPpm: null,
      orderedUnits: 0,
      price: null,
      revenue: 0.0,
      salesRank: 3727,
    },
    {
      asin: 'B01G4WUR1E',
      brand: 'Dell',
      classification: 'Monitors',
      conversionRate: null,
      glanceViews: 0,
      itemName:
        'Dell 22 Monitor P2217 - 55.9cm (22") Black, UK HDMI DisplayPort VGA 3 Year Advanced Exchange Warranty *Same as 210-AJCS*',
      netPpm: null,
      orderedUnits: 0,
      price: 140.0,
      revenue: 0.0,
      salesRank: 1633,
    },
  ],
  message: 'ok',
};
