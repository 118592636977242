import { useState } from 'react';
import { WppButton } from '@wppopen/components-library-react';
import {
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  Autocomplete } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DownwardPointer from '../../../assets/DownwardPointer';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ConfirmDialog = (props) => {

  const { title, children, open, setOpen, onConfirm } = props;
  
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
      sx={{'& .MuiPaper-root': {borderRadius: '12px'}}}
    >
    <DialogTitle id="confirm-dialog">{title}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <WppButton
        variant="contained"
        onClick={() => setOpen(false)}
      >
        No
      </WppButton>
      <WppButton
        variant="contained"
        onClick={() => {
          setOpen(false);
          onConfirm();
        }}
      >
        Yes
      </WppButton>
    </DialogActions>
    </Dialog>
  );
};

const DeleteRetailer = (props) => {

  const { 
    baseApi,
    accessToken,
    retailers,
    setSnackMessage,
    setSnackOpen,
    setRetailersDeleted,
    setNewRetailers,
    handleDialogClose } = props;

  const [retailerValues, setRetailerValues] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const removeRetailer = () => {
    const retailersToRemove = retailerValues.map(retailer => retailer.retailerId);
    fetch(`${baseApi}/partner/delete`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}`},
      body: JSON.stringify({"partners": retailersToRemove, "access_token": accessToken}),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }
      return response.json()
    })
    .then(data => {
      const retailers = retailerValues.map(a => a.retailer);
      setSnackMessage(`Retailer(s) ${[retailers.slice(0, -1).join(', '), retailers.slice(-1)[0]].join(retailers.length < 2 ? '' : ' and ')} deleted successfully`)
      setSnackOpen(true);
      setRetailerValues([]);
      setRetailersDeleted(true);
      setNewRetailers([]);
    })
    .catch((error) => {
      setSnackMessage(error.message);
      setSnackOpen(true);
    });
  };
  
  const removeRetailerDisabled = !retailerValues.length;

  return (
    <>
      <h3 slot="header">Remove Retailer(s)</h3>
      <div slot="body" className="w-auto">
        <div className="text-sm font-normal">Please select retailers from the list.</div>
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={retailers}
          value={retailerValues}
          onChange={(event, newValue) => {
            setRetailerValues(newValue);
          }}
          getOptionLabel={(option) => option.retailer}
          sx={{'& .MuiOutlinedInput-root': {padding: '0 0 0 2px'}}}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.retailer}
            </li>
          )}
          style={{ width: '100%', margin: '20px 0 32px 0' }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  height: "45px",
                  padding: "0",
                },
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => value.retailerId === "" || option.retailerId === value.retailerId}
          popupIcon={<DownwardPointer />}
        />
      </div>
      <div slot="actions" className="flex justify-end">
        <WppButton variant="secondary" size="s" onClick={() => {
          setSnackOpen(false);
          handleDialogClose();
        }}>Cancel</WppButton>
        <WppButton 
          disabled={removeRetailerDisabled}
          variant="primary"
          size="s"
          onClick={() => setConfirmOpen(true)}
          className="ml-4"
        >
          Remove Retailer(s)
        </WppButton>
        <ConfirmDialog
          title="Delete?"
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={removeRetailer}
        >
          Are you sure you want to delete these retailers?
        </ConfirmDialog>
      </div>
    </>
  )
}
export default DeleteRetailer;