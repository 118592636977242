import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { INPUT_TYPE } from '../../Utils/constants';

const RenderExpandableCell = (props) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const { row, value } = props;

  const isMultiOther = _.includes(row, INPUT_TYPE.Multi_Select_Other_Option);
  const multiOtherValue =  `${value?.value} - ${value?.Other}`;

  const textElementRef = useRef(null);

  const checkOverflow = () => {
    // Using getBoundingClientRect, instead of scrollWidth and clientWidth, to get width with fractional accuracy
    const clientWidth = textElementRef.current.getBoundingClientRect().width;

    textElementRef.current.style.overflow = 'visible';
    const contentWidth = textElementRef.current.getBoundingClientRect().width;
    textElementRef.current.style.overflow = 'hidden';

    setIsOverflow(contentWidth > clientWidth);
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  return (
    <Tooltip title={<Typography color={'#FEFEFE'} fontSize={14}>{isMultiOther ? multiOtherValue : value}</Typography>} disableHoverListener={!isOverflowed}>
      <span
        ref={textElementRef}
        style={{
          fontSize: '16px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {isMultiOther ? multiOtherValue : value}
      </span>
    </Tooltip>
  );
};

export default RenderExpandableCell;