import _ from 'lodash';
import { useEffect, useState, useRef } from 'react';
import { useFormContext, Controller } from "react-hook-form"
import { Checkbox, Autocomplete, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ExclamationIcon from '../../assets/ExclamationIcon'
import { INITIAL_SEGMENT } from '../Utils/constants';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const deleteObjectItemByValue = (Obj, val) => {
  for (const key in Obj) {
    if (Obj[key] === val) {
      delete Obj[key];
      return Obj;
    }
  }
};

const Retailers = (props) => {

  const { currentTab, segmentIndex, retailers, fields } = props

  const [selectedRetailers, setSelectedRetailers] = useState([]);
  const assignedRetailers = useRef({});

  const { control, formState, getValues } = useFormContext()

  useEffect(() => {
   _.forEach(fields, a => {
    if (a.segment !== INITIAL_SEGMENT.GENERAL) {
      _.forEach(a.retailerIds, retailer => {
        assignedRetailers.current[retailer.partner] = a.segment; 
      })
    }
   })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setSelectedRetailers(fields[segmentIndex]?.retailerIds);
  })

  return (
  <>
    <Controller
      name={`SegmentChannels[${segmentIndex}].retailerIds`}
      control={control}
      render={({ field: { onChange, onBlur, value, error } }) => (
        <Autocomplete
          multiple
          id="tags-outlined"
          disableCloseOnSelect
          onChange={(e, newValue) => {
            fields[segmentIndex].retailerIds = newValue;
            getValues().SegmentChannels[segmentIndex].retailerIds = newValue;

            setSelectedRetailers(fields[segmentIndex]?.retailerIds);
            
            deleteObjectItemByValue(assignedRetailers.current, currentTab);
            _.forEach(newValue, item => {
              assignedRetailers.current[item.partner] = currentTab;
            })

            onChange(newValue);
          }}
          onBlur={onBlur}
          options={retailers}
          value={selectedRetailers}
          getOptionLabel={(option) => option.partnerName}
          getOptionDisabled={(partner) => assignedRetailers.current[partner.partner] && assignedRetailers.current[partner.partner] !== currentTab}
          isOptionEqualToValue={(option, value) => option.partner === value.partner}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                id={option.partner}
                value={option.partner}
              />
              {option.partnerName}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  height: "45px",
                },
                "& .MuiChip-filled": {
                  margin: "-4px 12px 8px 4px",
                },
              }}
            />
          )}
        />
      )}
    />
    <div className="flex items-center" style={{ fontSize: '14px', color: '#db0025' }}>
      {formState.errors.SegmentChannels?.[segmentIndex]?.retailerIds?.message ? <span className="mr-2"><ExclamationIcon /></span> : null}
      {formState.errors.SegmentChannels?.[segmentIndex]?.retailerIds?.message}
    </div>
  </>
  )
}
export default Retailers;