import DropMultiSelect from "../../../components/DropMultiSelect";

const Filters = (props) => {
  const { 
    regions,
    markets,
    channels,
    types,
    setSuperSearchValues,
    setLiveChannels,
    setLiveTypes,
    setMarkets,
    setMarketIds,
    SuperSearch,
    filtersData 
  } = props;

  const outputSelections = (selections, type) => {
    if (type === 'regions') {
      filterRegions(selections)
      setSuperSearchValues([])
    } else if (type === 'markets') {
      filterMarkets(selections)
      setSuperSearchValues([])
    } else if (type === 'channels') {
      setLiveChannels(selections)
      setSuperSearchValues([])
    } else if (type === 'types') {
      setLiveTypes(selections)
      setSuperSearchValues([])
    }
  }
  
  const filterRegions = (selections) => {
    if (selections[0] === -1) {
      setMarkets([]);
      generateMarketIds([-1]);
      return;
    }
  
    let markets = []
    if (selections.length === 0) {
      // ALL REGIONS
      filtersData.markets.forEach((market) => {
        markets.push(market.name)
      })
      setMarkets(markets.sort())
      generateMarketIds(markets.sort())
    } else {
      // SELECT REGIONS
      let regionIds = []
      filtersData.regions.forEach((region) => {
        if (selections.includes(region.name)) {
          regionIds.push(region.id)
        }
      })
      filtersData.markets.forEach((market) => {
        if (regionIds.includes(market.regionId)) {
          markets.push(market.name)
        }
      })
      setMarkets(markets.sort())
      generateMarketIds(markets.sort())
      return;
    }
  }
  
  const filterMarkets = (selections) => {
    if (selections.length > 0) {
      generateMarketIds(selections)
      return;
    }
    setMarketIds([]);
  }
  
  const generateMarketIds = (selections) => {
    if (selections[0] === -1) {
      setMarketIds([-1])
      return
    }
  
    let marketIds = []
    filtersData.markets.forEach((market) => {
      if (selections.includes(market.name)) {
        marketIds.push(market.id)
      }
    })
    setMarketIds(marketIds)
  }


  return (
  <div className={'col-1-1 margin-t-16 top-curve'}>
    <div className={'padding-24'}>
      <DropMultiSelect
        title={'Regions'}
        items={regions}
        width={240}
        outputSelections={outputSelections}
        filterColumns={true}
        type={'regions'}
        superSearchHandleChange={SuperSearch.handleChange}
      />
    </div>
    <div className={'padding-24'}>
      <DropMultiSelect
        title={'Markets'}
        items={markets}
        width={240}
        outputSelections={outputSelections}
        filterColumns={true}
        type={'markets'}
      />
    </div>
    <div className={'padding-24'}>
      <DropMultiSelect
        title={'Channels'}
        items={channels}
        width={240}
        outputSelections={outputSelections}
        filterColumns={true}
        type={'channels'}
      />
    </div>
    <div className={'padding-24'}>
      <DropMultiSelect
        title={'Type'}
        items={types}
        width={240}
        outputSelections={outputSelections}
        filterColumns={true}
        type={'types'}
      />
    </div>
  </div>
  )
}

export default Filters;