import { Snackbar, IconButton }  from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const SnackbarComponent = (props) => {

  const { snackOpen, setSnackOpen, message } = props;

  const handleSnackClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  
  setSnackOpen(false);
  };

  const snackAction = (
  <>
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleSnackClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  </>
  );

  return (
    <Snackbar
      open={snackOpen}
      onClose={handleSnackClose}
      message={message}
      action={snackAction}
      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
    />
  )
}

export default SnackbarComponent;