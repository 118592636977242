import React, { useRef } from 'react';

import defaultErrorImage from '../../../images/logo-placeholder.png';

const Image = ({ src, alt, style }) => {
  const imageEl = useRef(null);
  return (
  <img
    src={src}
    alt={alt}
    style={style}
    onError={() => {
    imageEl.current.src = defaultErrorImage;
    imageEl.current.style.maxWidth='72px';
    }}
    ref={imageEl}
  />
  );
};

export default Image;