import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import useAuthUser from './AuthUser';
import badgeFusion from '../images/badge-fusion.png';
import { WppButton } from '@wppopen/components-library-react';

const Header = (props) => {
  const { title } = props;
  const userInfo = useAuthUser();
  const { oktaAuth, authState } = useOktaAuth();
  const loginWithRedirect = () => oktaAuth.signInWithRedirect({ originalUri: '/' });
  const logOut = () => oktaAuth.signOut();
  const buttonText = authState?.isAuthenticated ? 'Logout' : 'Login';
  const btnLogic = authState?.isAuthenticated ? logOut : loginWithRedirect;

  return (
    <div className="header h-[64px] border-b-[1px]">
      <div className="flex items-center w-1/3">
        <div className="flex items-center justify-center w-[80px]">
          <a href={'/home'} aria-label={'Home'}>
            <img src={badgeFusion} alt="logo Fusion" className="w-[32px]"/>
          </a>
        </div>
      </div>
      <div className="flex items-center justify-center w-1/3">
        <div className="text-2xl text-gray-500 font-thin tracking-widest">{title}</div>
      </div>
      <div className="flex items-center justify-end w-1/3 pr-6">
        <span className="text-sm text-gray-600 mr-2">{userInfo?.name}</span>
        <WppButton variant="secondary" size="m" onClick={btnLogic}>
          {buttonText}
        </WppButton>
      </div>
    </div>
  );
};

export default Header;
