import React from 'react';
import { useFormContext, Controller } from "react-hook-form"
import { WppInput } from '@wppopen/components-library-react'
import '../../../stylesheets/InsightName.css'

const InsightName = ({ insightId }) => {

  const { control, formState } = useFormContext()

  return (
    <>
      <div className="w-auto rounded-lg border-solid border-2 border-gray-200 h-40">
        <div className="text-xl font-semibold mb-6 mt-6 ml-6">Name Insight</div>
        <div className="w-80 ml-6">
          <Controller
            control={control}
            name="InsightName"
            render={({ field: { onChange, onBlur, value, error } }) => (
              <WppInput
                disabled={!!insightId}
                required
                placeholder=""
                message={formState.errors?.InsightName?.message}
                messageType="error"
                onWppChange={onChange}
                value={value}
                onWppBlur={onBlur}
              />
            )}
          />
        </div>
      </div>
    </>
  )
}
export default InsightName;