import _ from 'lodash';
import React from "react";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { line } from "d3-shape";

const axis = {
  "xAxis": "Average Growth Rate in %",
  "yAxis": "Retailer Score in %"
}

const COLOR_OPTIONS = { SELECTED: '#FF9E66', UNSELECTED: '#ABB1B5' };

const ScatterPlot = ({ data }) => {

  const dataTransformed = data.map(item => {
    return {
      id: item.id,
      data: item.data.map(a =>{
        return {
          retailer: a.retailer,
          y: a.retailer_score,
          x: a.growth
        };
      }) 
    }
  });

  if (!dataTransformed.length) return;

  const tooltip = (props) => {
    delete props.node.data.x;
    delete props.node.data.y;

    let text = (
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid gray",
          borderRadius: "10px",
        }}
      >
        <ul style={{ paddingLeft: "25px", paddingRight: "15px" }}>
          {Object.entries(props.node.data).map(([key, value]) => (
            <li key={key} style={{ textAlign: "left" }}>
              {key}:{" "}
              {key === "conversionRate"
                ? Math.round((Number(value) + Number.EPSILON) * 10000) / 10000
                : (value)}
            </li>
          ))}
        </ul>
      </div>
    );
    return <div>{text}</div>;
  };

  let totalX = 0,
      totalY = 0;
  let maxX = 0,
      minX = 0,
      maxY = 0,
      minY = 0;

  let count = 0;
  _.forEach(dataTransformed, (client) => {
    totalX += client.data.filter(a => a.x !== null).reduce((accum, next) => {
      maxX = parseFloat(next.x) > maxX ? parseFloat(next.x) : maxX;
      minX = parseFloat(next.x) < minX ? parseFloat(next.x) : minX;
      return accum + parseFloat(next.x);
    }, 0);
    totalY += client.data.filter(a => a.y !== null).reduce((accum, next) => {
      maxY = parseFloat(next.y) > maxY ? parseFloat(next.y) : maxY;
      minY = parseFloat(next.Y) < minY ? parseFloat(next.Y) : minY;
      return accum + parseFloat(next.y);
    }, 0);
    count += client.data.length;
  });

  const averageX = totalX / count;
  const averageY = totalY / count;

  const lineX = [
    { x: minX, y: averageY },
    { x: maxX, y: averageY },
  ];

  const lineY = [
    { x: averageX, y: minY },
    { x: averageX, y: maxY },
  ];

  const averageXLine: React.FC = ({ xScale, yScale }) => {
    const lineGenerator = line()
      .x((point) => xScale(point.x))
      .y((point) => yScale(point.y));

    return (
      <g>
        <path
          d={lineGenerator(lineX)}
          fill="none"
          stroke={"gray"}
          style={{
            pointerEvents: "none",
            strokeWidth: "2",
            strokeDasharray: "5,2",
          }}
        />
      </g>
    );
  };

  const averageYLine: React.FC = ({ xScale, yScale }) => {
    const lineGenerator = line()
      .x((point) => xScale(point.x))
      .y((point) => yScale(point.y));

    return (
      <g>
        <path
          d={lineGenerator(lineY)}
          fill="none"
          stroke={"gray"}
          style={{
            pointerEvents: "none",
            strokeWidth: "2",
            strokeDasharray: "5,2",
          }}
        />
      </g>
    );
  };

  const retailerLabel: React.FC = ({ nodes }) => {
    // labels for the selected
    return (
      <g>
        {nodes.map(({x, y, serieId, data }) => {
          if (serieId === 'SELECTED') {
            return (
              <text
                className="text-xs font-normal"
                transform={`translate(${x + 11}, ${y + 5})`}
                key={data.retailer}
              >
                {data.retailer}
              </text>
            );
          }
          return null;
        })}
      </g>
    );
  };

  const legend: React.FC = ({ innerWidth, innerHeight }) => {
    return (
      <g>
        <line x1={innerWidth - 150} y1={innerHeight + 60} x2={innerWidth - 100} y2={innerHeight + 60} 
          stroke="gray" strokeWidth="2" strokeDasharray="5,2" />
        <text
          className="text-s font-normal"
          transform={`translate(${innerWidth - 90}, ${innerHeight + 64})`}
        >
          Average
        </text>
      </g>
    )
  };

  return (
    <ResponsiveScatterPlot
      data={dataTransformed}
      tooltip={tooltip}
      useMesh={false}
      margin={{ top: 60, right: 80, bottom: 90, left: 90 }}
      xScale={{ type: "linear", min: minX >= 0 ? 0 : "auto", max: "auto" }}
      yScale={{ type: "linear", min: minY >= 0 ? 0 : "auto", max: "auto" }}
      blendMode="multiply"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 10,
        tickPadding: 5,
        tickRotation: 0,
        legend: axis.xAxis,
        legendPosition: "middle",
        legendOffset: 60,
      }}
      nodeSize={12}
      colors={a => COLOR_OPTIONS[a.serieId]}
      theme={{
        axis: {
          legend: {
            text: {
              fontSize: 20,
            },
          },
        },
      }}
      axisLeft={{
        tickSize: 10,
        tickPadding: 5,
        tickRotation: 0,
        legend: axis.yAxis,
        legendPosition: "middle",
        legendOffset: -60,
      }}
      layers={[
        "grid",
        "axes",
        "nodes",
        "markers",
        "mesh",
        "legends",
        averageXLine,
        averageYLine,
        retailerLabel,
        legend,
      ]}
    />
  );
};

export default ScatterPlot;
