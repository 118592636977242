import * as yup from 'yup';
import { INITIAL_SEGMENT } from './constants';

export const InsightValidationSchema = () => [
  // Validation for step1
  yup.object({
    InsightName: yup.string().required('Must select a new Insight name'),
  }),
  // Validation for step2
  yup.object({
    FlowSetId: yup.string().required('Must select a Flow'),
  }),
  // Validation for step 3
  yup.object({
    SegmentChannels: yup
      .array()
      .of(
        yup.object({
          segment: yup.string(),
          channels: yup.object(),
          retailerIds: yup.array().when('segment', {
            is: (value) => {
              return value !== INITIAL_SEGMENT.GENERAL;
            },
            then: () =>
              // yup.array().min(1, 'Please select a Retailer(s)...').of(yup.string()).required('Retailer is required'),
              yup.array().min(1, 'Please select a Retailer(s)...').required('Retailer is required'),
          }),
        })
      )
      .nullable(),
  }),
];
