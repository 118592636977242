import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useOktaAuth } from "@okta/okta-react";
import { Dialog, IconButton }  from "@mui/material";
import Revenue from './Explorer/Revenue';
import NetSalesByCategory from './Explorer/NetSalesByCategory';
import AverageOrderValue from './Explorer/AverageOrderValue';
import AverageConversionRate from './Explorer/AverageConversionRate';
import VisibilityScore from './Explorer/VisibilityScore';
import CloseIcon from '@mui/icons-material/Close';
import CompetitiveLandscape from './Explorer/CompetitiveLandscape';
import TopLevelStats from './Explorer/TopLevelStats';
import SocialMediaMetrics from './Explorer/SocialMediaMetrics';
import TrafficAnalytics from './Explorer/TrafficAnalytics';
import '../../../stylesheets/Explorer.css';

const Explorer = (props) => {

  const { card, isExplorerOpen, setIsExplorerOpen } = props;

  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getIdToken();

  const [data, setData] = useState([]);

  useEffect(() => {
    try {
      if (isExplorerOpen) {
        fetch(`https://flow-proxy-dev-ailufnpcpa-uc.a.run.app/explorer/ecommdb/${card.retailerId}/${card.marketId}`, {
          method: 'GET',
          headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}`},
        })
        .then(response => response.json())
        .then(data => {
          setData(data.ecomm_db_data)

          // TODO: THIS SECTION NEEDS TO BE CODED/TESTED
          console.log('>>>>>>>>>', data.ecomm_db_data);
        })
      }
    } catch (error) {
      console.log('API FETCH ERROR: ', error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExplorerOpen])

  const handleClose = () => {
    setIsExplorerOpen(false);
  };

  return (
  <Dialog
    fullWidth
    maxWidth={'100%'}
    scroll={'paper'}
    open={isExplorerOpen}
    onClose={handleClose}
  >
    <IconButton
      edge="start"
      color="inherit"
      onClick={handleClose}
      aria-label="close"
      sx={{justifySelf: 'flexEnd', position: 'absolute', top: '24px', right: '24px', color: '#333', zIndex: '100'}}
    >
      <CloseIcon />
    </IconButton>

    <div className={'exp-market row flex-center-all'}>
      <span className={'exp-market-name'}>Market</span>
      <span className={'exp-market-code'}>{card.marketCode}</span>
    </div>
    <div className={'row flex-direction-column flex-align-start exp-head-wrap'}>
      <div className={'row flex-direction-row flex-center-all'}>
        <img alt="partner-logo" src={`https://storage.googleapis.com/fusion-email-image-bucket/partner-logos/${card.retailerId}.png`} style={{width: "180px", height: "auto", margin: '0 0 0 24px'}}/>
        {/*<div style={{margin: '0 0 0 24px'}}>{card.retailerName}</div>*/}
        {/*<div className={'exp-market'}>FR</div>*/}
      </div>
      <TopLevelStats 
        TopLevelStatsData={null}
      />
    </div>
    {
      data?.length
      ?
      <div className={'row flex-direction-column flex-center-all bg-light-gray exp-det-wrap'}>
        <div className={'col-1-1'}>
          <div className={'col-1-1 flex-direction-column flex-center-all exp-box'}>
            <Revenue 
              RevenueData={_.get(_.find(data, 'RevenueData'), 'RevenueData')}
            />  
          </div>
        </div>
        <div className={'col-1-1'}>
          <div className={'col-1-2 flex-direction-column flex-center-all exp-box'}>
            <NetSalesByCategory 
              NetSalesByCategoryData={_.get(_.find(data, 'NetSalesByCategoryData'), 'NetSalesByCategoryData')}
            />
          </div>
          <div className={'col-1-2 flex-direction-column flex-center-all padding-16'}>
            <div className={'col-1-1 flex-direction-column flex-center-all exp-box-within padding-48'}>
              <CompetitiveLandscape
                CompetitiveLandscapeData={_.get(_.find(data, 'CompetitiveLandscapeData'), 'CompetitiveLandscapeData')}
              />
            </div>
            <div className={'col-1-1 flex-direction-column flex-center-all exp-box-within padding-48'}>
              <AverageOrderValue
                AverageOrderValueData={_.get(_.find(data, 'AverageOrderValueData'), 'AverageOrderValueData')}
              />
            </div>
            <div className={'col-1-1 flex-direction-column flex-center-all exp-box-within padding-48'}>
              <AverageConversionRate
                AverageConversionRateData={_.get(_.find(data, 'AverageConversionRateData'), 'AverageConversionRateData')}
              />
            </div>
          </div>
        </div>
        <div className={'col-1-1'}>
          <div className={'col-1-1 flex-direction-column flex-center-all exp-box'}>
            <VisibilityScore
              SeaData={_.get(_.find(data, 'SeaData'), 'SeaData')}
              SeoData={_.get(_.find(data, 'SeoData'), 'SeoData')}
            />
          </div>
        </div>
        <div className={'col-1-1'}>
          <div className={'col-1-2 flex-direction-column flex-center-all exp-box'}>
            <TrafficAnalytics 
              TrafficAnalyticsData={_.get(_.find(data, 'TrafficAnalyticsData'), 'TrafficAnalyticsData')}
            />
          </div>
          <div className={'col-1-2 flex-direction-column flex-center-all exp-box'}>
            <SocialMediaMetrics
              SocialMediaMetricsData={_.get(_.find(data, 'SocialMediaMetricsData'), 'SocialMediaMetricsData')}
            />
          </div>
        </div>
      </div>
    : null
    }
  </Dialog>
  )
}

export default Explorer;
