export const OktaRoles = {
  InsightsAdmin: 'GRP-HUB-AuthN-choreograph-Insights_Admin',
  PortfolioAdmin: 'GRP-HUB-AuthN-choreograph-Portfolio_Admin',
  InsightsSuperAdmin: 'GRP-HUB-AuthN-choreograph-Insights_Super_Admin',
  InsightsRfiViewer: 'GRP-HUB-AuthN-choreograph-Insights_RFI_Viewer',
  InsightsRfiEditor: 'GRP-HUB-AuthN-choreograph-Insights_RFI_Editor',
  InsightsFlowResponder: 'GRP-HUB-AuthN-choreograph-Insights_Flow_Responder',
  InsightsFlowEditor: 'GRP-HUB-AuthN-choreograph-Insights_Flow_Editor',
  InsightsEditor: 'GRP-HUB-AuthN-choreograph-Insights_Editor',
  InsightsPortfolioGeneral:
    'GRP-HUB-AuthN-choreograph-InsightsPortfolioGeneral',
};

export const levelNames = {
  level6: 6,
  level7: 7,
  level8: 8,
  level9: 9,
  level10: 10,
};

export const OktaPermissionLevels = [
  {
    level: levelNames.level10,
    permissions: [
      OktaRoles.InsightsSuperAdmin,
      OktaRoles.InsightsAdmin,
      OktaRoles.PortfolioAdmin,
    ],
  },
  {
    level: levelNames.level9,
    permissions: [OktaRoles.InsightsRfiEditor, OktaRoles.InsightsEditor],
  },
  {
    level: levelNames.level8,
    permissions: [OktaRoles.InsightsRfiViewer],
  },
  {
    level: levelNames.level7,
    permissions: [OktaRoles.InsightsFlowEditor],
  },
  {
    level: levelNames.level6,
    permissions: [OktaRoles.InsightsFlowResponder],
  },
];
