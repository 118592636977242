import { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const Tags = (props) => {
  const { rowId, rowMultiTemp, setRowMultiTemp } = props;

  const [rowItems, setRowItems] = useState([]);
  const [isUpdateRowTrue, setIsUpdateRowTrue] = useState(false);

  useEffect(() => {
    if (isUpdateRowTrue) {
      setRowMultiTemp({ ...rowMultiTemp, [rowId]: rowItems });
      setIsUpdateRowTrue(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowMultiTemp[rowId], rowItems]);

  const SortableItem = (props) => {
    const { index, value } = props;

    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition
    } = useSortable({ id: value });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition
    };
    const onMouseDown = (e) => {
      // e.preventDefault();
      // e.stopPropagation();
    };

    const handleDelete = (val) => {
      setRowItems(rowMultiTemp[rowId].filter((a) => a !== val.toString()));
      setIsUpdateRowTrue(true);
    }

    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <Chip
          key={index}
          label={value}
          onMouseDown={onMouseDown}
          onDelete={() => handleDelete(value)}
        />
      </div>
    );
  };

  const SortableChips = (props) => {
    const { values } = props;
    class MyPointerSensor extends PointerSensor {
      static activators = [
        {
          eventName: "onPointerDown",
          handler: ({ nativeEvent: event }) => {
            if (
              !event.isPrimary ||
              event.button !== 0 ||
              isInteractiveElement(event.target)
            ) {
              return false;
            }
            return true;
          },
        },
      ];
    }
    // This makes the chip's "x" mark to be non-draggable, 
    // and thus enables the triggering of the chip's "onDelete" event
    const isInteractiveElement = (element) => {
      const interactiveElements = [
        "path",
        "svg",
      ];
      if (interactiveElements.includes(element.tagName.toLowerCase())) {
        return true;
      }
      return false;
    }

    const sensors = [useSensor(MyPointerSensor)];

    const handleDragEnd = (event) => {
      const { active, over } = event;

      if (active && over && active.id !== over.id) {
        const oldIndex = values.indexOf(active.id);
        const newIndex = values.indexOf(over.id);
        setRowItems(arrayMove(values, oldIndex, newIndex));
        setIsUpdateRowTrue(true);
      }
    };
    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={values}
          strategy={verticalListSortingStrategy}
        >
          {values.map((id, index) => <SortableItem key={id} index={index} value={id} />)}
        </SortableContext>
      </DndContext>
    );
  };

  return (
    <Stack spacing={3} sx={{ width: 500 }}>
      <Autocomplete
        multiple
        id="tags-standard"
        onChange={(changedValues, value) => {
          setRowMultiTemp({ ...rowMultiTemp, [props.rowId]: value });
          setRowItems(value);
        }}
        options={[]}
        freeSolo={true}
        value={rowMultiTemp[rowId] || []}
        getOptionLabel={(option) => option.title || option}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Multi select options"
            placeholder="Type your options"
          />
        )}
        renderTags={(value) => <SortableChips values={value} />}
      />
    </Stack>
  );
}
export default Tags;