import React, { useState } from 'react';
import {
  WppInput,
  WppButton,
  WppFileUpload,
} from '@wppopen/components-library-react';
import DropSelectWppOpenByID from '../../../../components/DropSelectWppOpenByID';
import Image from '../../../assets/Image';
import X from '../../../assets/X';
import ExclamationIcon from '../../../assets/ExclamationIcon';

const AddRetailer = (props) => {

  const { 
    baseApi,
    accessToken,
    markets,
    setScoredOnly,
    setDialogOpen,
    setNewRetailers,
    handleDialogClose,
    setSnackMessage,
    setSnackOpen,
    setIsLoading } = props;

  const [responders, setResponders] = useState([]);
  const [newRetailerName, setNewRetailerName] = useState('');
  const [selectedImage, setSelectedImage] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadError, setUploadError] = useState(false);

  const imageChange = (e) => {
    const image = e.detail.value[0];
    if (image) {
      setSelectedImage(image);
    } else {
      setSelectedImage();
    }
  };
  
  const addRetailer = () => {

    const responderBody = [];
    responders.map(a => responderBody.push({ name: a, type: 'markets' }));
    
    let formData;
    formData = new FormData();
    formData.append("partner", JSON.stringify({ name: newRetailerName.trim(), type: "markets" }));
    formData.append("responders", JSON.stringify(responderBody));
    if (selectedImage) {
      formData.append("file", selectedImage, selectedImage.name);
    }
    
    setIsLoading(true);

    fetch(`${baseApi}/partner/create_or_update`, {
      method: 'POST',
      headers: { 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
      body: formData,
    })
    .then(response => {
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }
      return response.json()
    })
    .then(data => {
      setIsLoading(false);
      if (data.Message) {
        setSnackMessage(data.Message)
        setSnackOpen(true);
      }
      
      setNewRetailerName('');
      setResponders([]);
      setSelectedImage();

      setScoredOnly(false);
      setDialogOpen(false);
      setNewRetailers(data.created);
    })
    .catch((error) => {
      if (error.message === 'HTTP status 409') {
        setSnackMessage(`The retailer '${newRetailerName.trim()}' exists already.`)
        setSnackOpen(true);
      } else if (error.message === 'HTTP status 256') {
        setErrorMessage("Logo upload failed. Please proceed to add your retailer. You can update the logo later via the 'Edit Market' option.");
        setUploadError(true);
      } else {
        setSnackMessage(error.message);
        setSnackOpen(true);
      }
      setIsLoading(false);
    });
  }

  const addRetailerDisabled = !newRetailerName || !newRetailerName.trim().length || !responders.length || responders[0] === -1;

  return (
    <>
      <h3 slot="header">Add Retailer</h3>
      <div slot="body" className="w-auto">
        <div className="text-sm font-normal">To add a retailer, please enter the details below. Once you do, please proceed with editing default scores in the next view.</div>
        <div className="mt-6 text-sm font-semibold">Retailer Name</div>
        <div className="mt-2">
          <WppInput
            name="retailer"
            required
            placeholder=""
            value={newRetailerName}
            onWppChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNewRetailerName(event.target.value);
            }}
          />
        </div>
        <div className="mt-6 text-sm font-semibold">Markets</div>
        <div className="mt-2">
          <DropSelectWppOpenByID
            type="multiple"
            required
            items={markets}
            id="code"
            value="name"
            selection={responders}
            setSelection={setResponders}
          />
        </div>
        <div className="mt-6 text-sm font-semibold">Retailer logo</div>
        <div className="mt-2 flex flex-col">
          {!selectedImage 
          ? 
            <WppFileUpload
              acceptConfig={{
                'image/png': ['.png'],
              }}
              size={1}
              multiple={false}
              onWppChange={e => imageChange(e)}
              className="w-full"
            />
          :
            <>
              <div className="flex justify-center">
                <img
                  src={URL.createObjectURL(selectedImage)}
                  style={{maxWidth: '160px', maxHeight: '75px'}}
                  alt="Thumb"
                />
              </div>
              <div className="flex justify-between mt-2 items-center bg-gray-100 p-1">
                <div className="flex items-center">
                  <Image />
                  <span className="pl-2">{selectedImage.name}</span>
                </div>
                <button onClick={() => setSelectedImage()}><X /></button>
              </div>
            </>
          }
        </div>
        {uploadError &&
          <div className="flex items-top mt-2">
            <ExclamationIcon /><span className="ml-2 text-red-700 text-xs font-semibold">{errorMessage}</span>
          </div>
        }
      </div>
      <div slot="actions" className="flex justify-end">
        <WppButton variant="secondary" size="s" onClick={() => {
          setSnackOpen(false);
          setNewRetailerName('');
          setResponders([]);
          setSelectedImage();
          handleDialogClose();
        }}>Cancel</WppButton>
        <WppButton disabled={addRetailerDisabled} variant="primary" size="s" onClick={addRetailer} className="ml-4">Add Retailer</WppButton>
      </div>
    </>
  )
}
export default AddRetailer;