const TrashIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_513_2110)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.9997 9.0415C16.6607 9.0415 17.2243 9.4572 17.4435 10.0415H14.5558C14.775 9.4572 15.3387 9.0415 15.9997 9.0415ZM21.8316 10.0415H18.9933C18.7378 8.61994 17.4948 7.5415 15.9997 7.5415C14.5045 7.5415 13.2615 8.61994 13.0061 10.0415H10.167H8.70801C8.29379 10.0415 7.95801 10.3773 7.95801 10.7915C7.95801 11.2057 8.29379 11.5415 8.70801 11.5415H9.48514L10.5237 22.2742C10.6434 23.5129 11.6849 24.4582 12.929 24.4582H19.0694C20.3139 24.4582 21.355 23.5131 21.4747 22.274L22.5136 11.5415H23.2913C23.7056 11.5415 24.0413 11.2057 24.0413 10.7915C24.0413 10.3773 23.7056 10.0415 23.2913 10.0415H21.8321C21.8319 10.0415 21.8318 10.0415 21.8316 10.0415ZM21.0066 11.5415H10.9921L12.0167 22.1297C12.0621 22.5993 12.4573 22.9582 12.929 22.9582H19.0694C19.5415 22.9582 19.9363 22.5999 19.9816 22.1299L21.0066 11.5415ZM15.2915 14.125C15.2915 13.7108 14.9557 13.375 14.5415 13.375C14.1273 13.375 13.7915 13.7108 13.7915 14.125V20.375C13.7915 20.7892 14.1273 21.125 14.5415 21.125C14.9557 21.125 15.2915 20.7892 15.2915 20.375V14.125ZM17.458 13.375C17.8722 13.375 18.208 13.7108 18.208 14.125V20.375C18.208 20.7892 17.8722 21.125 17.458 21.125C17.0438 21.125 16.708 20.7892 16.708 20.375V14.125C16.708 13.7108 17.0438 13.375 17.458 13.375Z"
          fill="#0014CC"
        />
      </g>
      <defs>
        <clipPath id="clip0_513_2110">
          <rect width="32" height="32" rx="6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TrashIcon;
