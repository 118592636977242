import { ResponsiveHeatMap } from '@nivo/heatmap'

const COLOR_OPTIONS = ['#0013BD', '#0024F0', '#2954FF', '#5C85FF', '#94B4FF'];

const MyResponsiveHeatMap = ({ data }) => (
  <ResponsiveHeatMap
    data={data}
    margin={{ top: 60, right: 90, bottom: 60, left: 130 }}
    valueFormat=" >-.0f"
    xOuterPadding={0.15}
    xInnerPadding={0.5}
    yOuterPadding={0.15}
    yInnerPadding={0.25}
    enableGridX={true}
    enableGridY={true}
    axisTop={{
      tickSize: 0,
      tickPadding: 7,
      tickRotation: 0,
      legend: '',
      legendOffset: 46,
      truncateTickAt: 0
    }}
    axisLeft={{
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      legendPosition: 'middle',
      legendOffset: -60,
      truncateTickAt: 0
    }}
    labelTextColor="#ffffff"
    colors={a => {
      switch(a.data.y) {
        case 5:
          return COLOR_OPTIONS[0];
        case 4:
          return COLOR_OPTIONS[1];
        case 3:
          return COLOR_OPTIONS[2];
        case 2:
          return COLOR_OPTIONS[3];
        default:
          return COLOR_OPTIONS[4];
      }
    }}
    sizeVariation={{
      sizes: [0.5, 1]
    }}
    emptyColor="#555555"
    cellComponent="circle"
    borderRadius={16}
    inactiveOpacity={0.1}
    borderColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          '0.8'
        ]
      ]
    }}
    legends={[]}
  />
)
export default MyResponsiveHeatMap;