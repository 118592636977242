import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useFormContext } from "react-hook-form"
import { WppInlineMessage } from '@wppopen/components-library-react';
import { WppTooltip } from '@wppopen/components-library-react'
import Retailers from './Retailers';
import Channel from './Channel';
import ExclamationIcon from '../../assets/ExclamationIcon';
import InfoIcon from '../../assets/InfoIcon';
import { INITIAL_SEGMENT } from '../Utils/constants';

const SegmentChannels = (props) => {

  const { baseApi, accessToken, channels, currentTab, fields, tabTotal100, setIsSpinnerSpinning, rerender, setRerender } = props;

  const [retailers, setRetailers] = useState([]);
  const [total, setTotal] = useState(0);

  const { getValues } = useFormContext()

  const segmentIndex = fields.findIndex((item) => item?.segment === currentTab)
  
  const sum = (editedChannel, newValue) => {
    // fields DOES NOT GET UPDATED IMMEDIATELY, WITH THE CURRENTLY EDITED VALUE
    const tabItems = fields[segmentIndex].channels;
    let x = 0;
    _.forEach(channels, channel => {
      if (`${channel.attributeId}-${channel.entityId}` === editedChannel) {
        x += +newValue;
      } else {
        x += +tabItems[`${channel.attributeId}-${channel.entityId}`];
      }
    })
    setTotal(x.toFixed(0));
  }

  useEffect(() => {
    const flowSetId = getValues().FlowSetId;
    if (!retailers.length && typeof baseApi !== "undefined" && typeof accessToken !== "undefined") {
      setIsSpinnerSpinning(true);
      fetch(`${baseApi}/flow/${flowSetId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
      })
        .then(response => response.json())
        .then(data => {
          setRetailers(data.data.flows.map(a => ({ partner: `${a.partner}|${a.responder}`, partnerName: `${a.partnerName} | ${a.responderName}` })));
          setIsSpinnerSpinning(false);
        })
        .catch((error) => {
          console.log('API FETCH ERROR: ', error);
          setIsSpinnerSpinning(false);
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailers, baseApi, accessToken])

  useEffect(() => {
    tabTotal100.current[currentTab] = Math.round(total) === 100 ? true : false;
    setRerender(!rerender)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total])

  useEffect(() => {
    if (fields[segmentIndex]) {
      sum();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentIndex, channels])

  return (
    <>
      {currentTab === INITIAL_SEGMENT.GENERAL && (
        <div className="mb-6">
          <WppInlineMessage
            size="m"
            message="General is the default segment for all retailers until assigned a different segment"
            type="warning"
          />
        </div>
      )}
      {currentTab !== INITIAL_SEGMENT.GENERAL && retailers.length && (
        <>
          <div className="flex text-sm font-semibold mb-2">
            Select Retailers
            <WppTooltip className="ml-1" text='Retailers selected will be removed from "General"'><InfoIcon /></WppTooltip>
          </div>
          <div className="mb-8" id="retailers">
            <Retailers
              currentTab={currentTab}
              segmentIndex={segmentIndex}
              retailers={retailers}
              fields={fields}
            />
          </div>
        </>
      )}
      <table className=" w-auto h-auto flex justify-center mb-6">
        <tbody>
          <tr className="border-solid border-b border-gray-400 h-10">
            <td className="text-sm font-semibold w-56 pl-4">Channel</td>
            <td className="text-sm font-semibold w-20">Weight</td>
          </tr>
          {!!fields[segmentIndex] &&
            channels.map(channel => {
              return (
                <tr key={channel.entityId} className="border-solid border-b border-gray-200 h-14 pt-4 pb-4">
                  <td className="text-sm font-normal pl-4">{channel.channel}</td>
                  <td className="text-sm font-normal">
                    <Channel segmentIndex={segmentIndex} fields={fields} channel={channel} sum={sum} />
                  </td>
                  <td className="w-14 text-center">%</td>
                </tr>
              )    
            })
          }
          <tr className="h-16 bg-gray-50">
            <td className="text-sm font-semibold pl-4">Totals</td>
            <td className="text-sm font-semibold">
              <div className="flex items-center justify-end">
                {Math.round(total) === 100 ? null : <WppTooltip text='Total should equal 100%'><ExclamationIcon /></WppTooltip>}
                <span className="ml-1" style={{ color: Math.round(total) === 100 ? null : 'red' }}>{total}</span>
              </div>
            </td>
            <td className="w-14 text-center">%</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
export default SegmentChannels;