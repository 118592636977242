import _ from 'lodash';
import { useState, useEffect, useCallback, useRef } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Box, Button, Autocomplete, Chip, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  useGridApiRef,
  GridColumnMenu,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-pro";
import { randomId } from "@mui/x-data-grid-generator";
import AutoCompleteSortable from "../AutoCompleteWPP/AutoCompleteSortable";
import SnackbarComponent from '../../../../../components/Snackbar';
import { CATEGORIES, SUBCATEGORIES, WEIGHTS, INPUT_TYPES, INPUT_TYPE, IS_MANDATORY_OPTIONS } from '../../../Utils/constants';
import { WppButton } from "@wppopen/components-library-react";
import "../../../../../stylesheets/RfiBuilderDemo.css";
import { Styles } from './styles'

const isSelectOption = (inputType) => {
  if (inputType === INPUT_TYPE.Multi_Select 
    || inputType === INPUT_TYPE.Multi_Select_Other_Option
    || inputType === INPUT_TYPE.Single_Select) {
      return true;
    }
    return false;
}

const RFIBuilder = (props) => {

  const {
    rfiId,
    clonedRfiId,
    setIsSpinnerSpinning,
    setRfibuilderInEditState,
    isRfiViewMode,
    handleNext,
    inEditState,
    newRfi,
    setClonedRfiId,
    handleBack,
    editMode,
    hasAssociatedFlow } = props;

  const FlowStepperStyle = Styles.FlowStepperStyle;

  const initialColumns = [
    {
      field: "category",
      headerName: "Category",
      width: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: CATEGORIES,
    },
    {
      field: "subcategory",
      headerName: "Sub Category",
      width: 300,
      editable: true,
      type: "singleSelect",
      valueOptions: SUBCATEGORIES,
    },
    {
      field: "question",
      headerName: "Question",
      width: 260,
      editable: true,
      disableColumnMenu: true,
    },
    {
      field: "inputtype",
      headerName: "Input Type",
      width: 260,
      editable: true,
      type: "singleSelect",
      valueOptions: INPUT_TYPES,
    },
    {
      field: "weight",
      headerName: "Weight",
      width: 120,
      editable: true,
      type: "singleSelect",
      valueOptions: WEIGHTS,
    },
    {
      field: "isMandatory",
      headerName: "Is Mandatory?",
      width: 200,
      editable: true,
      type: "singleSelect",
      valueOptions: IS_MANDATORY_OPTIONS,
    },
    {
      field: GRID_DETAIL_PANEL_TOGGLE_FIELD
    }
  ]

// ADD NEW ROW
const EditToolbar = (props) => {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { category: '', subcategory: '', id, isNew: true, inputtype: '', weight: '', isMandatory: '', newRow: true }]);
    
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <GridToolbarContainer className={"margin-t-42"}>
      {!isRfiViewMode && <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add row
      </Button>}
    </GridToolbarContainer>
  );
}

const MyCustomNoRowsOverlay = () => (
  <div className={"col-1-1 flex-center-all"}>No rows found</div>
);

// Swap positions of filter and sort items
const CustomColumnMenu = (props) => {
  return (
    <GridColumnMenu
      {...props}
      slotProps={{
        columnMenuFilterItem: {
          displayOrder: 0, // Previously `10`
        },
        columnMenuSortItem: {
          displayOrder: 10, // Previously `0`
        },
      }}
    />
  );
}

  // SAVE / CANCEL ICONS AND ACTIONS
  const actionColumn = {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 125,
    cellClassName: "actions",
    getActions: ({ id }) => {
      const existingRecord = rows.some(a => a.id === id && !a.newRow);
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
              color: "primary.main",
            }}
            onClick={() => handleSaveClick(id)}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            className="textPrimary"
            onClick={() => handleCancelClick(id)}
            color="inherit"
          />,
        ];
      }

      if (!editMode
          || (editMode && !existingRecord)
          || (editMode && hasAssociatedFlow !== undefined && !hasAssociatedFlow)) {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
          />
        ];
      } else {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(id)}
            color="inherit"
          />,
        ];
      }
    },
  };

  const columns = [...initialColumns, actionColumn];

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSingle, setRowSingle] = useState({});
  const [rowMultiTemp, setRowMultiTemp] = useState({});
  const [rows, setRows] = useState([]);
  const [rowMulti, setRowMulti] = useState({});

  const savedRows = useRef(new Set());
  const mostRecentlySaved = useRef();
  const isDetailVisible = useRef({});
  
  const baseApi = process.env.REACT_APP_API_ENDPOINT;
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getIdToken();

  useEffect(() => {
    if (Object.keys(rowModesModel).length) {
      setRfibuilderInEditState(true);
    } else {
      setRfibuilderInEditState(false);
    }
  })

  //Save RFI Builder
  const handleSaveData = () => {
    if (isRfiViewMode) {
      handleNext();
      return
    }
    if (inEditState()) return;

    let rowsModified; 
    if (editMode) {
      rowsModified = rows.filter((row) => savedRows.current.has(row.id)).map((row) => {
        return {
          ...row, isMandatory: row.isMandatory === "Yes"
        }
      })
    } else {
      rowsModified = rows.map((row) => {
        return {
          ...row, isMandatory: row.isMandatory === "Yes"
        }
      })
    }
    let columnsData = initialColumns?.filter(column => column.type !== 'actions');

    setIsSpinnerSpinning(true);
    fetch(`${baseApi}/rfi/${newRfi}${editMode ? '/update-builder/' : '/create-builder/'}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
      body: JSON.stringify({ rows: rowsModified, rowMulti, columns: columnsData }),
    })
      .then(response => response.json())
      .then(data => {
        setIsSpinnerSpinning(false);
        setClonedRfiId(null);
        handleNext();
      })
      .catch(error => console.error('Error:', error));
    }

  const getMUIGridData = useCallback(() => {
    setIsSpinnerSpinning(true);
    fetch(`${baseApi}/rfi/${clonedRfiId ? clonedRfiId : rfiId}/fetch-builder`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
    })
      .then(response => response.json())
      .then(data => {
        setIsSpinnerSpinning(false);
        let rowsResponse = data.data.rows.map((row) => {
          return { ...row, isMandatory: row.isMandatory ? "Yes": "No", fromDB: editMode ? true : false }
        })
        setRows(rowsResponse);
        setRowMulti(data.data.rowMulti)
      }
      )
      .catch((error) => {
        setSnackMessage(error.message);
        setSnackOpen(true);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, baseApi, rfiId])

  useEffect(() => {
    setRowMultiTemp(_.cloneDeep(rowMulti));
  }, [rowMulti])

  useEffect(() => {
  }, [rows])
  useEffect(() => {
    if (rfiId)
      getMUIGridData()
  }, [getMUIGridData, rfiId])

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  // EDIT PEN CLICKED
  const handleEditClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit },
    });
    
    const row = rows.find((row) => row.id === id)
    if (isSelectOption(row.inputtype)) {
      if(!isDetailVisible.current[id]?.visible) {
        isDetailVisible.current = Object.assign(isDetailVisible.current, {[id]: {visible: true }});
        apiRef.current.toggleDetailPanel(id);
      }
    }
  };

  // SAVE ICON CLICKED
  const handleSaveClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View },
    });

    mostRecentlySaved.current = id;
    savedRows.current.add(id);
  };

  // DELETE ICON
  const handleDeleteClick = (id) => {
    setRows(rows.filter((row) => row.id !== id));
    delete isDetailVisible.current[id];
  };

  // CANCEL ICON
  const handleCancelClick = (id) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: {
        mode: GridRowModes.View,
        ignoreModifications: true,
      },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    } else if (isSelectOption(editedRow.inputtype)) {
      setRowMultiTemp(_.cloneDeep(rowMulti));
      setRowModesModel({
        ...rowModesModel,
        [editedRow.id]: { mode: GridRowModes.Edit },
      });
    } 
    
    if (isDetailVisible.current[id]?.visible 
        && !isSelectOption(editedRow.inputtype)) {
      delete isDetailVisible.current[id];
      apiRef.current.toggleDetailPanel(id);
    }
  }

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    
    if (isSelectOption(updatedRow.inputtype)) {
      if(updatedRow.id && rowMultiTemp[updatedRow.id]?.length) {
        setRowModesModel({
          ...rowModesModel,
          [updatedRow.id]: { mode: GridRowModes.View },
        });
        setRowMulti(_.cloneDeep(rowMultiTemp));
        setRowMultiTemp({});
        
        if (isDetailVisible.current[updatedRow.id]?.visible) {
          isDetailVisible.current = Object.assign(isDetailVisible.current, {[updatedRow.id]: {visible: false }});
          apiRef.current.toggleDetailPanel(updatedRow.id);
        }
      } else {
        setRowModesModel({
          ...rowModesModel,
          [updatedRow.id]: { mode: GridRowModes.Edit },
        });

        if(!isDetailVisible.current[updatedRow.id]?.visible) {
          isDetailVisible.current = Object.assign(isDetailVisible.current, {[updatedRow.id]: {visible: true }});
          apiRef.current.toggleDetailPanel(updatedRow.id);
        }
      }
    } else if (isDetailVisible.current[updatedRow.id]?.visible 
              && !isSelectOption(updatedRow.inputtype)) {
      delete isDetailVisible.current[updatedRow.id];
      apiRef.current.toggleDetailPanel(updatedRow.id);
    }

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    // THE FOLLOWING CHAIN DETERMINES THE VISIBILITY OF THE SAVE/EDIT ICON FOR THE "Select Options" QUESTIONS
    // DEPENDING ON THE STATE OF THE MASTER/DETAIL ROWS, IT CAN BE DETERMINED AT EITHER THE 
    // processRowUpdate OR THE FINAL handleRowModesModelChange FUNCTION
    // handleSaveClick => processRowUpdate => handleRowModesModelChange

    if (isSelectOption(rows.find((item) => item.id === mostRecentlySaved.current)?.inputtype)){
      if(rowModesModel[mostRecentlySaved.current]?.mode !== 'edit') {
        setRowModesModel(newRowModesModel);
      }
    } else {
      setRowModesModel(newRowModesModel);
    }
  };

  const handleMasterDetail = (row) => {
    if (isSelectOption(row.row.inputtype)) {
      return (
        <div className={"col-1-1 margin-t-64 margin-bt-64"}>
          <AutoCompleteSortable
            rowId={row.id}
            rowMultiTemp={rowMultiTemp}
            setRowMultiTemp={setRowMultiTemp}
          />
        </div>
      );
    } else if (row.row.inputtype === "Single Select") {
      return (
        <div className={"col-1-1 margin-t-64 margin-bt-64"}>
          <Autocomplete
            clearIcon={false}
            sx={{ width: 600 }}
            options={[]}
            freeSolo
            multiple
            renderTags={(value, props) =>
              value.map((option, index) => (
                <Chip
                  label={option}
                  {...props({
                    index,
                  })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField label="Add Single Select Options" {...params} />
            )}
            onChange={(event, newValue) => {
              setRowSingle({
                ...rowSingle,
                [row.id]: newValue,
              });
            }}
            value={rowSingle[row.id]}
          />
        </div>
      );
    } else {
      return (
        <div className={"col-1-1 margin-t-64 margin-bt-64"}>
          <div>No input required</div>
        </div>
      );
    }
  };

  const apiRef = useGridApiRef();

  const screenHeight = window.innerHeight * 0.65;
  return (
    <>
      <Box className="rfiBuilderContainer"
        sx={{
          height: `${screenHeight}px`,
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
        }}
      >
        {<DataGridPro
          apiRef={apiRef}
          autoHeight={false}
          rowReordering={isRfiViewMode ? false : true}
          disableColumnReorder={!isRfiViewMode ? false : true}
          rows={rows}
          columns={isRfiViewMode ? initialColumns : columns}
          isCellEditable={(params) => !(params.row.fromDB && params.field === 'inputtype')}
          editMode={isRfiViewMode ? "none" : "row"}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={error => console.log('onProcessRowUpdateError: ', error)}
          slots={{
            toolbar: EditToolbar,
            noRowsOverlay: MyCustomNoRowsOverlay,
            columnMenu: CustomColumnMenu,
          }}
          slotProps={{
            toolbar: {
              setRows,
              setRowModesModel,
            },
          }}
          getDetailPanelContent={handleMasterDetail}
          getDetailPanelHeight={({ row }) => "auto"}
          initialState={{
            columns: {
              columnVisibilityModel: {
                [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false
              }
            }
          }}
          sx={{
            "& .MuiDataGrid-menuIcon": {
              visibility: "visible",
              width: "auto"
            },
            '.MuiDataGrid-columnSeparator': {
              visibility: 'visible',
            },
          }}
        />
        }
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}
        style={FlowStepperStyle.stepperControls}
      >
        {!isRfiViewMode && <WppButton
          variant={'secondary'}
          color="inherit"
          onClick={handleBack}
          style={{ marginRight: '8px' }}
        >
          Back
        </WppButton>}
        <WppButton onClick={handleSaveData} sx={{ mr: 1 }}>Next</WppButton>
      </Box>
      <SnackbarComponent
        snackOpen={snackOpen}
        setSnackOpen={setSnackOpen}
        message={snackMessage}
      />
    </>
  );
}
export default RFIBuilder;