import React, { useState } from 'react';
import { useOktaAuth } from "@okta/okta-react";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertTwoToneIcon from "@mui/icons-material/MoreVertTwoTone";
import PreviewIcon from '@mui/icons-material/Preview';
import VisibilityIcon from '@mui/icons-material/Visibility';

const baseApi = process.env.REACT_APP_API_ENDPOINT+ '/insights/v2/1';

const TimeMachineMenu = (props) => {

  const { 
    userName,
    timeStamp,
    completed,
    changeId,
    changes,
    setPreview,
    setSnackMessage,
    setSnackOpen,
    includeExcludeTrigger,
    setIncludeExcludeTrigger,
  } = props;

  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getIdToken();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const includeExclude = () => {
    let type = '';
    if (completed) {
      type = 'exclude';
    } else {
      type = 'include';
    }
    fetch(`${baseApi}/${type}/${changeId}`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}`},
    })
    .then(response => {
      if (!response.ok) {
        throw new Error("HTTP status " + response.status);
      }
      return response.json()
    })
    .then(data => {
      setSnackMessage(`The changes by ${userName} on ${timeStamp} were successfully ${type}d.`)
      setSnackOpen(true);
      setAnchorEl(null);
      setIncludeExcludeTrigger(!includeExcludeTrigger);
    })
    .catch((error) => {
      setSnackMessage(error.message);
      setSnackOpen(true);
    });
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{'margin': '0 1px', p: .5, 'minWidth': 24}}
      >
        <MoreVertTwoToneIcon style={{'width': '20px'}}/>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        style={{'zIndex': '11000'}}
        disableScrollLock={true}
      >
        <MenuItem onClick={() => {handleClose(); setPreview(changes);}}><PreviewIcon style={{'marginRight' : '6px', 'width': '20px'}}/>Preview changes</MenuItem>
        <MenuItem onClick={includeExclude}><VisibilityIcon style={{'marginRight' : '6px', 'width': '20px'}}/>{completed ? 'Exclude' : 'Include'}</MenuItem>
      </Menu>
    </div>
  );
}
export default TimeMachineMenu;