const Tooltip = ({ interactionData }) => {
  if (!interactionData) {
    return null;
  }

  const { xPos, yPos, x, y, logoPath, name } = interactionData;

  return (
    <div
      className="w-[230px] h-[152px] bg-white border border-gray-100 shadow p-2.5 absolute opacity-1 z-[99999]"
      style={{
        left: xPos - (y <= 86 ? 50 : -80),  // To prevent the tooltip from being cutoff
        top: yPos - (y <= 86 ? 143 : 110),
      }}
    >
      <div className="flex justify-center">
        {logoPath
          ?
            <img
              alt="partner-logo"
              style={{maxWidth: '160px', maxHeight: '75px'}}
              src={'https://storage.googleapis.com/fusion-email-image-bucket/partner-logos/' + logoPath + '.png'}
            />
          :
            <div className="font-semibold pt-7 h-[75px]">{name}</div>
        }
      </div>
      <div className="w-full bg-[#c9c7c7] h-px mt-[8px] mb-[10px]" />
      <div>
        <div className="flex justify-between leading-5 text-sm">
          <span>Retailer Maturity Index: </span>
          <b>{`${Math.round(x * 100)} %`}</b>
        </div>
        <div className="flex justify-between leading-5 text-sm">
          <span>Growth Potential Index: </span>
          <b>{Math.round(y * 100) / 100}</b>
        </div>
      </div>
    </div>
  );
};
export default Tooltip;