import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from "react-hook-form"
import { WppInlineMessage } from '@wppopen/components-library-react'
import { WppSelect, WppListItem } from '@wppopen/components-library-react';
import { Styles } from './styles'

const SelectRFI = (props) => {

  const { rfiId, setRfiId, rfiCardsData } = props;

  const [rfis, setRfis] = useState([]);
  const [selectedRfi, setSelectedRfi] = useState(null);

  useEffect(() => {
    setSelectedRfi(rfiId)
  }, [rfiId])

  useEffect(() => {
    if (typeof rfiCardsData !== 'undefined') {
      setRfis(rfiCardsData?.map((rfi) => (rfi)));
    }
  }, [rfiCardsData])

  const { control } = useFormContext()

  const handleChange = (event) => {
    setRfiId(event.target.value)
  }

  return (
    <>
      <div style={Styles.SelectRFI.message}>
        <WppInlineMessage
          size="m"
          message="If you would like to create a new RFI, please navigate back to the RFI tab."
          type="warning"
        />
      </div>
      <div style={Styles.SelectRFI.dropdown}>
        <Controller
          control={control}
          name="RFI"
          render={({ field: { onChange, onBlur, value, error } }) => (
            <WppSelect
              onWppChange={(e) => {
                handleChange(e);
                onChange(e);
              }}
              placeholder="Select"
              required
              labelConfig={{ text: 'Select from Existing RFI' }}
              value={selectedRfi}
              onBlur={onBlur}
            >
              {rfis?.map(rfi => (
                <WppListItem value={rfi.rfiId} key={rfi.rfiId}>
                  <p className="w-96 text-[16px]" slot="label">{rfi.title}</p>
                </WppListItem>
              ))}
            </WppSelect>
          )}
        />
      </div>
    </>
  )
}
export default SelectRFI;