/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import { useOktaAuth } from "@okta/okta-react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SelectRFIType from './Steps/SelectRFIType';
import RFIBuilder from './Steps/RFIBuilder';
import Review from './Steps/Review';
import useAuthUser from "../../../../components/AuthUser";
import SnackbarComponent from '../../../../components/Snackbar';
import { Box, Stepper, Step, StepButton } from "@mui/material";
import { WppButton } from "@wppopen/components-library-react";
import { RFIValidationSchema, RFIEditValidationSchema } from '../../Utils/validation';
import { RFI_OPTIONS, RFI_STEPS } from '../../Utils/constants';
import { ModalSpinner } from "../../../../components/ModalSpinner";
import { Styles } from './styles';
import './temp_styles.css';

const FlowStepperStyle = Styles.FlowStepperStyle;

const baseApi = process.env.REACT_APP_API_ENDPOINT;

const screenHeight = window.innerHeight * 0.65;

const RFIStepper = (props) => {
  const { handleCloseModal, rfiCardsData, rfiId, rfiTitle, setRfiCreatedUpdated, isRfiViewMode, editMode, hasAssociatedFlow } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [isExistingRFI, setIsExistingRFI] = useState(true);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [newRfi, setNewRfi] = useState(null);
  const [clonedRfiId, setClonedRfiId] = useState(null);
  const [isSpinnerSpinning, setIsSpinnerSpinning] = useState(false);
  const [rfiCreated, setRfiCreated] = useState(false);
  const [rfibuilderInEditState, setRfibuilderInEditState] = useState(false);
  const [initialEntry, setInitialEntry] = useState(false)

  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getIdToken();
  const userInfo = useAuthUser();

  const currentValidationSchema = rfiId ? RFIEditValidationSchema(rfiId, rfiCardsData)[activeStep] : RFIValidationSchema(rfiCardsData)[activeStep];

  useEffect(() => {
    if (rfiId && isRfiViewMode && activeStep === 0) {
      setActiveStep(1);
    }
    else if (((rfiId && !isRfiViewMode) || !rfiId) && !initialEntry) {
      setActiveStep(0)
      setInitialEntry(true)
    }
    // eslint-disable-next-line
  }, [isRfiViewMode, rfiId, activeStep])

  const methods = useForm({
    shouldUnregister: false,
    resolver: activeStep < 1 ? yupResolver(currentValidationSchema) : null,
    mode: "onChange"
  });
  const { handleSubmit, trigger, reset } = methods;

  const generateSteps = (steps) => {
    return steps.map((label, index) => (
      <Step key={label} sx={FlowStepperStyle.stepper}>
        <StepButton color="inherit" onClick={handleStep(index)}>
          {label}
        </StepButton>
      </Step>
    ))
  }

  const inEditState = () => {
    if (rfibuilderInEditState) {
      setSnackMessage('It seems you\'re in the middle of editing... Please save your changes.');
      setSnackOpen(true);
      return true;
    }
    return false;
  }

  const onSubmit = (data) => {
    if (rfiCreated || isRfiViewMode) {
      handleNext();
      return;
    }

    if (inEditState()) return;

    setIsSpinnerSpinning(true);
    const requestBody = {};
    requestBody['title'] = data.RFIType === RFI_OPTIONS.EXISTING_RFI.value ? data.clonedRFIName : data.newRFIName;
    requestBody['createdBy'] = { 'name': userInfo?.name, 'emailAddress': userInfo?.email };

    fetch(rfiId ? `${baseApi}/rfi/${rfiId}/save` : `${baseApi}/rfi/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
      body: JSON.stringify(requestBody),
    })
      .then(response => response.json())
      .then(data => {
        if (rfiId) {
          setNewRfi(rfiId);
        } else if (data) {
          setNewRfi(data?.rfiId);
          setRfiCreated(true);
        }
        setRfiCreatedUpdated((previous) => previous * -1)
        handleNext();
      })
      .catch((error) => {
        setSnackMessage('There was an error creating an RFI.' + error);
        setSnackOpen(true);
      });
  };

  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setRfibuilderInEditState(false);
  };

  const handleStep = (step) => () => {
    const newStep = isRfiViewMode ? step + 1 : step;
    if (activeStep !== newStep) {
      setActiveStep(newStep);
    }
  };

  const handleReset = () => {
    setRfiCreated(false);
    setActiveStep(0);
    setClonedRfiId(null);
    reset();
    handleCloseModal();
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return <SelectRFIType 
          rfiId={rfiId}
          rfiTitle={rfiTitle}
          rfiCardsData={rfiCardsData} 
          isExistingRFI={isExistingRFI} 
          setIsExistingRFI={setIsExistingRFI} 
          rfiCreated={rfiCreated} 
          setClonedRfiId={setClonedRfiId} />;
      case 1:
        return <RFIBuilder
          newRfi={newRfi}
          rfiId={rfiId ? rfiId : newRfi}
          clonedRfiId={clonedRfiId}
          setIsSpinnerSpinning={setIsSpinnerSpinning}
          setRfibuilderInEditState={setRfibuilderInEditState}
          isRfiViewMode={isRfiViewMode}
          handleNext={handleNext}
          inEditState={inEditState}
          handleBack={handleBack}
          setClonedRfiId={setClonedRfiId}
          editMode={editMode}
          hasAssociatedFlow={hasAssociatedFlow} />;
      case 2:
        return <Review />;
      default:
        return null;
    }
  };

  return (
    <>
      <Box sx={{ width: '100%', marginTop: '64px' }}>
        <Stepper alternativeLabel activeStep={activeStep}>
          {isRfiViewMode ? generateSteps(RFI_STEPS.slice(1)) : generateSteps(RFI_STEPS)}
        </Stepper>

        {isSpinnerSpinning ? (
          <ModalSpinner/>
        ) : null}

        <FormProvider {...methods}>
          <form style={FlowStepperStyle.rfiForm(screenHeight)}>
            {getStepContent()}
            <Box
              sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}
              style={FlowStepperStyle.stepperControls}
            >
              {activeStep !== 1 && <WppButton
                variant={'secondary'}
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                style={{ marginRight: '8px' }}
              >
                Back
              </WppButton>}
              {activeStep === 0 && (
                <WppButton onClick={handleSubmit(onSubmit)} sx={{ mr: 1 }}>
                  {rfiCreated ? 'Next' : rfiId ? 'Update RFI' : 'RFI Create'}
                </WppButton>
              )}
              {activeStep === RFI_STEPS.length - 1 && (
                <WppButton onClick={handleSubmit(handleReset)} sx={{ mr: 1 }}>
                  Close
                </WppButton>
              )}
            </Box>
          </form>
        </FormProvider>
      </Box>
      <SnackbarComponent
        snackOpen={snackOpen}
        setSnackOpen={setSnackOpen}
        message={snackMessage}
      />
    </>
  );
}

export default RFIStepper;