import { WppSpinner } from '@wppopen/components-library-react';
import React from 'react';

export const ModalSpinner = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'rgba(255,255,255,0.5)',
        zIndex: '1000000',
        width: '100%',
        height: '100vh',
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all .3s ease-in-out',
      }}
    >
      <WppSpinner size="l" style={{ marginLeft: '80px' }} />
    </div>
  );
};
