import React from 'react';
import { levelNames } from '../okta/OktaRoles';
import { IsRendered } from '../utils/RolesUtils';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WavesIcon from '@mui/icons-material/Waves';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';

const SideBar = (props) => {

  const { active, userPermissions } = props;
  const { level6, level10 } = levelNames;
  const iconClasses =
    'flex flex-col justify-center items-center cursor-pointer text-xs my-4 hover:text-[#0A2FFF]';

  return (
    <>
      <div className="w-[80px] flex justify-center fixed h-full bg-white text-[#888]">
        <div className="flex flex-col justify-center items-center h-full -mt-[64px]">
          <a className={`${iconClasses} ${active === 'insights' && ' text-[#0A2FFF]'}`} href={'/home'}>
            <ScatterPlotIcon />
            <span>Insights</span>
          </a>
          {userPermissions && IsRendered(level6, userPermissions) && (
            <a className={`${iconClasses} ${active === 'flow' && ' text-[#0A2FFF]'}`} href={'/flow'}>
              <WavesIcon />
              <span>Flow</span>
            </a>
          )}
          {userPermissions && IsRendered(level10, userPermissions) && (
            <a className={`${iconClasses} ${active === 'portfolio' && ' text-[#0A2FFF]'}`} href={'/portfolio'}>
              <TrendingUpIcon />
              <span>Portfolio</span>
            </a>
          )}
        </div>
      </div>
    </>
  );
};

export default SideBar;
