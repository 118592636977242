import React, { useEffect, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = {
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
  };

type DropMultiSelectType = {
  title: string,
  items: Array<string>,
  width: number,
  outputSelections: (selections: Array<string>, type: string) => void,
  filterColumns: boolean,
  type: string,
  selection?: Array<string>,
  selectAll?: boolean
}

const DropMultiSelect = (props: DropMultiSelectType) => {

  const { title, items, width, outputSelections, filterColumns, type, selectAll, selection } = props;

  const [dropItems, setDropItems] = useState([]);
  const isAllSelected = items.length > 0 && dropItems.length === items.length;

  useEffect(() => {
    if (selection !== undefined && selection[0] !== -1) {
      setDropItems(selection)
    }
	}, [selection])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    let filter = value;
    if (value[value.length - 1] === "all") {
      setDropItems(dropItems.length === items.length ? [] : items);
      
      if (dropItems.length === items.length) {
        filter = [-1];
      } else {
        filter = selectAll !== 'undefined' && !selectAll ? items : [];
      }
    } else {
      setDropItems(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );

      if (value.length === 0) {
        filter = [-1]
      }
    }

    if (filterColumns === true) {
      setTimeout(() => {
        outputSelections(filter, type)
        // gotta clear supersearch too
      }, 200)
    }
  };

  useEffect(() => {
    if (selectAll === undefined || selectAll === true) {
      setDropItems(items)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  return (
    <div>
      <FormControl sx={{ m: 3, width: width, margin: 0 }}>
        <InputLabel id="demo-multiple-checkbox-label">{title}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={dropItems}
          onChange={handleChange}
          input={<OutlinedInput label={title} />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          style={{ 
            height: "64px",
            textAlign: "center",
          }}
        >
          <MenuItem
            value="all"
            classes={{ root: isAllSelected ? useStyles.selectedAll : "" }}
          >
            <Checkbox
              classes={{ indeterminate: useStyles.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                  dropItems.length > 0 && dropItems.length < items.length
              }
            />
            <ListItemText
              classes={{ primary: useStyles.selectAllText }}
              primary="Select All"
            />
          </MenuItem>
          {items.length > 0 && items.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={dropItems?.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default DropMultiSelect;
