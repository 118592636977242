import { useState } from 'react';
import DropSelectWppOpen from '../../../components/DropSelectWppOpen';
import DropSelectWppOpenByID from '../../../components/DropSelectWppOpenByID';
import { WppTab, WppTabs } from '@wppopen/components-library-react';
import Piechart from './Piechart';
import BarchartRetailerScore from './BarchartRetailerScore';
import LineTrends from './LineTrends';

const FlowVizRetailerSpecific = (props) => {

  const {
    selectedSegmentName,
    retailers,
    selectedRetailer,
    setSelectedRetailer,
    piechartData,
    categories,
    selectedCategory,
    setSelectedCategory,
    barchartRetailerData,
   } = props;

   const [trendsTab, setTrendsTab] = useState('retailer');
   const [selectedTrendRetailers, setSelectedTrendRetailers] = useState([]);

   const handleTrendsTabChange = (event) => {
    setTrendsTab(event.detail.value);
  };

  return (
    <>
      <div className={'flex mt-12'}>
        <DropSelectWppOpenByID
          type="single"
          title="Choose Retailer"
          className="w-60 text-[14px]"
          items={retailers.sort((a,b) => (a.partner > b.partner) ? 1 : ((b.partner > a.partner) ? -1 : 0))}
          id="partnerId"
          value="partner"
          selection={selectedRetailer}
          setSelection={setSelectedRetailer}
          withSearch={true}
        />
      </div>
      <div className={'flex w-full mt-6 min-h-24 bg-white shadow-[0_0_2px_0_rgba(0,0,0,0.1)] rounded-[8px] p-8 flex-col'}>
        <div className="flex w-full pb-8 border-b-[1px]">
          <div className="w-5/12">
            <div className={'text-[24px] text-gray-900 font-bold'}>{selectedSegmentName} Evaluation Weighting</div>
            <div className={'text-sm text-gray-900 mt-1'}>Visualizes the weighting of evaluated channels and categories for your selected segment, giving you a greater understanding of the evaluation process</div>
            <div className={'w-full h-96 mt-8 flex justify-center'}>
              {!!piechartData?.length
                ? <Piechart data={piechartData} />
                : <div className="mt-20">No data to display</div>
              }
            </div>
          </div>
          <div className="w-7/12">
            <div className={'text-[24px] text-gray-900 font-bold ml-6'}>Detailed Evaluation Score Bar Chart</div>
            <div className={'text-sm text-gray-900 mt-1 ml-6'}>Provides a detailed breakdown of the retailer score and benchmark on evaluated channels and categories, giving you a comprehensive understanding of their performance</div>
            <div className={'flex w-full mt-2 ml-6'}>
              <DropSelectWppOpen
                type="single"
                title="Category"
                className="w-60 text-[14px]"
                items={categories}
                selection={selectedCategory}
                setSelection={setSelectedCategory}
              />
            </div>
            <div className={'w-full h-80 mt-8'}>
              {!!barchartRetailerData?.data?.length
                ? <BarchartRetailerScore data={barchartRetailerData} />
                : <div className="ml-48">No data to display</div>
              }
            </div>
          </div>
        </div>
        <div className={'text-[24px] text-gray-900 mt-8 font-bold'}>Trended Retailer Maturity</div>
        <div className={'text-sm text-gray-900 mt-1'}>Tracks the total maturity score trended over time, providing insights into how the retailer is evolving and improving</div>
        <div className="w-64 mt-6">
          <WppTabs value={trendsTab} onWppChange={handleTrendsTabChange}>
            <WppTab value='retailer' className="w-32">Retailer</WppTab>
            <WppTab value='segment' className="w-32">Segment</WppTab>
          </WppTabs>
        </div>
        <div className="w-full opacity-30">
          {
            {
              retailer: (
                <>
                  <div className={'flex w-full mt-6'}>
                    <DropSelectWppOpenByID
                      type="multiple"
                      title="Compare Retailers"
                      className="w-60 text-[14px]"
                      items={retailers}
                      id="partnerId"
                      value="partner"
                      selection={selectedTrendRetailers}
                      setSelection={setSelectedTrendRetailers}
                    />
                  </div>
                  <div className={'w-full border-b-[1px] mt-4 flex justify-center'}>
                    <div className="w-3/4 h-96">
                      <LineTrends />
                    </div>
                  </div>
                </>
              ),
              segment: (
                <>
                </>
              ),
            }[trendsTab]
          }
        </div>
      </div>
    </>
  )
}
export default FlowVizRetailerSpecific;