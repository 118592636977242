/* eslint-disable react/jsx-pascal-case */
import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useOktaAuth } from "@okta/okta-react";
import useAuthUser from "../../components/AuthUser";
import { FormProvider, useForm } from "react-hook-form";
import InsightName from './Steps/InsightName';
import SelectFlow from './Steps/SelectFlow';
import Segments from './Steps/Segments';
import { WppButton, WppStepper, WppStep } from '@wppopen/components-library-react'
import { InsightValidationSchema } from './Utils/validation';
import { yupResolver } from "@hookform/resolvers/yup";
import { INSIGHT_CREATION_STEPS } from './Utils/constants';
import SnackbarComponent from '../../components/Snackbar'
import { ModalSpinner } from "../../components/ModalSpinner";
import '../../stylesheets/InsightStepper.css';

const InsightStepper = (props) => {

  const { projectId, insightIdDB, setInsightName, selectedFlowId, setSelectedFlowId } = props;

  const baseApi = process.env.REACT_APP_API_ENDPOINT;
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getIdToken();
  const userInfo = useAuthUser();

  const [insightId, setInsightId] = useState(insightIdDB);
  
  const [flowData, setFlowData] = useState([]);
  const [activeStep, setActiveStep] = useState(1);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [isSpinnerSpinning, setIsSpinnerSpinning] = useState(false);
  const [rerender, setRerender] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);

  const tabTotal100 = useRef({});

  const currentValidationSchema = InsightValidationSchema()[activeStep - 1];

  const methods = useForm({
    shouldUnregister: false,
    resolver: yupResolver(currentValidationSchema),
    mode: "onChange"
  });
  const { handleSubmit, trigger, getValues, setValue } = methods;

  const transform_from_API = (segments) => {
    const segmentsCopy = _.cloneDeep(segments);
    const newSegments = segmentsCopy.map(segment => {
      // FROM API: [{entityId: 15086, attributeId: 12, Audio: '20.00'}, ...]
      // TO RHF: {12-15086: '20.00', 12-15087: '20.00', ...}
      const channels = {};
      _.forEach(segment.channels, channel => {
        const items = Object.values(channel);
        channels[`${items[1]}-${items[0]}`] = items[2];
      })
      segment.channels = channels;
      return segment;
    });
    return newSegments;
  }


  useEffect(() => {
    if (insightIdDB) {
      setIsSpinnerSpinning(true);
      fetch(`${baseApi}/segment/insight/${insightIdDB}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status}`);
        }
        return response.json()
      })
      .then(data => {
        setValue('InsightName', data?.data.name);
        setValue('FlowSetId', data?.data.flowSetId);
        setValue('SegmentChannels', transform_from_API(data.data.segments));
        setSelectedFlowId(data?.data.flowSetId);
        setInsightName(data?.data.name);
        setActiveStep(3);
      })
      .catch((error) => {
        console.log('API FETCH ERROR: ', error.message);
        setIsSpinnerSpinning(false);
      })
    }
    // eslint-disable-next-line
  }, [insightIdDB])

  useEffect (() => {
    setIsSpinnerSpinning(true);
    fetch(`${baseApi}/flow/fetch_flows`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.status}`);
      }
      return response.json()
    })
    .then(data => {
      if (data?.data) {
        setFlowData(data.data.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0)));
        setIsSpinnerSpinning(false);
      }
    })
    .catch((error) => {
      console.log('API FETCH ERROR: ', error);
      setIsSpinnerSpinning(false);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transform_to_API = (segments) => {
    const newSegments = segments.map(segment => {
      const channels = [];
      // FROM RHF: {12-15086: '20.00', 12-15087: '20.00', ...}
      // TO API: [{entityId: 15086, attributeId: 12, Audio: '20.00'}, ...]
      _.forEach(segment.channels, (value, key) => {
        const attributeId = parseInt(key.split('-')[0]);
        const entityId = parseInt(key.split('-')[1]);
        const valueFloat = parseFloat(value);
        channels.push({ entityId, attributeId, weight: valueFloat })
      })
      segment.channels = channels;
      return segment;
    });

    return newSegments;
  }

  const onSubmit = (data) => {

    setIsSpinnerSpinning(true);
    if (Object.values(tabTotal100.current).includes(false)) {
      setIsSpinnerSpinning(false);
      setSnackMessage("Please fix the existing errors before saving.");
      setSnackOpen(true);
      return;
    }

    const RHFValues = getValues();
    const payload = {
      insightId: insightId,
      insightName: RHFValues.InsightName,
      flowSetId: RHFValues.FlowSetId,
      segments: transform_to_API(_.cloneDeep(RHFValues.SegmentChannels)),
    }

    fetch(`${baseApi}/segment/${insightIdDB ? 'update' : 'create'}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
      body: JSON.stringify(payload)
    })
      .then(response => response.json())
      .then(data => {
        if (data) {
          setIsSpinnerSpinning(false);
          setSnackMessage('Insight successfully updated');
          setSnackOpen(true);
        }
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setSnackOpen(true);
      });
  };

  const handleNext = async (activeStep) => {
    
    const isStepValid = await trigger();
    if (activeStep === 1) {
      if (isStepValid) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 2) {
      
      if (!insightId) {
        const RHFValues = getValues();
        if (!RHFValues.FlowSetId) return;

        setIsSpinnerSpinning(true);
        
        const payload = {
          name: RHFValues.InsightName,
          projectId: projectId,
          flowSetId: RHFValues.FlowSetId,
          createdBy: {
            emailAddress: userInfo?.email,
            name: userInfo?.name
          }
        }
        
        fetch(`${baseApi}/insights/v2/create`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
          body: JSON.stringify(payload),
        })
          .then(response => {
            if (!response.ok) {
              throw new Error("HTTP status " + response.status);
            }
            return response.json()
          })
          .then(data => {
            setIsSpinnerSpinning(false);
            setInsightId(data.insightId);

            if (isStepValid) {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
          })
          .catch(error => {
            setIsSpinnerSpinning(false);
            setSnackMessage(error.message === 'HTTP status 409' ? `Insight "${payload.name}" already exists` : error.message);
            setSnackOpen(true);
            console.error('Error:', error)
          });
      } else {
        if (isStepValid) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
    }
  };

  useEffect(() => {
    if (Object.values(tabTotal100.current).includes(false)) {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  }, [rerender])

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 1:
        return <InsightName insightId={insightId} />;
      case 2:
        return <SelectFlow insightId={insightId} flowData={flowData} selectedFlowId={selectedFlowId} setSelectedFlowId={setSelectedFlowId} />;
      case 3:
        return <Segments
                insightId={insightId}
                flowSetId={selectedFlowId}
                baseApi={baseApi}
                accessToken={accessToken}
                setIsSpinnerSpinning={setIsSpinnerSpinning}
                tabTotal100={tabTotal100}
                rerender={rerender}
                setRerender={setRerender}
              />;
      default:
        return null;
    }
  };

  return (
    <>
      <div style={{ color: "#4D5358" }} className=" px-16 text-2xl font-semibold mb-10">Create New Insight</div>
      <div className="w-full px-16">
        <div style={{ display: 'flex' }}>
          <div className="min-w-[250px] pt-[6px]">
            <WppStepper activeStep={activeStep}>
              {INSIGHT_CREATION_STEPS.map((label, index) => (
                <WppStep key={index}>
                  <p slot="label" className="w-56 text-sm font-medium">
                    {label}
                  </p>
                </WppStep>
              ))}
            </WppStepper>
          </div>

          <div className="w-full ml-4">
            {isSpinnerSpinning ? (
              <ModalSpinner/>
            ) : null}

            <FormProvider {...methods}>
              <form>
                {getStepContent()}
                <div className="mt-6 w-auto flex justify-end">
                  <WppButton
                    variant={'secondary'}
                    color="inherit"
                      disabled={activeStep === 1}
                    onClick={handleBack}
                    style={{ marginRight: '8px' }}
                  >
                    Back
                  </WppButton>
                  {activeStep === INSIGHT_CREATION_STEPS.length
                    ? (
                      <WppButton onClick={handleSubmit(onSubmit)} sx={{ mr: 1 }} disabled={saveDisabled}>
                        Save
                      </WppButton>
                    ) : (
                      <WppButton onClick={() => handleNext(activeStep)} sx={{ mr: 1 }}>
                        Next
                      </WppButton>
                    )
                  }
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
        <SnackbarComponent
          snackOpen={snackOpen}
          setSnackOpen={setSnackOpen}
          message={snackMessage}
        />
      </div>
    </>
  );
}

export default InsightStepper;