import React, { memo, useEffect, useState } from 'react';
import NoData from './NoData';
import dayjs from 'dayjs';
import { WppSelect, WppListItem } from '@wppopen/components-library-react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { hackGrid, SortedDescendingIcon, SortedAscendingIcon, UnsortedIcon} from '../../utils'
import './styles/CompetitiveLandscape.css';

const columnDefs: GridColDef[] = [
  { field: 'Online Store', width: 200 },
  { field: 'Net Sales', width: 170,
    renderCell: (params) => (`${new Intl.NumberFormat().format(params.value)}`)
  },
  { field: 'Market Share', width: 180, align: 'right', hideSortIcons: true, sortable: false,
    renderHeader: (params) => (
      <div style={{ fontWeight: '800', marginLeft: 'auto', marginRight: '0' }}>
        {'Market Share '}
      </div>
    ),
  },
];

const CompetitiveLandscape = (props) => {

  const { CompetitiveLandscapeData } = props;

  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [rows, setRows] = useState([]);
  

  useEffect(() => {
    hackGrid();
  })

  
  useEffect(() => {
    if (CompetitiveLandscapeData) {
      setYears([...new Set(CompetitiveLandscapeData.map(item => item.year))]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (years.length) {
      setSelectedYear(Math.max(...years));
    } else {
      setSelectedYear(dayjs().year());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [years]);

  useEffect(() => {
    if (CompetitiveLandscapeData) {
      const selectedData = CompetitiveLandscapeData.filter((a) => a.year === selectedYear);

      if (selectedData.length) {
        setRows(selectedData);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear])

  const handleChange = (event) => {
    setSelectedYear(event.detail.value);
  }

  return (
    <div style={{ width: '100%', height: '100%'}}>
      <div className={'competitive-landscape-header'}>
        <div>
          <div className='h2' style={{marginRight: '16px'}}>
            Competitive Landscape
          </div>
          <div className='h5' style={{ marginTop: '4px', marginRight: '64px' }}>
            Top online stores: Food & Personal Care in France
          </div>
        </div>
        <div className='dropdown-wrap'>
          <div className={'select-container'}>
            <WppSelect
              onWppChange={handleChange}
              placeholder={selectedYear}
              value={selectedYear}
            >
              {
                years.map(year => (
                  <WppListItem value={year} key={year}>
                    <p slot="label">{year}</p>
                  </WppListItem>
                ))
              }
            </WppSelect>
          </div>
        </div>
      </div>
      {
        rows?.length
        ?
          <div className='data-grid-container'>
            <DataGridPro
              getRowId={(row) => row.year.toString() + row["Online Store"]}
              columns={columnDefs}
              rows={rows}
              rowHeight={50}
              disableColumnMenu={true}
              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  textTransform: 'none',
                  fontWeight: '800',
                },
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-iconButtonContainer': {
                  marginLeft: '-70px',
                  visibility: 'visible !important',
                  width: 'auto !important',
                },
                '& .MuiDataGrid-columnHeaderTitleContainer': {
                  margin: '0'
                },
              }}
              slots={{
                columnSortedDescendingIcon: SortedDescendingIcon,
                columnSortedAscendingIcon: SortedAscendingIcon,
                columnUnsortedIcon: UnsortedIcon,
              }}
              hideFooter={true}
            />
          </div>
        : <NoData />
      }
    </div>
  )
}

export default memo(CompetitiveLandscape);