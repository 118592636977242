import React, { useEffect } from 'react';
import { useFormContext, Controller } from "react-hook-form"
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { WppInput } from '@wppopen/components-library-react'
import { WppSelect, WppListItem } from '@wppopen/components-library-react';
import { Styles } from './styles'
import { RFI_OPTIONS } from '../../../Utils/constants'
import "../temp_styles.css";

const SelectRFITypeStyle = Styles.SelectRFIType;
const FormMain = Styles.FormMain;

const SelectRFIType = (props) => {

  const { rfiId, rfiTitle, rfiCardsData, isExistingRFI, setIsExistingRFI, rfiCreated, setClonedRfiId } = props;

  const RFIs = rfiCardsData?.map((rfi) => (rfi));

  const { control, formState, reset } = useFormContext()
  const { errors } = formState;

  useEffect(() => {
    reset({ newRFIName: rfiTitle })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfiTitle])

  const handleChange = (event) => {
    setIsExistingRFI(event.target.value === RFI_OPTIONS.EXISTING_RFI.value ? true : false);
  }

  return (
    <div style={FormMain}>
      {
        rfiId 
        ?
          null
        :
        <>
          <div style={SelectRFITypeStyle.title}>
            Please Select
          </div>
          <div style={SelectRFITypeStyle.radio}>
            <Controller
              control={control}
              name="RFIType"
              defaultValue={rfiId ? RFI_OPTIONS.NEW_RFI.value : RFI_OPTIONS.EXISTING_RFI.value}
              render={({ field: { onChange, onBlur, value } }) => (
                <RadioGroup
                  row
                  name="controlled-radio-buttons-group"
                  value={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    handleChange(e);
                    onChange(e);
                  }}
                >
                  <FormControlLabel disabled={rfiCreated} value={RFI_OPTIONS.NEW_RFI.value} control={<Radio />} label={RFI_OPTIONS.NEW_RFI.label} />
                  <FormControlLabel disabled={rfiCreated} value={RFI_OPTIONS.EXISTING_RFI.value} control={<Radio />} label={RFI_OPTIONS.EXISTING_RFI.label} sx={SelectRFITypeStyle.radioWidth} />
                </RadioGroup>
              )}
            />
          </div>
        </>
      }
      {isExistingRFI && !rfiId ?
        <table style={SelectRFITypeStyle.table}>
          <tbody>
            <tr>
              <td style={SelectRFITypeStyle.newRFIName}>
                <Controller
                  control={control}
                  name="RFIExisting"
                  render={({ field: { onChange, onBlur, value, error } }) => (
                    <WppSelect
                      onWppChange={(e) => {
                        isExistingRFI ? setClonedRfiId(e.target.value.rfiId) : setClonedRfiId(null);
                        onChange(e);
                      }}
                      placeholder="Select"
                      required
                      labelConfig={{ text: 'Existing RFI' }}
                      value={value}
                      onBlur={onBlur}
                      disabled={rfiCreated}
                    >
                      {RFIs?.map(rfi => (
                        <WppListItem value={rfi} key={rfi.rfiId}>
                          <p className="text-[16px]" slot="label">{rfi.title}</p>
                        </WppListItem>
                      ))}
                    </WppSelect>
                  )}
                />
                <div style={SelectRFITypeStyle.errorFeedback}>{errors.RFIExisting?.message}</div>
              </td>
              <td style={SelectRFITypeStyle.spacer} />
              <td style={SelectRFITypeStyle.newRFIName}>
                <Controller
                  control={control}
                  name="clonedRFIName"
                  render={({ field: { onChange, onBlur, value, error } }) => (
                    <WppInput
                      required={true}
                      placeholder="Enter New RFI Name"
                      labelConfig={{ text: 'New RFI' }}
                      onWppChange={onChange}
                      value={value}
                      onWppBlur={onBlur}
                      disabled={rfiCreated}
                    />
                  )}
                />
                <div style={SelectRFITypeStyle.errorFeedback}>{errors.clonedRFIName?.message}</div>
              </td>
            </tr>
          </tbody>
        </table>
        :
        <>
          <div style={SelectRFITypeStyle.newRFIName}>
            <Controller
              control={control}
              name="newRFIName"
              render={({ field: { onChange, onBlur, value, error } }) => (
                <WppInput
                  labelConfig={{ text: 'RFI Name' }}
                  required={true}
                  placeholder=""
                  message={errors?.FlowName?.message}
                  messageType="error"
                  onWppChange={onChange}
                  value={value}
                  onWppBlur={onBlur}
                  disabled={rfiCreated}
                />
              )}
            />
          </div>
          <div style={SelectRFITypeStyle.errorFeedback}>{errors.newRFIName?.message}</div>
        </>
      }
    </div>
  )
}
export default SelectRFIType;