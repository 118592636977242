/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useFormContext, Controller, useFieldArray } from "react-hook-form"
import { WppInput, WppActionButton, WppIconAddCircle, WppDatepicker, WppFileUpload } from '@wppopen/components-library-react'
import FlowName_Admin from './FlowName_Admin';
import Image from '../../../../assets/Image';
import X from '../../../../assets/X';
import { fileDownloader } from '../../../../utils';
import { Styles } from './styles'
import { NUMBER_DATE_FORMAT, WPPOPEN_LOCALE_DATE_FORMAT } from '../../../Utils/constants';

const CALENDAR_TYPE = {
  START_DATE: 'start_date',
  END_DATE: 'end_date',
};

const FlowName = (props) => {
  const { flowName, flowAdmins, accessWindow, uploadedFile, setUploadedFile, hasAttachment, setHasAttachment, filePath, accessToken } = props;

  const baseApi = process.env.REACT_APP_API_ENDPOINT;

  const [startDateDisabled, setStartDateDisabled] = useState(false);
  const [startDate, setStartDate] = useState();

  const { control, formState, setValue, getValues } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'FlowAdmins',
    shouldUnregister: false,
  });

  const { errors } = formState;

  const reformatDateDB = (date) => {
    return `${date.substring(5, 7)}/${date.substring(8, 10)}/${date.substring(0, 4)}`;
  }

  const welcomeEmailAttachmentExtension = filePath ? filePath.split('.').pop() : null;

  useEffect(() => {
    if (accessWindow?.length) {
      setStartDateDisabled(true)
    }
    if (filePath) {
      setHasAttachment(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (flowName && !getValues()?.FlowName)
      setValue('FlowName', flowName, { shouldValidate: true })
    if (flowAdmins && getValues()?.FlowAdmins?.length === 0)
      setValue('FlowAdmins', flowAdmins, { shouldValidate: true })
    if (accessWindow?.length && !getValues()?.accessWindow) {
      setValue('accessWindowStartDate', reformatDateDB(accessWindow[0]))
      setValue('accessWindowEndDate', reformatDateDB(accessWindow[1]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowName, flowAdmins])

  const calendarDateCheck = (type, date, onChange) => {
    if (type === CALENDAR_TYPE.START_DATE) {
      onChange(date);
      setStartDate(date);
      if (!getValues()?.accessWindowEndDate || dayjs(date).isAfter(dayjs(getValues()?.accessWindowEndDate), 'day')) {
        setValue('accessWindowEndDate', date);
      }
    }
  }

  const FlowName = Styles.FlowName;
  const FLOW_ADMINS = Styles.FlowName.FLOW_ADMINS;

  const columns = ['Name', 'Email Address', 'Admin Role'];

  const imageChange = (e) => {
    const image = e.detail.value[0];
    if (image) {
      setUploadedFile(image);
    } else {
      setUploadedFile(null);
    }
  };

  const handleDownload = (e) => {
    e.preventDefault();

    fetch(`${baseApi}/storage/download`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        filePath: filePath,
      }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.status}`);
      }
      return response.json();
    })
    .then((res) => {
      fileDownloader(res.data, `WelcomeEmailAttachment.${welcomeEmailAttachmentExtension}`);
    })
    .catch((error) => {
      console.log('API FETCH ERROR: ', error.message);
    })
  }

  return (
    <>
      <div style={FlowName.flowName}>
        <Controller
          control={control}
          name="FlowName"
          render={({ field: { onChange, onBlur, value, error } }) => (
            <WppInput
              labelConfig={{ text: 'Flow Name' }}
              required={true}
              placeholder=""
              message={formState.errors?.FlowName?.message}
              messageType="error"
              onWppChange={onChange}
              value={value}
              onWppBlur={onBlur}
            />
          )}
        />
      </div>
      <div style={FlowName.flowName}>
        <WppActionButton
          onClick={() => append({ name: '', email: '', role: null })}
        >
          Additional Flow Admins
          <WppIconAddCircle slot='icon-end' variant="inverted" />
        </WppActionButton>
      </div>

      {!!fields?.length && (
        <table style={FLOW_ADMINS.table}>
          <tbody>
            <tr style={FLOW_ADMINS.headerRow}>
              {
                columns.map((item, index) => (
                  <React.Fragment key={index}>
                    <td>{item}</td>
                    <td style={FLOW_ADMINS.spacer} />
                  </React.Fragment>
                ))
              }
              <td />
            </tr>
            {fields.map((item, index) => (
              <FlowName_Admin
                Controller={Controller}
                control={control}
                item={item}
                index={index}
                key={index}
                remove={remove}
                errors={errors}
              />
            ))}
          </tbody>
        </table>
      )}
      <div style={FLOW_ADMINS.zeroCountFeedback}>{errors.FlowAdmins?.message}</div>

      <div className="ml-[180px] text-sm font-medium">Access Window</div>
      <div className="ml-[180px] mt-2 flex">  
        <Controller
          control={control}
          name="accessWindowStartDate"
          render={({ field: { onChange, onBlur, value, error } }) => (
            <WppDatepicker
              labelConfig={{ text: 'Start Date' }}
              required
              value={value || ''}
              placeholder={NUMBER_DATE_FORMAT}
              message={formState.errors?.accessWindowStartDate?.message}
              messageType="error"
              onWppChange={(e) => calendarDateCheck(CALENDAR_TYPE.START_DATE, e.detail.formattedDate, onChange)}
              minDate={accessWindow?.length ? reformatDateDB(accessWindow[0]) : dayjs().format(NUMBER_DATE_FORMAT)}
              locale={{ dateFormat: WPPOPEN_LOCALE_DATE_FORMAT }}
              onWppBlur={onBlur}
              disabled={startDateDisabled}
              onWppDateClear={() => { setValue('accessWindowStartDate', null) }}
            />
          )}
        />
        <Controller
          control={control}
          name="accessWindowEndDate"
          render={({ field: { onChange, onBlur, value, error } }) => (
            <WppDatepicker
              className="ml-4"
              labelConfig={{ text: 'End Date' }}
              required
              value={value || ''}
              placeholder={NUMBER_DATE_FORMAT}
              message={formState.errors?.accessWindowEndDate?.message}
              messageType="error"
              onWppChange={(e) => onChange(e.detail.formattedDate)}
              minDate={startDate || dayjs().format(NUMBER_DATE_FORMAT)}
              locale={{ dateFormat: WPPOPEN_LOCALE_DATE_FORMAT }}
              onWppBlur={onBlur}
              onWppDateClear={() => { setValue('accessWindowEndDate', null) }}
            />
          )}
        />
      </div>

      <div className="ml-44 mt-12 text-sm font-medium">{hasAttachment ? 'Welcome Email Attachment' : 'Add attachment to Welcome Email'}</div>
      {!hasAttachment
      ?
        <div className="ml-44 mt-2 flex flex-col w-[340px]">
          {!uploadedFile 
          ? 
            <WppFileUpload
              acceptConfig={{
                'image/png': ['.png'],
                'text/html': ['.htm', '.html'],
                'application/pdf': ['.pdf'],
                'application/vnd.ms-excel': ['.xls'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                'application/msword': ['.doc'],
              }}
              size={5}
              multiple={false}
              onWppChange={e => imageChange(e)}
              className="w-full"
            />
          :
            <div className="flex justify-between mt-2 items-center bg-gray-100 p-1">
              <div className="flex items-center">
                <Image />
                <span className="pl-2">{uploadedFile.name}</span>
              </div>
              <button onClick={() => setUploadedFile(null)}><X /></button>
            </div>
          }
        </div>
      :
        <div className="ml-44 w-[340px] flex justify-between mt-2 items-center bg-gray-100 p-1">
          <div className="flex items-center">
            <Image />
            <button className="ml-2" onClick={(e) => handleDownload(e)}>{`WelcomeEmailAttachment.${welcomeEmailAttachmentExtension}`}</button>
          </div>
          <button onClick={() => {
            setHasAttachment(false);
            setUploadedFile(null);
          }}>
            <X />
          </button>
        </div>
      }
    </>
  )
}
export default FlowName;