import React from 'react';
import { ResponsiveBar } from '@nivo/bar'

const COLOR_OPTIONS = { category: '#85A3FF', subcategory: '#ED78C6' };

const MyResponsiveBar = ({ data }) => {
  
  const benchmarkLine = (props) => {
    const { bars, xScale } = props;
    return (
      <>
        {bars.map((bar) => {
        return (
          <line
            key={bar.key}
            x1={xScale(bar.data.data.benchmark)}
            y1={bar.absY}
            x2={xScale(bar.data.data.benchmark)}
            y2={bar.absY + bar.height}
            stroke="#555555"
            strokeWidth="2"
          />
        )}
      )}
      </>
    );
  };

  const legend = ({ innerWidth, innerHeight }) => {
    return (
      <g>
        <line 
          x1={innerWidth - 83}
          y1={innerHeight + 40}
          x2={innerWidth - 83}
          y2={innerHeight + 60} 
          stroke="#555555" strokeWidth="2"
        />
        <text
          className="text-s font-normal"
          transform={`translate(${innerWidth - 70}, ${innerHeight + 57})`}
        >
          Benchmark
        </text>
      </g>
    )
  };

  return (
    <ResponsiveBar
      data={data.data}
      keys={["val"]}
      indexBy="id"
      layout="horizontal"
      margin={{ top: 0, right: 100, bottom: 70, left: 165 }}
      innerPadding={6}
      indexScale={{ type: 'band', round: true }}
      colors={a => COLOR_OPTIONS[data.type]}
      valueScale={{ 
        type: 'linear',
        min: 0,
        max: 5,
        clamp: true
      }}
      borderColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            1.6
          ]
        ]
      }}
      borderRadius={3}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: [0,1,2,3,4,5],
        legend: '',
        legendPosition: 'middle',
        legendOffset: 32,
        truncateTickAt: 0
      }}
      axisLeft={true}
      enableGridX={false}
      enableGridY={false}
      enableLabel={false}
      layers={["grid", "axes", "bars", benchmarkLine, legend]}
    />
  )
}
export default MyResponsiveBar;