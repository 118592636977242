const Quadrants = ({ x, y, width, height }) => {

  if (!x || !y) return;

  return (
    <g>
      <rect
        x={0}
        width={x < 0 ? 0 : x}
        height={y < 0 ? 0 : y}
        fill="#F8F1A0"
        opacity=".3"
      />
      <rect
        x={x}
        width={width - x < 0 ? 0 : width - x}
        height={y < 0 ? 0 : y}
        fill="#A2F1DB"
        opacity=".3"
      />
      <rect
        x={0}
        y={y}
        width={x < 0 ? 0 : x}
        height={height - y < 0 ? 0 : height - y}
        fill="#FFBDC8"
        opacity=".3"
      />
      <rect
        x={x}
        y={y}
        width={width - x < 0 ? 0 : width - x}
        height={height - y < 0 ? 0 : height - y}
        fill="#DBC3F9"
        opacity=".3"
      />
    </g>
  )
}
export default Quadrants;