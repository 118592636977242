import React, { useEffect, useState, useRef } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemText from "@mui/material/ListItemText";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import HistoryIcon from '@mui/icons-material/History';

const MenuGrid = (props) => {

  const { handleDialogOpen, setTimeMachineVisible, isAdmin } = props;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div style={{margin: '4px 14px 0 0', zIndex: 10000}}>
      <MoreVertIcon
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        style={{cursor: 'pointer', opacity: .8}}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={(e) => {handleClose(e); handleDialogOpen('add');}}>
                    <AddCircleOutlineIcon/>
                    <ListItemText style={{margin: '0 0 0 6px'}}>Add Retailer</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={(e) => {handleClose(e); handleDialogOpen('edit');}}>
                    <EditRoundedIcon/>
                    <ListItemText style={{margin: '0 0 0 6px'}}>Edit Market</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={(e) => {handleClose(e); handleDialogOpen('delete');}}>
                    <RemoveCircleOutlineIcon/>
                    <ListItemText style={{margin: '0 0 0 6px'}}>Remove Retailer</ListItemText>
                  </MenuItem>
                  {
                    isAdmin &&
                    <MenuItem onClick={(e) => {handleClose(e); setTimeMachineVisible(true);}}>
                      <HistoryIcon/>
                      <ListItemText style={{margin: '0 0 0 6px'}}>Time Machine</ListItemText>
                    </MenuItem>
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
export default MenuGrid;