import { WppSelect, WppListItem } from "@wppopen/components-library-react";

export const DropSelectWppOpenByID = (props) => {
  const {type, title, items, id, value, selection, setSelection, maxSelection, withFolder = true, className} = props;

  return (
    <WppSelect
      placeholder={selection?.length ? selection : 'Choose options'}
      type={type}
      labelConfig={{ text: title }}
      value={selection}
      onWppChange={e => {
        if (maxSelection && selection.length >= maxSelection) {
          e.detail.value.splice(5);
        }
        setSelection(e.detail.value)
      }}
      withSearch={true}
      withFolder={withFolder}
      className={className}
      required
    >
      {items?.length > 0 && items.map((item, index) => {
        return (
          <WppListItem
            key={index}
            value={item[id]}
            disabled={maxSelection && selection.length >= maxSelection && !selection.includes(item[id])}
          >
            <p className={className} slot="label">{item[value]}</p>
          </WppListItem>
        )
      })}
    </WppSelect>
  )
}
export default DropSelectWppOpenByID;