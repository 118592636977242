export const Styles = {
  SelectRFI: {
    message: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#FDEAE2',
      width: '550px',
      margin: '60px 0 0 180px',
    },
    dropdownHeading: {
      margin: '45px 0 0 180px',
    },
    dropdown: {
      width: '350px',
      margin: '5px 0 0 180px',
      borderColor: '#EBEAEB',
    },
  },
  FlowName: {
    flowName: {
      width: '500px',
      margin: '45px 0 0 180px',
      borderColor: '#EBEAEB',
    },
    message: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#FDEAE2',
      width: '550px',
      margin: '60px 0 0 180px',
    },
    dropdown: {
      width: '350px',
      margin: '5px 0 0 180px',
    },
    FLOW_ADMINS: {
      table: {
        margin: '5px 0 0 180px',
        borderCollapse: 'collapse',
      },
      headerRow: {
        borderBottom: '1px solid #C1C7CD',
        height: '40px',
        marginBottom: '20px',
      },
      row: {
        borderBottom: '1px solid #E7EAEE',
        height: '70px',
      },
      spacer: {
        width: '12px',
      },
      name: {
        verticalAlign: 'top',
        paddingTop: '14px',
        width: '250px',
      },
      email: {
        verticalAlign: 'top',
        paddingTop: '14px',
        width: '350px',
      },
      role: {
        verticalAlign: 'top',
        paddingTop: '14px',
        width: '250px',
      },
      delete: {
        cursor: 'pointer',
        backgroundColor: 'white',
        border: '0',
      },
      invalidFeedback: {
        fontSize: '12px',
        color: 'red',
      },
      zeroCountFeedback: {
        margin: '30px 0 0 185px',
        fontSize: '14px',
        color: 'red',
      },
    },
  },
  SelectPartners: {
    addPartner: {
      width: '500px',
      margin: '45px 0 0 180px',
      borderColor: '#EBEAEB',
    },
    PARTNER_ADMINS: {
      table: {
        margin: '50px 0 0 100px',
        borderCollapse: 'collapse',
      },
      headerRow: {
        borderBottom: '1px solid #C1C7CD',
        height: '40px',
        marginBottom: '20px',
      },
      row: {
        borderBottom: '1px solid #E7EAEE',
        height: '70px',
      },
      spacer: {
        width: '12px',
      },
      partner: {
        borderColor: '#EBEAEB',
        verticalAlign: 'top',
        paddingTop: '14px',
        width: '350px',
      },
      responder: {
        borderColor: '#EBEAEB',
        verticalAlign: 'top',
        paddingTop: '14px',
        width: '250px',
      },
      email: {
        borderColor: '#EBEAEB',
        verticalAlign: 'top',
        paddingTop: '16px',
        width: '600px',
      },
      delete: {
        cursor: 'pointer',
        backgroundColor: 'white',
        border: '0',
      },
      invalidFeedback: {
        fontSize: '12px',
        color: 'red',
      },
      zeroCountFeedback: {
        margin: '20px 0 0 185px',
        fontSize: '14px',
        color: 'red',
      },
    },
  },
  ReviewInformation: {
    RFIHeader: {
      width: '500px',
      margin: '80px 0 0 180px',
      fontWeight: 600,
      fontSize: '18px',
      color: '#404252',
    },
    RFI: {
      margin: '7px 0 0 180px',
      fontWeight: 400,
    },
    table: {
      margin: '0 0 0 180px',
      borderCollapse: 'collapse',
    },
    headerRow: {
      height: '100px',
      fontWeight: 600,
      fontSize: '18px',
      color: '#404252',
      verticalAlign: 'bottom',
    },
    col1: {
      width: '300px',
      paddingBottom: '5px',
    },
    col2: {
      width: '350px',
      paddingBottom: '5px',
    },
    col3: {
      width: '350px',
      paddingBottom: '5px',
    },
    row: {
      height: '40px',
      fontWeight: 400,
      fontSize: '18px',
      color: '#404252',
    },
    spacer: {
      width: '12px',
    },
  },
};
