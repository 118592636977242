import { OktaPermissionLevels } from '../okta/OktaRoles';

export const IsRendered = (permissionLevel, userPermissions) => {
  if (userPermissions !== null) {
    try {
      let userHighestPermissionLevel = OktaPermissionLevels.sort(
        (a, b) => b.level - a.level
      ).find((permissionDetail) =>
        permissionDetail.permissions.some((permission) =>
          userPermissions.includes(permission)
        )
      ).level;
      if (userHighestPermissionLevel >= permissionLevel) return true;
      else return false;
    } catch (error) {
      console.log(error);
    }
  }
  return false;
};
