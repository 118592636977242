const Zoom = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.3593 7.97273C11.4843 8.09775 11.5546 8.26731 11.5546 8.44411C11.5546 8.62091 11.4843 8.79047 11.3593 8.91548C11.2343 9.0405 11.0647 9.11073 10.8879 9.11073H9.11027V10.8884C9.11027 11.0652 9.04004 11.2348 8.91502 11.3598C8.79001 11.4848 8.62045 11.555 8.44365 11.555C8.26685 11.555 8.09729 11.4848 7.97227 11.3598C7.84725 11.2348 7.77702 11.0652 7.77702 10.8884V9.11073H5.99935C5.82255 9.11073 5.65299 9.0405 5.52797 8.91548C5.40295 8.79047 5.33272 8.62091 5.33272 8.44411C5.33272 8.26731 5.40295 8.09775 5.52797 7.97273C5.65299 7.84771 5.82255 7.77748 5.99935 7.77748H7.77702V5.99981C7.77702 5.82301 7.84725 5.65345 7.97227 5.52843C8.09729 5.40341 8.26685 5.33318 8.44365 5.33318C8.62045 5.33318 8.79001 5.40341 8.91502 5.52843C9.04004 5.65345 9.11027 5.82301 9.11027 5.99981V7.77748H10.8879C11.0647 7.77748 11.2343 7.84771 11.3593 7.97273Z" fill="#697077"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.8836 2.9948C10.8538 2.34477 9.66086 1.99985 8.44307 2C7.41166 2.00016 6.39535 2.24789 5.47956 2.72238C4.56377 3.19687 3.77525 3.88425 3.18027 4.72675C2.58529 5.56925 2.20123 6.54226 2.06038 7.56401C1.91952 8.58576 2.02598 9.6264 2.3708 10.5985C2.71563 11.5705 3.28875 12.4456 4.042 13.1502C4.79524 13.8548 5.70661 14.3683 6.69951 14.6475C7.69241 14.9267 8.73783 14.9635 9.74791 14.7548C10.758 14.5461 11.7032 14.0981 12.5042 13.4482L16.8603 17.8035L16.935 17.8684C17.0703 17.9689 17.2386 18.0145 17.4062 17.9959C17.5737 17.9774 17.728 17.896 17.838 17.7682C17.9479 17.6405 18.0054 17.4758 17.9988 17.3074C17.9922 17.1389 17.9221 16.9792 17.8025 16.8604L13.4472 12.5052C14.2147 11.5596 14.6985 10.416 14.8426 9.20676C14.9868 7.99753 14.7853 6.7722 14.2616 5.67278C13.7379 4.57335 12.9134 3.64483 11.8836 2.9948ZM6.48725 3.72229C7.10732 3.46545 7.77191 3.33325 8.44307 3.33325C9.79854 3.33325 11.0985 3.87171 12.057 4.83017C13.0154 5.78864 13.5539 7.08859 13.5539 8.44406C13.5539 9.79953 13.0154 11.0995 12.057 12.0579C11.0985 13.0164 9.79854 13.5549 8.44307 13.5549C7.77191 13.5549 7.10732 13.4227 6.48725 13.1658C5.86718 12.909 5.30377 12.5325 4.82919 12.0579C4.3546 11.5834 3.97815 11.02 3.7213 10.3999C3.46446 9.77981 3.33227 9.11522 3.33227 8.44406C3.33227 7.7729 3.46446 7.10831 3.7213 6.48824C3.97815 5.86817 4.3546 5.30476 4.82919 4.83017C5.30377 4.35559 5.86718 3.97913 6.48725 3.72229Z" fill="#697077"/>
    </svg>
  )
}
export default Zoom;

