import { Grid, Box, Typography } from '@mui/material';
import FlowViewerCard from './FlowViewerCard';
import { Styles } from './styles';

const FlowViewerStyles = Styles.FlowViewerStyles;

const FlowViewer = (props) => {

  const { flowCardData, userPermissions } = props;

  return (
    <>
      <Typography sx={FlowViewerStyles.title}>{flowCardData.title}</Typography>
      <Box sx={Styles.FlowTabsStyle.box}>
        {<Grid container spacing={4}>
          {flowCardData?.flows?.length > 0 && flowCardData?.flows?.map((flow, index) => {
            return (
              <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
                <FlowViewerCard
                  flowName={flowCardData.title}
                  flowSetId={flowCardData.flowSetId}
                  flow={flow}
                  userPermissions={userPermissions}
                />
              </Grid>
            )
          })}
        </Grid>}
      </Box>
    </>
  );
}
export default FlowViewer;