import { useState, useEffect, useRef } from "react";
import { select, scaleLinear, zoomIdentity, zoom } from "d3";
import Axes from "./Scatterplot/Axes";
import Quadrants from './Scatterplot/Quadrants';
import Circles from './Scatterplot/Circles';
import Annotations from './Scatterplot/Annotations';
import Legend from './Scatterplot/Legend';
import Tooltip from "./Scatterplot/Tooltip";

const median = (numbers) => {
  const sorted = Array.from(numbers).sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
      return (sorted[middle - 1] + sorted[middle]) / 2;
  }
  return sorted[middle];
}

export const QUADRANT_COLOR = { 1: '#ECC706', 2: '#45E4B6', 3: '#B482F3', 4: '#FF7A91' }

const MARGIN = { top: 30, right: 30, bottom: 60, left: 60 };

const Scatterplot = ({ data, submitToggle, filteredRetailers, width, height }) => {

  const [nonZeroDataset, setNonZeroDataset] = useState([]);
  const [interactionData, setInteractionData] = useState();
  const [xMedian, setXMedian] = useState(0);
  const [yMedian, setYMedian] = useState(0);
  const [maxX, setMaxX] = useState(0);
  const [maxY, setMaxY] = useState(0);
  const [minX, setMinX] = useState(0);
  const [minY, setMinY] = useState(0);
  
  const svgRef = useRef();

  const [currentGlobalZoomState, setCurrentGlobalZoomState] = useState(zoomIdentity);
  const [currentYZoomState, setCurrentYZoomState] = useState(zoomIdentity);
  const [currentXZoomState, setCurrentXZoomState] = useState(zoomIdentity);

  useEffect(() => {
    const filtered = data.filter(a => a.x !== 0 && a.y !== 0);
    setNonZeroDataset(filtered);
    // RESET ZOOM  
    setCurrentXZoomState(zoomIdentity);
    setCurrentYZoomState(zoomIdentity);
    // Median values
    if (!filteredRetailers.length) {
      const xArr = filtered.map(a => a.x);
      const yArr = filtered.map(a => a.y);
      setXMedian(median(xArr));
      setYMedian(median(yArr));
      setMaxX(Math.max(...xArr));
      setMaxY(Math.max(...yArr));
      setMinX(Math.min(...xArr));
      setMinY(Math.min(...yArr));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;

  // Scales (Centered on the Median)
  const xAdjustment = Math.max(maxX + .05 - xMedian, xMedian + .05 - minX);
  const yAdjustment = Math.max(maxY + 5 - yMedian, yMedian + 5 - minY);
  const newXDomain = [ xMedian - xAdjustment, xMedian + xAdjustment];
  const newYDomain = [ yMedian - yAdjustment, yMedian + yAdjustment];
  const xScale = scaleLinear().domain(newXDomain).range([0, boundsWidth]);
  const yScale = scaleLinear().domain(newYDomain).range([boundsHeight, 0]);

  if (currentXZoomState) {
    const newXScale = currentXZoomState.rescaleX(xScale);
    xScale.domain(newXScale.domain());
  }

  if (currentYZoomState) {
    const newYScale = currentYZoomState.rescaleY(yScale);
    yScale.domain(newYScale.domain());
  }

  useEffect(() => {
    const svg = select(svgRef.current);
    const zoomGlobal = zoom()
      .scaleExtent([1.1, 10])
      .on("zoom", (event) => {
        const { k: newK, x: newX, y: newY } = event.transform;
        const { k: prevK, x: prevX, y: prevY } = currentGlobalZoomState;

        // MAKE SURE YOU CAN'T ZOOM OUT PAST 1:1
        if (newK < 1) return;
        
        setCurrentXZoomState(
          currentXZoomState
            .translate((newX - prevX) / prevK, 0)
            .scale(newK / prevK)
        );
        setCurrentYZoomState(
          currentYZoomState
            .translate(0, (newY - prevY) / prevK)
            .scale(newK / prevK)
        );

        // Keeping track of the previous transform object
        setCurrentGlobalZoomState(event.transform);
      });

    svg.call(zoomGlobal);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    boundsWidth,
    boundsHeight,
    currentXZoomState,
    currentYZoomState,
    currentGlobalZoomState,
    xScale,
    yScale
  ]);

  return (
    <div
      className="relative"
    >
      <div>
        <svg width={width} height={height} ref={svgRef}>
          <g
            transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
            overflow={"visible"}
          >
            <Axes
              xScale={xScale}
              yScale={yScale}
              x={xScale(xMedian)}
              y={yScale(yMedian)}
              width={boundsWidth}
              height={boundsHeight}
            />
          </g>
          <defs>
            <clipPath className="clip-path" id="clip">
              <rect
                x="0"
                y="0"
                width={boundsWidth}
                height={boundsHeight}
              />
            </clipPath>
          </defs>
          <g
            transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
            overflow={"visible"}
            clipPath="url(#clip)"
          >
            <Quadrants 
              x={xScale(xMedian)}
              y={yScale(yMedian)}
              width={boundsWidth}
              height={boundsHeight}
            />
            <Circles
              data={nonZeroDataset}
              xScale={xScale}
              yScale={yScale}
              xMedian={xMedian}
              yMedian={yMedian}
              setInteractionData={setInteractionData}
              QUADRANT_COLOR={QUADRANT_COLOR}
              width={boundsWidth}
              height={boundsHeight}
            />
            <Annotations
              data={nonZeroDataset}
              xScale={xScale}
              yScale={yScale}
            />
          </g>
        </svg>
      </div>
      <div className="mt-[-10px]">
        <Legend
          width={width}
        />
      </div>
      <div className="pointer-events-none">
        <Tooltip interactionData={interactionData} />
      </div>
    </div>
  );
};
export default Scatterplot;