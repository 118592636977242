import * as d3 from "d3";
import styles from "./scatterplot.module.css";

const Circles = ({ data, xScale, yScale, xMedian, yMedian, setInteractionData, QUADRANT_COLOR, boundsWidth, boundsHeight }) => {
  
  const sizeScale = d3.scaleSqrt().domain([0, 32]).range([3, 40]);
  const dotColor = (x, y) => {
    if (y >= yMedian) {
      if (x < xMedian) {
        return QUADRANT_COLOR[1];
      } else {
        return QUADRANT_COLOR[2];
      }
    } else {
      if (x > xMedian) {
        return QUADRANT_COLOR[3];
      } else {
        return QUADRANT_COLOR[4];
      }
    }
  }

  return (
    data.map((d, i) => {
      const size = sizeScale(.6);
      const xPos = xScale(d.x);
      const yPos = yScale(d.y);
      const className = styles.scatterplotCircle;

      return (
        <g
          key={i}
          onMouseMove={() =>
            setInteractionData({
              xPos,
              yPos,
              ...d,
            })
          }
          onMouseLeave={() => setInteractionData(undefined)}
        >
          <path
            d={["M", xPos + 9, yPos + 9, "L", xPos + 15, yPos + 15].join(" ")}
            fill="none"
            stroke="#6D6D6D"
          />
          <circle
            cx={xPos}
            cy={yPos}
            opacity={1}
            fill={dotColor(d.x, d.y)}
            r={size}
            className={className}
          />
        </g>
      );
    })
  );
}
export default Circles;