import React, { memo, useEffect, useState } from 'react';
import NoData from './NoData';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { WppSelect, WppListItem } from '@wppopen/components-library-react';
import { ResponsiveLine } from '@nivo/line';
import { cardContentMargin } from '../../styles'

dayjs.extend(isSameOrAfter);

const INITIALIZE_DATE_INTERVAL_VALUE = 12;

const transformData = (data, range) => {
  return data
    .filter(item => dayjs(item.x).isSameOrAfter(dayjs().subtract(range, 'month')))
    .map(a =>  ({ 'x': dayjs(a.x).format('MM/YYYY'), 'y': a.y }));
}

const VisibilityScore = (props) => {

  const { SeaData, SeoData } = props;

  const [dateRange, setDateRange] = useState(INITIALIZE_DATE_INTERVAL_VALUE);
  const [seaData, setSeaData] = useState([]);
  const [seoData, setSeoData] = useState([]);

  useEffect(() => {
    filter(dateRange);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange])

  const handleChange = (event) => {
    setDateRange(event.detail.value);
  }

  const filter = (range) => {
    const SEA = [];
    if (SeaData) {
      SEA.push(
        { 
          "id": 'SEA',
          "data": transformData(SeaData[0].data, range),
        }
      );
      setSeaData(SEA);
    }

    const SEO = [];
    if (SeoData) {
      SEO.push(
        { 
          "id": 'SEO',
          "data": transformData(SeoData[0].data, range),
        }
      );
      setSeoData(SEO);
    }
  }

  return (
    <>
      <div style={{ width: '100%', height: '50%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: cardContentMargin }}>
          <div className='h2'>
            SEA Visibility Score
          </div>
          <div className='vis-dropdown-wrap'>
            <div style={{width: '150px', marginTop: '8px' }}>
              <WppSelect
                onWppChange={handleChange}
                placeholder="last 12 months"
                value={dateRange}
              >
                <WppListItem value="12" key={12}>
                  <p slot="label">last 12 months</p>
                </WppListItem>
                <WppListItem value="24" key={24}>
                  <p slot="label">last 24 months</p>
                </WppListItem>
              </WppSelect>
            </div>
          </div>
        </div>
        <div style={{ width: '100%', height:'275px', marginTop: '10px' }}>
          {
            seaData?.length
            ?
              <ResponsiveLine
                data={seaData}
                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                xScale={{ type: 'point' }}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: true,
                    reverse: false
                }}
                colors={'#C880B2'}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: 4,
                  }}
                enableGridX={true}
                enableGridY={true}
                gridXValues={[0, .01]}
                gridYValues={[0, .01]}
                lineWidth={3}
                enablePoints={false}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[]}
              />
            : <NoData />
          }
        </div>
      </div>
      <div style={{ width: '100%', height: '50%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: cardContentMargin }}>
          <div className='h2'>
              SEO Visibility Score
          </div>
          <div className='vis-dropdown-wrap'>
            <div style={{width: '150px', marginTop: '8px' }}>
              <WppSelect
                onWppChange={handleChange}
                placeholder="last 12 months"
                value={dateRange}
              >
                <WppListItem value="12" key={12}>
                  <p slot="label">last 12 months</p>
                </WppListItem>
                <WppListItem value="24" key={24}>
                  <p slot="label">last 24 months</p>
                </WppListItem>
              </WppSelect>
            </div>
          </div>
        </div>
        {
          seoData?.length
          ?
            <div style={{ width: '100%', height:'275px', marginTop: '10px' }}>
              <ResponsiveLine
                data={seoData}
                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                xScale={{ type: 'point' }}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: true,
                    reverse: false
                }}
                colors={'#C880B2'}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: 4,
                }}
                enableGridX={true}
                enableGridY={true}
                gridXValues={[0, .01]}
                gridYValues={[0, .01]}
                lineWidth={3}
                enablePoints={false}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[]}
              />
            </div>
          : <NoData />
        }
      </div>
    </>
  )
}
export default memo(VisibilityScore);
