import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Styles } from './styles';

const BasicTooltip = (props) => {

  const { title } = props;

  return (
    <Tooltip title={title}>
      <InfoOutlinedIcon style={Styles.InfoOutlinedIcon}/>
    </Tooltip>
  );
}
export default BasicTooltip
