import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { levelNames } from '../../../okta/OktaRoles.js';
import { WppTabs, WppTab, WppSpinner } from '@wppopen/components-library-react';
import { IsRendered } from '../../../utils/RolesUtils.js';
import { Styles } from '../../../pages/Flow/Components/RFI/styles.js';
import SnackbarComponent from '../../../components/Snackbar';
import { Box, Grid, Autocomplete, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FlowButton from '../Components/common/FlowButton';
import FlowStepper from './Flow/FlowStepper';
import FlowCard from '../Components/common/FlowCard';
import FlowViewer from './Flow/FlowViewer';
import RFIStepper from '../Components/RFI/RFIStepper';
import { TAB } from '../../Flow/Utils/constants.js';

const FlowTabs = ({ handleOpenModal, handleCloseModal, setModalContent, setIsFullModal, userPermissions }) => {
  const { level7, level9, level6 } = levelNames;
  const [currentTab, setCurrentTab] = useState(TAB.FLOWS);
  const [flowCardsOriginal, setFlowCardsOriginal] = useState([]);
  const [flowCardsData, setFlowCardsData] = useState([]);
  const [rfiCardsOriginal, setRfiCardsOriginal] = useState([]);
  const [rfiCardsData, setRfiCardsData] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [flowCreated, setFlowCreated] = useState();
  const [rfiCreatedUpdated, setRfiCreatedUpdated] = useState(1);
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [rerender, setRerender] = useState(false);

  const baseApi = process.env.REACT_APP_API_ENDPOINT;
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getIdToken();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSearchItems = useCallback((isFlows) => {
    if (isFlows) {
      return flowCardsOriginal
        .map((x) => ({ id: x.flowSetId, label: x.title }))
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    } else {
      return rfiCardsOriginal
        .map((x) => ({ id: x.rfiId, label: x.title }))
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    }
  });

  const searchItems = useMemo(() => getSearchItems(currentTab === TAB.FLOWS), [currentTab, getSearchItems]);

  const handleTabChange = (event) => {
    setCurrentTab(event.detail.value);
  };

  const fetchData = useCallback(
    async (url, setData, setDataOriginal) => {
      setSpinnerVisible(true);
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status}`);
        }

        const responseData = await response.json();
        setData(responseData.data);
        setDataOriginal(responseData.data);

        setSpinnerVisible(false);
      } catch (error) {
        setSnackMessage(error.message);
        setSnackOpen(true);
        setSpinnerVisible(false);
      }
    },
    [accessToken]
  );

  const getFlowCardsData = useCallback(() => {
    fetchData(`${baseApi}/flow/fetch_flows`, setFlowCardsData, setFlowCardsOriginal);
  }, [baseApi, fetchData]);

  const getRfiCardsData = useCallback(() => {
    fetchData(`${baseApi}/rfi/fetchall`, setRfiCardsData, setRfiCardsOriginal);
  }, [baseApi, fetchData]);

  const handleSearchChange = (event, value) => {
    if (currentTab === TAB.FLOWS) {
      setFlowCardsData(flowCardsOriginal.filter((card) => card.title.toLowerCase().includes(value.toLowerCase())));
    } else {
      setRfiCardsData(rfiCardsOriginal.filter((card) => card.title.toLowerCase().includes(value.toLowerCase())));
    }
  };

  useEffect(() => {
    if (flowCreated) {
      getFlowCardsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowCreated]);

  useEffect(() => {
    getRfiCardsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfiCreatedUpdated]);

  useEffect(() => {
    getFlowCardsData();
    getRfiCardsData();
  }, [getFlowCardsData, getRfiCardsData]);

  useEffect(() => {
    if (currentTab === TAB.RFIS) {
      getRfiCardsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rerender]);

  const renderFlowCards = () => (
    <>
      <div className='flex w-full justify-end'>
        <FlowButton
          variant={'primary'}
          title={'Create Flow'}
          permissionLevel={level7}
          handleOpenModal={() => {
            setIsFullModal(true);
            handleOpenModal();
          }}
          modalContent={
            <FlowStepper
              handleCloseModal={handleCloseModal}
              rfiCardsData={rfiCardsData}
              setFlowCreated={setFlowCreated}
              flowCardsData={flowCardsData}
            />
          }
          setModalContent={setModalContent}
          userPermissions={userPermissions}
        />
      </div>
      <Box sx={Styles.FlowTabsStyle.box}>
        {userPermissions && IsRendered(level9, userPermissions) && (
          <Grid container spacing={4} className='min-h-[600px]'>
            {spinnerVisible ? (
              <div className={'absolute top-[324px] left-[50%]'}>
                <WppSpinner size="l" />
              </div>
            ) : null}
            {flowCardsData?.length > 0 &&
              flowCardsData?.map((flowCardData, index) => (
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
                  <FlowCard
                    currentTab={currentTab}
                    flowCardData={flowCardData}
                    flowCardsData={flowCardsData}
                    rfiCardsData={rfiCardsData}
                    setModalContent={setModalContent}
                    handleCloseModal={handleCloseModal}
                    setFlowCreated={setFlowCreated}
                    handleOpenModal={() => {
                      handleOpenModal();
                      setIsFullModal(true);
                      setModalContent(<FlowViewer flowCardData={flowCardData} userPermissions={userPermissions} />);
                    }}
                  />
                </Grid>
              ))}
          </Grid>
        )}
      </Box>
    </>
  );

  const renderRfiCards = () => (
    <>
      <div className="flex w-full justify-end">
        <FlowButton
          variant={'primary'}
          title={'Create RFI'}
          permissionLevel={level6}
          handleOpenModal={() => {
            setIsFullModal(true);
            handleOpenModal();
          }}
          modalContent={
            <RFIStepper
              handleCloseModal={handleCloseModal}
              rfiCardsData={rfiCardsData}
              setRfiCreatedUpdated={setRfiCreatedUpdated}
            />
          }
          setModalContent={setModalContent}
          userPermissions={userPermissions}
        />
      </div>
      <div className="flex items-start mt-8">
        {userPermissions && IsRendered(level9, userPermissions) && (
          <Grid container spacing={4}>
            {spinnerVisible ? (
              <div className={'flex-center-all col-1-1 margin-t-196'}>
                <WppSpinner size="l" />
              </div>
            ) : null}
            {rfiCardsData?.length > 0 &&
              rfiCardsData?.map((rfiCardData, index) => (
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
                  <FlowCard
                    flowCardData={rfiCardData}
                    handleOpenModal={() => {
                      handleOpenModal();
                      setModalContent(
                        <RFIStepper
                          handleCloseModal={handleCloseModal}
                          rfiCardsData={rfiCardsData}
                          rfiId={rfiCardData?.rfiId}
                          rfiTitle={rfiCardData?.title}
                          setRfiCreatedUpdated={setRfiCreatedUpdated}
                          isRfiViewMode={rfiCardData?.hasAssociatedFlow > 0}
                        />
                      );
                    }}
                    currentTab={currentTab}
                    handleCloseModal={handleCloseModal}
                    setModalContent={setModalContent}
                    rfiCardsData={rfiCardsData}
                    setRfiCreatedUpdated={setRfiCreatedUpdated}
                    userPermissions={userPermissions}
                    accessToken={accessToken}
                    rerender={rerender}
                    setRerender={setRerender}
                  />
                </Grid>
              ))}
          </Grid>
        )}
      </div>
    </>
  );

  return (
    <>
      <div className="flex w-full items-start">
        <Autocomplete
          id="free-solo-search"
          freeSolo={true}
          onInputChange={handleSearchChange}
          options={searchItems}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={currentTab === TAB.FLOWS ? 'Search Flows' : 'Search RFIs'}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                },
                '& .MuiAutocomplete-inputRoot': {
                  width: '270px',
                  height: '38px',
                  paddingTop: '0 !important', // Adjust as needed
                },
              }}
            />
          )}
        />
      </div>

      <div className='flex w-full'>
        <WppTabs value={currentTab} onWppChange={handleTabChange} style={Styles.FlowTabsStyle.tabs}>
          <WppTab value={TAB.FLOWS}>Flow Dashboard</WppTab>
          <WppTab value={TAB.RFIS}>RFI Dashboard</WppTab>
        </WppTabs>
      </div>

      {currentTab === TAB.FLOWS ? renderFlowCards() : renderRfiCards()}

      <SnackbarComponent snackOpen={snackOpen} setSnackOpen={setSnackOpen} message={snackMessage} />
    </>
  );
};
export default FlowTabs;
