export const FLOW_STEPS = ['Select RFI', 'Flow Name + Admins', 'Select Partner + Partner Admins', 'Review Information'];

export const STATUS_LABELS = {
  NOT_STARTED: 'Not Started',
  INSIGHTS_SENT_TO_PARTNER_ADMIN: 'Insight Sent to Partner Admin',
  READY_FOR_SCORING: 'Ready for Scoring',
  SCORING_COMPLETE: 'Scoring Complete',
  READY_FOR_APPROVAL: 'Ready for Approval',
  APPROVED: 'Approved',
};

export const FLOW_STATUS = {
  [-1]: STATUS_LABELS.NOT_STARTED,
  0: STATUS_LABELS.INSIGHTS_SENT_TO_PARTNER_ADMIN,
  1: STATUS_LABELS.READY_FOR_SCORING,
  2: STATUS_LABELS.SCORING_COMPLETE,
  3: STATUS_LABELS.READY_FOR_APPROVAL,
  4: STATUS_LABELS.APPROVED,
};

export const READY_FOR_REVIEW = {
  ACTIVE_STEP: 3,
};

export const RFI_STEPS = ['Select RFI Type', 'RFI Builder', 'Review'];

export const RFI_OPTIONS = {
  NEW_RFI: {
    value: 'newRFI',
    label: 'Create New RFI',
  },
  EXISTING_RFI: {
    value: 'existingRFI',
    label: 'Clone Existing RFI',
  },
};

export const CATEGORIES = [
  'API',
  'Audio',
  'CTV',
  'Digital OOH',
  'General',
  'Off-Property Display',
  'On-Property Display',
  'On-Property Search',
  'Social',
];

export const SUBCATEGORIES = [
  'Access',
  'Administrative',
  'Buying Model',
  'Cost',
  'Creative',
  'Data',
  'Depth',
  'Expose Below Questions?',
  'General',
  'Integrations',
  'Measurement',
  'Optimization',
  'Pricing',
  'Reporting',
  'Scale',
  'Security',
  'Tagging and Tracking',
  'Technical',
];

export const INPUT_TYPES = ['File Upload', 'Freeform', 'Multi Select', 'Multi Select Other Option', 'Single Select'];

export const INPUT_TYPE = {
  File_Upload: 'File Upload',
  Freeform: 'Freeform',
  Multi_Select: 'Multi Select',
  Multi_Select_Other_Option: 'Multi Select Other Option',
  Single_Select: 'Single Select',
};

export const WEIGHTS = ['1', '2', '3', '4', '5'];

export const IS_MANDATORY_OPTIONS = ['Yes', 'No'];

export const TAB = {
  FLOWS: 'flows',
  RFIS: 'rfis',
};

export const COLUMN_HEADERS = {
  CATEGORY: 'category',
  SUBCATEGORY: 'subcategory',
  INPUT_TYPE: 'inputtype',
  QUESTION: 'question',
  ANSWER: 'Answer',
};

export const NUMBER_DATE_FORMAT = 'MM/DD/YYYY';
export const WPPOPEN_LOCALE_DATE_FORMAT = 'MM/dd/yyyy';
