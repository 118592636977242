import React from 'react';
import { WppCard, WppToggle } from '@wppopen/components-library-react';
import ArrowsVertical from '../../../assets/ArrowsVertical';
import DropSelectWppOpenByID from '../../../../components/DropSelectWppOpenByID';
import './card.css';

const GrowthPotential = (props) => {

  const {
    similarWebStatus,
    setSimilarWebStatus,
    eCommerceDBStatus,
    setECommerceDBStatus,
    categories,
    selectedCategories,
    setSelectedCategories } = props;

  return (
    <WppCard
      size={'2xl'}
      style={{ width: '641px', height: '330px' }}
      className="CARD"
    >
      <div className="ml-[-20px] mt-[-10px]">
        <ArrowsVertical />
      </div>
      <div className="ml-[-20px] text-base font-semibold mt-2 flex items-center">
        Growth Potential
      </div>
      <div className="ml-[-20px] text-sm font-normal text-[#4D5358] w-[600px]">
        The opportunity for your brand to grow online in a chosen market and the ideal investment required to reach that growth.​ 
      </div>
      <div className="ml-[-20px] text-sm font-normal text-[#4D5358] w-[600px]">
        Select <span className="text-sm font-semibold text-[#121619]">One or both</span> of the following sources.
      </div>
      <hr className="ml-[-20px] divide-y-2 divide-solid divide-gray-700 mt-3" />
      <div className="ml-[-20px] mt-3">
        <div className="flex justify-between w-[600px]">
          <div className="flex items-center">
            <div className="text-sm font-normal">Similar Web</div>
            <div className="ml-2 text-gray-500 font-bold text-xs">(Monthly traffic value updated 15th of every month)</div>
          </div>
          <WppToggle
            checked={similarWebStatus}
            name="optionsSimilarWeb"
            onWppChange={({ detail: { checked } }) => setSimilarWebStatus(checked)}
          />
        </div>
        <div className="flex justify-between mt-2 w-[600px]">
          <div className="flex items-center">
            <div className="text-sm font-normal">ECommerceDB</div>
            <div className="ml-2 text-gray-500 font-bold text-xs"> (Data from the last full year (2023))</div>
          </div>
          <WppToggle
            checked={eCommerceDBStatus}
            name="optionsECommerceDB"
            onWppChange={({ detail: { checked } }) => setECommerceDBStatus(checked)}
          />
        </div>
        <div className="mt-2 w-[600px]">
          <DropSelectWppOpenByID 
            type="single"
            title="Categories"
            className="text-[14px]"
            items={categories}
            id="id"
            value="name"
            selection={selectedCategories}
            setSelection={setSelectedCategories}
            placeholder="Categories"
          />
        </div>
      </div>
    </WppCard>
  )
}
export default GrowthPotential;