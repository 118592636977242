/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react';
import { useFormContext, Controller, useFieldArray } from "react-hook-form"
import { WppActionButton, WppIconAddCircle } from '@wppopen/components-library-react'
import SelectPartners_Admin from './SelectPartners_Admin';
import { Styles } from './styles'

const SelectPartners = (props) => {
  const { rfiId, baseApi, accessToken, partnersResponders, setPartnersResponders, isSpinnerSpinning, setIsSpinnerSpinning, flows } = props;

  const { control, formState, setValue, getValues } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'PartnerAdmins',
    shouldUnregister: false,
  });

  const { errors } = formState;

  const SelectPartners = Styles.SelectPartners;
  const PARTNER_ADMINS = Styles.SelectPartners.PARTNER_ADMINS;

  const columns = ['Partners', 'Responder', 'Partner Admin Email'];
  
  const handleAddPartner = () => {
    append({ partnerId: '', responder: '', email: [] });
  };

  useEffect(() => {
    if (flows && getValues()?.PartnerAdmins?.length === 0) {
      const list = flows.map(({ partner, responder, responderEmailAddress, flowId }) => { 
        return { partnerId: +partner, responder: +responder, email: responderEmailAddress, flowId }
      })
      setValue('PartnerAdmins', list);
    }
    // eslint-disable-next-line 
  }, [flows])

  useEffect(() => {
    if (!fields.length && !isSpinnerSpinning && partnersResponders.length && rfiId && !flows) {
      append({ partnerId: '', responder: '', email: null });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.length, isSpinnerSpinning, partnersResponders.length, rfiId]);


  useEffect(() => {
    if (!partnersResponders.length && typeof baseApi !== "undefined" && typeof accessToken !== "undefined") {
      setIsSpinnerSpinning(true);

      fetch(`${baseApi}/partner`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
      })
        .then(response => response.json())
        .then(data => {
          setPartnersResponders(data.data);
          setIsSpinnerSpinning(false);
        })
        .catch((error) => {
          console.log('API FETCH ERROR: ', error);
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnersResponders, baseApi, accessToken, rfiId]);

  return (
    <>
      <table style={PARTNER_ADMINS.table}>
        <tbody>
          {(!isSpinnerSpinning && !!fields.length) && (
            <tr style={PARTNER_ADMINS.headerRow}>
              {columns.map((item, index) => (
                <React.Fragment key={index}>
                  <td>{item}</td>
                  <td style={PARTNER_ADMINS.spacer} />
                </React.Fragment>
              ))}
              <td />
            </tr>
          )}
          {partnersResponders?.length > 0 && fields.map((item, index) => (
            <SelectPartners_Admin
              key={index}
              disabled={flows?.length && index < flows?.length}
              Controller={Controller}
              control={control}
              item={item}
              index={index}
              remove={remove}
              errors={errors}
              partnersResponders={partnersResponders}
            />
          ))}
        </tbody>
      </table>
      <div style={SelectPartners.addPartner}>
        <WppActionButton onClick={handleAddPartner}>
          <WppIconAddCircle slot='icon-start' variant="inverted" />
          Add Partners
        </WppActionButton>
      </div>
      <div style={PARTNER_ADMINS.zeroCountFeedback}>{errors.PartnerAdmins?.message}</div>
    </>
  )
}
export default SelectPartners;