import {
  WppButton,
  WppIconAdd,
  WppIconTrash,
  WppInput,
  WppListItem,
  WppModal,
  WppSelect,
} from '@wppopen/components-library-react';
import React from 'react';

const ModalCreateProject = (props) => {
  const {
    modalTitle,
    isModalOpen,
    setIsModalOpen,
    projectTitle,
    setProjectTitle,
    ctaTitle,
    setCurrentCards,
    users,
    setUsers,
    fetchData,
    userEmail,
    userName,
    setFetchTrigger,
    currProjectId
  } = props;
  const userRoles = ['Project Editor', 'Admin'];
  const [currentEmail, setCurrentEmail] = React.useState('');

  const handleEmailChange = (e) => {
  };

  const handleEmailBlur = (e) => {
    setCurrentEmail(e.target.value);
  };

  const addEmail = () => {
    if (users.find((user) => user.email === currentEmail)) {
    } else if (!currentEmail.includes('@')) {
    } else {
      setUsers([...users, { email: currentEmail, role: 'Project Editor' }]);
    }
  };

  const removeEmail = (event) => {
    let em = event.target.parentElement.parentElement.parentElement.firstChild.innerText;
    setUsers(users.filter((user) => user.email !== em));
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setProjectTitle('');
    setUsers([]);
  };

  const handleRoleChange = (event) => {
    let em = event.target.parentElement.parentElement.parentElement.firstChild.innerText;
    setUsers(users.map((user) => (user.email === em ? { ...user, role: event.target.value } : user)));
  };

  const handleCreateProject = () => {
    if (projectTitle !== '') {
      setIsModalOpen(false);
      setUsers([]);
      setProjectTitle('');
      setCurrentCards([]);
      const np = {
        name: projectTitle,
        users: [...users],
        createdBy: { emailAddress: userEmail, name: userName },
      };
      if (ctaTitle === 'Save Changes') {
        fetchData(`/project/update/${currProjectId}`, 'POST', np);
      } else fetchData('/project/create', 'POST', np);
    }
    setFetchTrigger((prev) => !prev);
  };

  return (
    <WppModal open={isModalOpen} size={'m'} style={{ '--wpp-modal-vertical-position-minus-number': '64px' }}>
      <h3 slot="header">{modalTitle}</h3>
      <div slot="body">
        <div className={'w-full mt-2'}>
          <WppInput
            name="project"
            required
            labelConfig={{
              text: 'Enter project name',
            }}
            placeholder="Project Name"
            value={projectTitle}
            onWppChange={(e) => setProjectTitle(e.target.value)}
            disabled={ctaTitle === 'Save Changes'}
          />
        </div>
        <div className={'w-full mt-6 mb-14'}>
          <WppInput
            name="email"
            required
            labelConfig={{ text: 'Add people' }}
            placeholder="Email"
            value=""
            onWppChange={handleEmailChange}
            onWppBlur={handleEmailBlur}
          >
            <WppIconAdd slot="icon-end" aria-label="Clear icon" onClick={addEmail} />
          </WppInput>
        </div>
        <div className={'h-auto'}>
          {Object.keys(users).map((user, index) => (
            <div className={'flex flex-row items-center mt-2'} key={index}>
              <div className={'w-1/2'}>{users[user].email}</div>
              <div className={'w-1/2 flex flex-row items-center justify-end'}>
                <div className={'w-full mr-2'}>
                  <WppSelect onWppChange={handleRoleChange} placeholder="Placeholder" value={users[user].role}>
                    {userRoles.map((role, index) => (
                      <WppListItem key={index} value={role}>
                        <p slot="label">{role}</p>
                      </WppListItem>
                    ))}
                  </WppSelect>
                </div>
                <div
                  className={
                    'cursor-pointer h-[40px] border-[1px] flex items-center px-2 border-gray-400 rounded-[6px] hover:bg-gray-100'
                  }
                  onClick={removeEmail}
                >
                  <WppIconTrash aria-label="Clear icon" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div slot="actions" className={'flex justify-end mt-4'}>
        <div className={'mr-2'}>
          <WppButton variant="secondary" size="s" onClick={handleCloseModal}>
            Close
          </WppButton>
        </div>
        <div>
          <WppButton variant="primary" size="s" onClick={handleCreateProject} disabled={!projectTitle || users.length === 0}>
            {ctaTitle}
          </WppButton>
        </div>
      </div>
    </WppModal>
  );
};

export default ModalCreateProject;
