const Annotations = ({ data, xScale, yScale }) => {
  return data.map((d, i) => {
    const xText = xScale(d.x) + 15;
    const yText = yScale(d.y) + 25;

    return (
      <g key={i} >
        <text
          x={xText}
          y={yText}
          fontSize={12}
          fontWeight={500}
          fill="#4D5358"
          textAnchor={"start"}        // horizontal alignment
          dominantBaseline={"middle"} // vertical alignment
        >
          {d.name}
        </text>
      </g>
    );
  });
}
export default Annotations;