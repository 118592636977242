import _ from 'lodash';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import RenderExpandableCell from '../common/RenderExpandableCell';
import { useOktaAuth } from "@okta/okta-react";
import { IsRendered } from "../../../../utils/RolesUtils.js";
import { levelNames } from "../../../../okta/OktaRoles.js";
import { DataGridPro, GridToolbar, useGridApiRef, GridColumnMenu } from "@mui/x-data-grid-pro";
import { WppSelect, WppListItem, WppButton } from "@wppopen/components-library-react";
import { Typography } from "@mui/material";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SnackbarComponent from '../../../../components/Snackbar';
import useAuthUser from "../../../../components/AuthUser";
import { CATEGORIES, SUBCATEGORIES, COLUMN_HEADERS } from '../../Utils/constants';
import '../../../../stylesheets/InsightRtmV2Page.css'
import '../../../../stylesheets/InsightPage.css'
import { ModalSpinner } from "../../../../components/ModalSpinner";
import { fileDownloader } from '../../../utils';
import { Styles } from './styles';

const editable = true;

const baseApi = process.env.REACT_APP_API_ENDPOINT;

const FlowScoringStyles = Styles.FlowScoringStyles;

// Swap positions of filter and sort items
const CustomColumnMenu = (props) => {
  return (
    <GridColumnMenu
      {...props}
      slotProps={{
        columnMenuFilterItem: {
          displayOrder: 0,
        },
        columnMenuSortItem: {
          displayOrder: 10,
        },
      }}
    />
  );
}

const FlowScoring = (props) => {

  const { flowName, flowSetId, task, userPermissions } = props;
  const { level10 } = levelNames;

  const { authState, oktaAuth } = useOktaAuth();
  const userInfo = useAuthUser();
  const accessToken = oktaAuth.getIdToken();

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [gridEdited, setGridEdited] = useState(false);
  // const [dataSaved, setDataSaved] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("Changes made, don't forget to save them!");
  const [selectedVersion, setSelectedVersion] = useState(task.version);

  // The most recently touched/edited entityId
  const entityIdRef = useRef('');
  const dataSave = useRef({});

  const screenHeight = window.innerHeight * 0.60;


  const getRowId = (row) => {
    return row.entityId;
  }

  const generateVersionList = (version) => {
    const number = parseInt(version);
    if (isNaN(number)) {
      return []; // Return an empty array if the input is not a valid number
    }
    return Array.from({ length: number }, (_, index) => (number - index).toString());
  }

  const handleFileDownload = (pathName) => {
    try {
      fetch(`${baseApi}/storage/download`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
        body: JSON.stringify({
          filePath: pathName
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data?.data)
            fileDownloader(data?.data)
        })
    } catch (error) {
      console.log('API FETCH ERROR: ', error);
    }
  }


  const versionList = generateVersionList(task.version);

  useEffect(() => {
    if (selectedVersion) {
      setGridEdited(false);
      setShowLoader(true);

      try {
        fetch(`${baseApi}/flow/flow-set-id/${flowSetId}/flow-id/${task.flowId}?version=${selectedVersion}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
        })
          .then(response => response.json())
          .then(data => {
            if (data.data.columns?.length && data.data.rows?.length) {
              let columnsModified = data.data.columns.map((column) => {
                if (column.field === "score") {
                  return {
                    ...column, editable: editable, type: "singleSelect", valueOptions: [0, 1, 2, 3, 4, 5], disableColumnMenu: true,
                  }
                } else if (column.headerName === COLUMN_HEADERS.CATEGORY) {
                  return {
                    ...column, width: 150, type: "singleSelect", valueOptions: CATEGORIES
                  }
                } else if (column.headerName === COLUMN_HEADERS.SUBCATEGORY) {
                  return {
                    ...column, width: 150, type: "singleSelect", valueOptions: SUBCATEGORIES
                  }
                } else if (column.headerName === COLUMN_HEADERS.QUESTION) {
                  return {
                    ...column, width: 250, disableColumnMenu: true,
                  }
                } else if (column.headerName === COLUMN_HEADERS.INPUT_TYPE) {
                  return {
                    ...column, width: 200, disableColumnMenu: true,
                  }
                } else if (column.headerName === COLUMN_HEADERS.ANSWER) {
                  return {
                    ...column, editable: false, width: 350, disableColumnMenu: true,
                    renderCell: (params) => {
                      if (Object.keys(params.row).some(key => params.row[key] === 'File Upload')) { // Check if the row has a file upload
                        return (
                          <div onClick={() => handleFileDownload(params.value)} key={params.id} style={{ display: 'flex', alignItems: 'center' }}>
                            <span>{params.value.split('/').pop().replace(/^"|"$/g, '')}</span>
                            <SaveAltIcon style={{ color: '#0014CC', cursor: 'pointer', marginLeft: '10px', width: '20px', height: '20px' }} />
                          </div >
                        );
                      } else {
                        return <RenderExpandableCell {...params} />
                      }
                    }
                  }
                }
                else
                  return {
                    ...column, disableColumnMenu: true,
                  }
              })

              setColumns(columnsModified);
              setRows(data.data.rows);
              setLoaded(true);
              setShowLoader(false)
            }
          })
      } catch (error) {
        console.log('API FETCH ERROR: ', error);
      }
    }
    // eslint-disable-next-line
  }, [task, accessToken, flowSetId, selectedVersion])

  useEffect(() => {
    if (gridEdited) {
      setSnackOpen(true)
    }
    else {
      setSnackOpen(false)
    }
  }, [gridEdited])

  const processRowUpdate = (newRow, oldRow) => {
    const updatedRow = { ...newRow, isNew: false };
    if (_.get(oldRow, entityIdRef.current) !== _.get(newRow, entityIdRef.current)) {
      dataSave.current[entityIdRef.current + ',' + newRow.entityId] = newRow[entityIdRef.current];
      setGridEdited(true);
      // setDataSaved(false);
    }
    return updatedRow;
  };

  const updateDB = (e) => {
    let data = [];
    _.forOwn(dataSave.current, (value, key) => {
      const field_entityId = key.split(',');
      data.push({ score: value, entityId: parseInt(field_entityId[1]) })
    });

    try {
      fetch(`${baseApi}/flow/flow-set-id/${flowSetId}/flow-id/${task.flowId}/save?version=${selectedVersion}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
        body: JSON.stringify({
          "rows": data,
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.Message === 'Success') {
            setGridEdited(false);
            // setDataSaved(true);
            entityIdRef.current = '';
            dataSave.current = {};
          } else {
            console.log('Error: ', data);
          }
        })
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  const approve = () => {
    let user = { name: userInfo?.name, email: userInfo?.email }

    try {
      fetch(`${baseApi}/flow/flow-set-id/${flowSetId}/flow-id/${task.flowId}/approve`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
        body: JSON.stringify({
          "data": { 'isApproved': true },
          "user": user,
          "access_token": oktaAuth.getIdToken,
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.Message === 'Approved') {
            setSnackbarMessage("APPROVED!");
            setSnackOpen(true);
          } else {
            console.log('Error: ', data);
          }
        })
    } catch (e) {
      console.log('Error: ', e)
    }
  }

  const apiRef = useGridApiRef();

  const onCellClick = (params) => {

    if (params.isEditable && params.cellMode !== 'edit') {
      apiRef.current.startCellEditMode({ id: params.id, field: params.field });
    }
  }

  const onCellEditStop = (params, event) => {
    entityIdRef.current = params.field;
  }

  const onProcessRowUpdateError = useCallback((err) => {
    console.log("Error: ", err);
  }, []);

  const handleVersionChange = (e) => {
    setSelectedVersion(e.target.value);
  }

  const renderScores = () => {
    if (authState?.isAuthenticated)
      return (<>
        <div style={FlowScoringStyles.gridParent(screenHeight)}>
          <div className={'grid-wrap'} style={FlowScoringStyles.grid}>
            {isLoaded &&
              <DataGridPro
                className={showLoader ? FlowScoringStyles.loadingState : FlowScoringStyles.loadedState}
                slots={{
                  Toolbar: GridToolbar,
                  columnMenu: CustomColumnMenu,
                }}
                getRowId={getRowId}
                rows={rows}
                columns={columns}
                hideFooterRowCount={true}
                hideFooterSelectedRowCount={true}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      entityId: false,
                    },
                  },
                }}
                isCellEditable={(params) => (editable && (params.field === 'score'))}
                onCellEditStop={onCellEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={onProcessRowUpdateError}
                disableSelectionOnClick
                disableColumnSelector
                rowHeight={54}
                autoHeight={false}
                onCellClick={onCellClick}
                apiRef={apiRef}
                experimentalFeatures={{ newEditingApi: true }}
                sx={{
                  ...FlowScoringStyles.gridDataPro(screenHeight),
                  "& .MuiDataGrid-menuIcon": {
                    visibility: "visible",
                    width: "auto"
                  },
                }}
              />
            }
          </div>
          <div style={FlowScoringStyles.saveButton}>
            <WppButton disabled={!gridEdited} onClick={updateDB}>Save Changes</WppButton>
            {(userPermissions && IsRendered(level10, userPermissions)) && <WppButton onClick={approve}>Approve</WppButton>
            }          </div>
        </div>
        <div>
          <SnackbarComponent
            snackOpen={snackOpen}
            setSnackOpen={setSnackOpen}
            message={snackbarMessage}
          />
        </div>
      </>)
  }

  return (
    <>
      <Typography sx={FlowScoringStyles.title}>{flowName}</Typography>
      <Typography sx={FlowScoringStyles.partnerResponder}>{task?.partnerName} | {task?.responderName}</Typography>

      {showLoader ? (
        <ModalSpinner />
      ) : null}

      {<div style={FlowScoringStyles.dropdown}>
        <WppSelect
          onWppChange={handleVersionChange}
          placeholder="Select Version"
          withSearch
          labelConfig={{ text: 'Version' }}
          required
          value={selectedVersion}
        >
          {versionList?.map(version => (
            <WppListItem value={version} key={version}>
              <p className="text-[16px]" slot="label">{version}</p>
            </WppListItem>
          ))}
        </WppSelect>
      </div>}
      {renderScores()}

    </>
  );
}

export default FlowScoring;
