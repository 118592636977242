import * as yup from 'yup';
import dayjs from 'dayjs';
import { RFI_OPTIONS } from './constants';

export const FlowValidationSchema = (flowCardsData, flowSetId = '') => [
  // Validation for step1
  yup.object({
    RFI: yup.string().required(),
  }),

  // Validation for step2
  yup.object({
    FlowName: yup
      .string()
      .required('Must select a new Flow name')
      .test({
        message: 'Flow name should be unique',
        test: (value) => {
          if (flowSetId) {
            return !flowCardsData.some(
              ({ flowSetId: id, title }) => title.toLowerCase() === value.toLowerCase() && id !== flowSetId
            );
          } else {
            return flowCardsData.every(({ title }) => title.toLowerCase() !== value.toLowerCase());
          }
        },
      }),

    FlowAdmins: yup
      .array()
      .min(1, 'Please add a Flow Admin...')
      .of(
        yup.object({
          name: yup.string().required('Name is required'),
          email: yup.string().email('Invalid email format').required('Email is required'),
          role: yup.string().required('Role is required'),
        })
      )
      .test('unique-admins', 'Duplicate combination of Name, Email and Role', function (value) {
        const flowAdminSet = new Set();
        for (let i = 0; i < value.length; i++) {
          const { name, email, role } = value[i];
          const key = `${name}-${email}-${role}`;
          if (flowAdminSet.has(key)) {
            return false; // Duplicate combination found
          }
          flowAdminSet.add(key);
        }
        return true; // All combinations are unique
      })
      .required(),

    accessWindowStartDate: yup.string().required('Select the start date'),
    accessWindowEndDate: yup.string().when('accessWindowStartDate', {
      is: (value) => !!value,
      then: () =>
        yup
          .string()
          .required('Select the end date')
          .test({
            message: 'The "To" date must not be before the start date.',
            test: (value, props) => {
              const { accessWindowStartDate } = props.parent;
              return !dayjs(value).isBefore(dayjs(accessWindowStartDate), 'day');
            },
          }),
    }),
  }),

  // Validation for step3
  yup.object({
    PartnerAdmins: yup
      .array()
      .min(1, 'Please add a Partner...')
      .of(
        yup.object({
          partnerId: yup.string().required('Partner is required'),
          responder: yup.string().required('Responder is required'),
          email: yup
            .array()
            .of(yup.string().email('Invalid email format'))
            .min(1, 'At least one email is required')
            .required('At least one email is required'),
        })
      )
      .test('unique-admins', 'Duplicate combination of Partner, Responder', function (value) {
        const partnerResponderSet = new Set();
        for (let i = 0; i < value.length; i++) {
          const { partnerId, responder } = value[i];
          const key = `${partnerId}-${responder}`;
          if (partnerResponderSet.has(key)) {
            return false; // Duplicate combination found
          }
          partnerResponderSet.add(key);
        }
        return true; // All combinations are unique
      })
      .required(),
  }),
];

export const RFIValidationSchema = (rfiCardsData) => [
  //validation for step1
  yup.object({
    RFIType: yup.string().required(),
    RFIExisting: yup
      .object()
      .when('RFIType', {
        is: (value) => value === RFI_OPTIONS.EXISTING_RFI.value,
        then: () => yup.object().required('Must select a RFI'),
      })
      .nullable(),
    clonedRFIName: yup
      .string()
      .when('RFIType', {
        is: (value) => value === RFI_OPTIONS.EXISTING_RFI.value,
        then: () =>
          yup
            .string()
            .required('Must select a new RFI name')
            .test({
              message: 'RFI name should be unique',
              test: (value) => rfiCardsData.every(({ title }) => title.toLowerCase() !== value.toLowerCase()),
            }),
      })
      .nullable(),
    newRFIName: yup
      .string()
      .when('RFIType', {
        is: (value) => value === RFI_OPTIONS.NEW_RFI.value,
        then: () =>
          yup
            .string()
            .required('Must select a new RFI name')
            .test({
              message: 'RFI name should be unique',
              test: (value) => rfiCardsData.every(({ title }) => title.toLowerCase() !== value.toLowerCase()),
            }),
      })
      .nullable(),
  }),
];

export const RFIEditValidationSchema = (rfiId, rfiCardsData) => [
  //validation for step1
  yup.object({
    newRFIName: yup
      .string()
      .required('Must select a new RFI name')
      .test({
        message: 'RFI name should be unique',
        test: (value) =>
          !rfiCardsData.some(({ rfiId: id, title }) => title.toLowerCase() === value.toLowerCase() && id !== rfiId),
      }),
  }),
];
