import { CSSProperties } from 'react';

export const FONT_FAMILY = "Gilroy,Gotham,'Helvetica Neue',Arial,Helvetica,sans-serif";

export const ROW_DISPLAY: CSSProperties = { display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }

export const HEADER_STYLES: { [key: string]: CSSProperties } = {
  badgeFusionLogo: {
    height: '53px',
    width: '53px',
    margin: '0',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '24px 0 0 33px',
  },
  headerTitleStyle: {
    margin: '15px 0 0 7px',
    fontFamily: 'PT serif',
    fontSize: '28px',
    fontWeight: '400',
    color: '#404252',
    lineHeight: '40px',
    whiteSpace: 'pre',
  },
};

export const UPDATE_VIEW_STYLES = {
  active: {
    background: '#00078f',
    color: 'white',
    padding: '12px 16px',
    borderRadius: '8px',
    minWidth: '196px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '500',
    cursor: 'pointer',
  },
  disabled: {
    background: '#DFDFDF',
    cursor: 'default',
  }
}

