import React, { useEffect } from 'react';
import { useFormContext, Controller } from "react-hook-form"
import { WppSelect, WppListItem } from '@wppopen/components-library-react';
import '../../../stylesheets/SelectFlow.css'

const SelectFlow = (props) => {

  const { insightId, flowData, selectedFlowId, setSelectedFlowId } = props;

  const { control, formState, getValues } = useFormContext()

  useEffect(() => {
    if (getValues().FlowSetId) {
      setSelectedFlowId(getValues().FlowSetId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues().FlowSetId])


  const handleChange = (event) => {
    setSelectedFlowId(event.target.value)
  }

  return (
    <>
      <div className="w-auto rounded-lg border-solid border-2 border-gray-200 h-40">
        <div className="text-xl font-semibold mb-6 mt-6 ml-6">Attach Existing Flow</div>
        <div className="w-80 ml-6">
          <Controller
            control={control}
            name="FlowSetId"
            render={({ field: { onChange, onBlur, value, error } }) => (
              <WppSelect
                onWppChange={(e) => {
                  handleChange(e);
                  onChange(e);
                }}
                disabled={!!insightId}
                placeholder="Select"
                required
                value={selectedFlowId}
                onBlur={onBlur}
                message={formState.errors?.FlowSetId?.message}
                messageType="error"
              >
                {flowData?.map(flow => (
                  <WppListItem value={flow.flowSetId} key={flow.flowSetId}>
                    <p slot="label">{flow.title}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            )}
          />
        </div>
      </div>
    </>
  )
}
export default SelectFlow;