import { FLOW_STATUS, STATUS_LABELS } from './constants';

export const FlowStepsPerStatus = (status) => {
  switch (FLOW_STATUS[status]) {
    case STATUS_LABELS.INSIGHTS_SENT_TO_PARTNER_ADMIN:
      return {
        items: [
          STATUS_LABELS.INSIGHTS_SENT_TO_PARTNER_ADMIN,
          STATUS_LABELS.READY_FOR_SCORING,
          STATUS_LABELS.READY_FOR_APPROVAL,
        ],
        activeStep: 1,
      };
    case STATUS_LABELS.READY_FOR_SCORING:
      return {
        items: [
          STATUS_LABELS.INSIGHTS_SENT_TO_PARTNER_ADMIN,
          STATUS_LABELS.READY_FOR_SCORING,
          STATUS_LABELS.READY_FOR_APPROVAL,
        ],
        activeStep: 1,
      };
    case STATUS_LABELS.SCORING_COMPLETE:
      return {
        items: [
          STATUS_LABELS.INSIGHTS_SENT_TO_PARTNER_ADMIN,
          STATUS_LABELS.SCORING_COMPLETE,
          STATUS_LABELS.READY_FOR_APPROVAL,
        ],
        activeStep: 2,
      };
    case STATUS_LABELS.READY_FOR_APPROVAL:
      return {
        items: [
          STATUS_LABELS.INSIGHTS_SENT_TO_PARTNER_ADMIN,
          STATUS_LABELS.SCORING_COMPLETE,
          STATUS_LABELS.READY_FOR_APPROVAL,
        ],
        activeStep: 2,
      };
    case STATUS_LABELS.APPROVED:
      return {
        items: [STATUS_LABELS.INSIGHTS_SENT_TO_PARTNER_ADMIN, STATUS_LABELS.SCORING_COMPLETE, STATUS_LABELS.APPROVED],
        activeStep: 3,
      };
    default:
      return {
        items: [
          STATUS_LABELS.INSIGHTS_SENT_TO_PARTNER_ADMIN,
          STATUS_LABELS.READY_FOR_SCORING,
          STATUS_LABELS.READY_FOR_APPROVAL,
        ],
        activeStep: status ? 0 : -1,
      };
  }
};
