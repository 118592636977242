import React, { memo, useEffect, useState } from 'react';
import NoData from './NoData';
import dayjs from 'dayjs';
import { ResponsiveBar } from '@nivo/bar'
import { cardContentMargin } from '../../styles'

const TrafficAnalytics = (props) => {

  const { TrafficAnalyticsData } = props;

  const [data, setData] = useState([]);

  useEffect(() => {
    if (TrafficAnalyticsData) {
      setData(TrafficAnalyticsData.map(a =>  ({ 'month': dayjs(a.month).format('MM/YYYY'), 'value': a.value })));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div style={{ margin: cardContentMargin }}>
        <div className='h2'>
          Traffic Analytics
        </div>
        <div className='h5' style={{ marginTop: '4px' }}>
          Traffic Data By Similarweb
        </div>
        <div className='h6' style={{ marginTop: '4px' }}>
          MONTHLY VISITS IN THOUSANDS (K)
        </div>
      </div>
      {
        data?.length
        ?
          <div style={{ width: '100%', height:'250px', marginTop: '20px' }}>
            <ResponsiveBar
              data={data}
              keys={[
                'value'
              ]}
              indexBy="month"
              colors='#C880B2'
              margin={{ top: 35, right: 0, bottom: 35, left: 0 }}
              padding={0.2}
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              theme={{
                axis: {
                  ticks: {
                    text: {
                      fontSize: 18, // Label text size
                      fill: "#444444"
                    }
                  }
                },
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
              }}
              axisLeft={null}
              enableGridY={true}
              gridYValues={[0, .01]}
              enableLabel={false}
              layers={[
                'grid',
                'axes',
                'bars',
                'markers',
                'legends',
                'annotations',
                ({ bars }) => {
                  return (
                    // labels above the bars
                    <g>
                      {bars.map(({ width, height, x, y, data, key }) => {
                        return (
                          <text
                            transform={`translate(${width / 2 + x - 10}, ${y - 15})`}
                            style={{ fontSize: '20px', fill: '#444444' }}
                            key={key}
                          >
                            {`${data.value ?? ''}`}
                          </text>
                        );
                      })}
                    </g>
                  );
                },
            ]}
            />
          </div>
        : <div style={{ height: '60%' }}><NoData /></div>
      }
    </div>
  )
}
export default memo(TrafficAnalytics)
