import { useState } from 'react';
import dayjs from 'dayjs';
import React from 'react';
import RFIStepper from '../RFI/RFIStepper';
import { IsRendered } from "../../../../utils/RolesUtils.js";
import { levelNames } from "../../../../okta/OktaRoles.js";
import {
  WppCard,
  WppTypography,
  WppProgressIndicator,
  WppIconMore,
  WppMenuContext,
  WppActionButton,
  WppListItem,
  WppTooltip,
  WppModal,
  WppButton 
} from '@wppopen/components-library-react';
import FlowStepper from '../Flow/FlowStepper';
import SnackbarComponent from '../../../../components/Snackbar';
import { ModalSpinner } from "../../../../components/ModalSpinner";
import { TAB } from '../../Utils/constants';
import { Styles } from '../Flow/styles';
import "../../../../stylesheets/FlowCard.css";

const FlowCard = (props) => {

  const {
    flowCardData,
    handleOpenModal,
    handleCloseModal,
    setModalContent,
    rfiCardsData,
    setRfiCreatedUpdated,
    setFlowCreated,
    flowCardsData,
    currentTab,
    userPermissions,
    accessToken,
    rerender,
    setRerender,
   } = props;

  const baseApi = process.env.REACT_APP_API_ENDPOINT;
  const { level10 } = levelNames;

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [isSpinnerSpinning, setIsSpinnerSpinning] = useState(false);

  const FlowCardStyle = Styles.FlowCardStyle;

  const handleCardMenu = (e) => {
    e.stopPropagation()
  }

  const handleRFIDelete = (e) => {
    setIsSpinnerSpinning(true);
    fetch(`${baseApi}/rfi/${flowCardData.rfiId}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'Authorization': `Bearer ${accessToken}` },
    })
      .then(response => response.json())
      .then(data => {
        if (data) {
          setIsSpinnerSpinning(false);
          setConfirmDeleteOpen(false);
        }
      })
      .catch((error) => {
        setIsSpinnerSpinning(false);
        setSnackMessage(error.message);
        setSnackOpen(true);
      });
    
    setRerender(!rerender);
  }

  return (
    <>
      {isSpinnerSpinning ? (
        <ModalSpinner/>
       ) : null}

      <WppCard interactive={true} size={'2xl'} onClick={flowCardData?.hasAssociatedFlow > 0 || currentTab === TAB.FLOWS ? handleOpenModal : null}>
        <div slot="header">
          <WppTypography type="2xl-heading" style={FlowCardStyle.header}>
            {flowCardData?.title?.length >= 15 ? <WppTooltip text={flowCardData?.title}>
              <span>{`${flowCardData?.title.slice(0, 15)}...`}</span>
            </WppTooltip> :
              <span>{flowCardData?.title}</span>}
          </WppTypography>
        </div>

        <div slot="actions" style={FlowCardStyle.actions}>
          <WppMenuContext onClick={handleCardMenu}>
            <WppActionButton slot="trigger-element" variant="secondary">
              <WppIconMore slot="icon-start" direction='horizontal' />
            </WppActionButton>
            <div style={FlowCardStyle.listItems}>
              <WppListItem onWppChangeListItem={() => {
                handleOpenModal();
                const modalContent = (currentTab === TAB.RFIS) 
                  ?
                    <RFIStepper
                      handleCloseModal={handleCloseModal}
                      rfiCardsData={rfiCardsData}
                      rfiId={flowCardData?.rfiId}
                      rfiTitle={flowCardData?.title}
                      setRfiCreatedUpdated={setRfiCreatedUpdated} 
                      editMode={true}
                      hasAssociatedFlow={flowCardData.hasAssociatedFlow}
                    />
                  : <FlowStepper
                      handleCloseModal={handleCloseModal}
                      rfiCardsData={rfiCardsData}
                      setFlowCreated={setFlowCreated}
                      flowCardsData={flowCardsData}
                      flowCardData={flowCardData}
                    />;
                setModalContent(modalContent);
              }}>
                <p slot="label">Edit</p>
              </WppListItem>
              {
                (((userPermissions && IsRendered(level10, userPermissions)) || currentTab === TAB.RFIS) && !flowCardData.hasAssociatedFlow) && (
                  <WppListItem onWppChangeListItem={() => {
                    setConfirmDeleteOpen(true);
                  }}>
                    <p slot="label">Delete</p>
                  </WppListItem>
                )
              }
            </div>
          </WppMenuContext>
        </div>
        

        <hr style={FlowCardStyle.cardLine} />
        <div className={'col-1-1 flex-direction-row'}>
          <div className={'col-1-2 flex-direction-column'}>
            <div style={FlowCardStyle.cardSx}>Created by</div>
            <div style={FlowCardStyle.cardM}>{flowCardData?.createdBy?.name}</div>
          </div>
          <div className={'col-1-2 flex-direction-column'}>
            <div style={FlowCardStyle.cardSx}>Created Date</div>
            <div style={FlowCardStyle.cardM}>{dayjs(flowCardData?.createdDate).format('l')}</div>
          </div>
        </div>
        <div className={'col-1-1 flex-direction-row'}>
          <div className={'col-1-2 flex-direction-column'}>
            <div style={FlowCardStyle.cardSx}>Modified by</div>
            <div style={FlowCardStyle.cardM}>{flowCardData?.modifiedBy?.name}</div>
          </div>
          <div className={'col-1-2 flex-direction-column'}>
            <div style={FlowCardStyle.cardSx}>Last Modified</div>
            <div style={FlowCardStyle.cardM}>{dayjs(flowCardData?.lastModified).format('l')}</div>
          </div>
        </div>
        {flowCardData?.progress !== 0 && flowCardData?.progress !== undefined ? <WppProgressIndicator
          width={'100%'}
          is-show-percentage={false}
          variant="bar"
          value={flowCardData?.progress * 100}
          style={FlowCardStyle.progressIndicator}>
        </WppProgressIndicator> : flowCardData?.progress === 0 ? <div className='hideProgressBar'></div> : <></>}
      </WppCard >
      <WppModal open={confirmDeleteOpen} wppModalClose={() => setConfirmDeleteOpen(false)}>
        <h3 slot="header">Delete RFI</h3>
        <p slot="body">Are you sure you want to delete <span className="font-semibold">{flowCardData.title}</span>?</p>
        <div slot="actions" className="flex justify-end">
          <WppButton variant="secondary" size="s" onClick={() => setConfirmDeleteOpen(false)}>Cancel</WppButton>
          <WppButton variant="destructive" size="s" onClick={handleRFIDelete} className="ml-4">Delete</WppButton>
        </div>
      </WppModal>
      <SnackbarComponent
        snackOpen={snackOpen}
        setSnackOpen={setSnackOpen}
        message={snackMessage}
      />
    </>
  )
};

export default FlowCard;