import React, { memo, useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import {
  DataGridPro,
  GridRow,
  GridColumnHeaders,
  GridRenderCellParams,
  GridColumnMenu,
  getGridDefaultColumnTypes,
} from '@mui/x-data-grid-pro';
import { Paper, Popper, Typography } from '@mui/material';
import { ReportTypeSpecificData} from './utils/utils';

const MemoizedRow = memo(GridRow);
const MemoizedColumnHeaders = memo(GridColumnHeaders);

const renderCellExpand = (params: GridRenderCellParams<any, string>) => {
  return (
    <GridCellExpand
      value={params.value || ''}
      width={params.colDef.computedWidth}
    />
  );
}

const defaultColumnTypes = getGridDefaultColumnTypes();

const wrapOperator = (operator) => {
  const getApplyFilterFn = (filterItem, column) => {
    const innerFilterFn = operator.getApplyFilterFn(filterItem, column);
    if (!innerFilterFn) {
      return innerFilterFn;
    }

    return (params) => {
      if (params.formattedValue.includes(filterItem.value)) {
        return true;
      }
      return false;
    };
  };

  return {
    ...operator,
    getApplyFilterFn,
  };
};

export const columns = [
  {
    field: 'brand',
    headerName: 'Brand',
    width: 110,
    headerClassName: 'data-grid',
  },
  {
    field: 'asin',
    headerName: 'ASIN',
    width: 130,
    headerClassName: 'data-grid',
  },
  {
    field: 'itemName',
    headerName: 'Product Title',
    headerClassName: 'data-grid',
    renderCell: renderCellExpand,
    width: 350,
  },
  {
    field: 'classification',
    headerName: 'Classification',
    headerClassName: 'data-grid',
    // renderCell: (params: GridRenderCellParams) => (
    //   <strong>
    //     <Button
    //       variant="contained"
    //       size="small"
    //       style={{ marginLeft: 16 }}
    //       tabIndex={params.hasFocus ? 0 : -1}
    //     >
    //       {params.row.classificationRanksTitle}
    //     </Button>
    //   </strong>
    // ),
    width: 275,
  },
  {
    field: 'salesRank',
    headerName: 'Sales Rank',
    headerClassName: 'data-grid',
    width: 145,
  },
  {
    field: 'price',
    headerName: 'Price',
    headerClassName: 'data-grid',
    width: 140,
  },
  {
    field: 'glanceViews',
    headerName: 'Traffic',
    headerClassName: 'data-grid',
    width: 140,
  },
  {
    field: 'revenue',
    headerName: 'Revenue',
    headerClassName: 'data-grid',
    width: 140,
  },
  {
    field: 'conversionRate',
    headerName: 'Conversion Rate',
    headerClassName: 'data-grid',
    width: 180,
    valueFormatter: ({ value }) =>
      `${Math.round((Number(value * 100) + Number.EPSILON) * 100) / 100} %`, // Up to 2 decimal places
    filterOperators: defaultColumnTypes.string.filterOperators.filter(a => a.value === 'contains').map((operator) => wrapOperator(operator)),
  },
  {
    field: 'sellableOnHandInventoryUnits',
    headerName: 'On Hand Inventory',
    headerClassName: 'data-grid',
    width: 200,
  },
  {
    field: 'netPpm',
    headerName: 'Net PPM',
    headerClassName: 'data-grid',
    width: 140,
    valueFormatter: ({ value }) =>
      `${Math.round((Number(value * 100) + Number.EPSILON) * 100) / 100} %`, // Up to 2 decimal places
      filterOperators: defaultColumnTypes.string.filterOperators.filter(a => a.value === 'contains').map((operator) => wrapOperator(operator)),
  },
];

const CustomColumnMenu = (props) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsItem: null,
        columnMenuPinningItem: null,
      }}
      slotProps={{
        columnMenuFilterItem: {
          displayOrder: 0, // Previously `10`
        },
        columnMenuSortItem: {
          displayOrder: 10, // Previously `0`
        },
      }}
    />
  );
}

const DataGrid = (props) => {
  const { data, reportType } = props;
  const [columnVisibility, setColumnVisibility] = useState({});

  useEffect(() => {
    setColumnVisibility(ReportTypeSpecificData(reportType).HiddenColumns);
  }, [reportType]);

  return (
    <Box sx={{ height: 440, width: '100%' }}>
      <DataGridPro
        sx={{ borderColor: '#EEE', borderRadius: '4px' }}
        columns={columns}
        rows={data}
        getRowClassName={() => 'avenir-next'}
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 25]}
        rowHeight={58}
        disableRowSelectionOnClick
        components={{
          Row: MemoizedRow,
          ColumnHeaders: MemoizedColumnHeaders,
          ColumnMenu: CustomColumnMenu,
        }}
        componentsProps={{
          panel: {
            sx: {
              top: '-165px !important',
            },
          },
        }}
        columnVisibilityModel={columnVisibility}
      />
    </Box>
  );
};

export default DataGrid;

// ===============================
// DataGrid Cell Hover Popup Section

const isOverflown = (element: Element): boolean => {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = memo(function GridCellExpand(
  props
) {
  const { width, value } = props;
  const wrapper = useRef(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: '100%',
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});


