import { ResponsivePie } from '@nivo/pie'

const MyResponsivePie = ({ data }) => (
    <ResponsivePie
      data={data}
      margin={{ top: 65, right: 55, bottom: 50, left: 85 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      colors={{ scheme: 'nivo' }}
      borderColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            0.2
          ]
        ]
      }}
      arcLinkLabel={(e) => (
        <>
          <tspan x="0" dy="-0.7em">{e.id}</tspan>
          <tspan x={e.arc.angle} dy="1.2em">{`(${e.value}%)`}</tspan>
        </>
      )}
      arcLinkLabelsTextOffset={8}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsDiagonalLength={20}
      arcLinkLabelsStraightLength={15}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      enableArcLabels={false}
      arcLabelsRadiusOffset={0}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            2
          ]
        ]
      }}
      legends={[]}
    />
)
export default MyResponsivePie;