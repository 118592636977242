
import { ResponsiveTreeMapHtml } from '@nivo/treemap'

const COLOR_OPTIONS = ['#08260C', '#115019', '#197625', '#219C31', '#2AC63F', '#46D859', '#6CE07B', '#9EEBA8', '#CCF4D2', '#E6FAE8'];

const color = (val) => {
  if ( val >= 90) {
    return COLOR_OPTIONS[0];
  } else if (val < 90 && val >= 80) {
    return COLOR_OPTIONS[1];
  } else if (val < 80 && val >= 70) {
    return COLOR_OPTIONS[2];
  } else if (val < 70 && val >= 60) {
    return COLOR_OPTIONS[3];
  } else if (val < 60 && val >= 50) {
    return COLOR_OPTIONS[4];
  } else if (val < 50 && val >= 40) {
    return COLOR_OPTIONS[5];
  } else if (val < 40 && val >= 30) {
    return COLOR_OPTIONS[6];
  } else if (val < 30 && val >= 20) {
    return COLOR_OPTIONS[7];
  } else if (val < 20 && val >= 10) {
    return COLOR_OPTIONS[8];
  } else if (val < 10) {
    return COLOR_OPTIONS[9];
  }
}

const MyResponsiveTreeMap = ({ data }) => (
  <ResponsiveTreeMapHtml
    data={data}
    identity="name"
    orientLabel={false}
    value="val"
    valueFormat=" >-,.2s"
    leavesOnly={true}
    margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
    label={e => {
      let color;
      if (e.data.val >= 50) {
        color = '#FFFFFF'
      } else {
        color = '#000000'
      }
      return <div style={{ display: 'flex', flexDirection: 'column' }}><div style={{ color }}>{e.id}</div><div style={{ color }}>{`${e.formattedValue}%`}</div></div>
    }}
    labelSkipSize={12}
    labelTextColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          2.5
        ]
      ]
    }}
    enableParentLabel={false}
    colors={a => color(a.data.val)}
    nodeOpacity={0.8}
    borderColor="#ffffff"
  />
)
export default MyResponsiveTreeMap;
