export const MuiDatePickerTitle = {
  fontSize: '14px',
  fontWeight: 600,
  margin: '0 0 8px 0',
  color: '#666',
};

export const MuiDatePickerGeneral = {
  '& .MuiInputBase-input': {
    height: '24px',
    textAlign: 'left',
    borderRadius: '24px',
  },
  '& .MuiInputBase-root': {
    borderRadius: '8px',
    borderColor: 'black',
    fontSize: '14px',
    maxWidth: '230px',
  },
  '& .MuiInputBase-root:hover': {
    background: '#f2f2f2',
  },
  '& .MuiSvgIcon-root': {
    width: '20px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#999',
  },
};

export const WppOpenOverrides = {
  '.single-select-input': {
    width: '230px !important',
  },
};

export const SpinnerStyles = {
  '.mainSpinner': {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '66px',
    left: '80px',
    background: 'linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,1))',
    zIndex: 100,
    minHeight: '620px',
    width: '100%',
  },
};
