import React from "react";
import {WppFullScreenModal, WppModal, WppIconClose} from '@wppopen/components-library-react';
import { Styles } from './styles';
import "../stylesheets/FlowCard.css"

const Modal = (props) => {
  
  const { isModalOpen, handleCloseModal, modalContent, setModalContent, isFullModal, customStyle } = props;

  return (
    <>
    {isFullModal ?
      <WppFullScreenModal
        open={isModalOpen}
        disableOutsideClick={false}
          style={customStyle}
          className="full-screen-modal"
      >
        <div slot="body">{modalContent}</div>
        <div slot="actions">
          <WppIconClose
            width={'24px'}
            style={Styles.ModalStyle.iconClose}
            onClick={() => {
              setModalContent(null);
              handleCloseModal();
            }}
          />
        </div>
      </WppFullScreenModal>
      :
      <WppModal
        open={isModalOpen}
        disableOutsideClick={false}
      >
        <div slot="body">{modalContent}</div>
        <div slot="actions">
          <WppIconClose
            width={'24px'}
            style={Styles.ModalStyle.iconClose}
            onClick={() => {
              setModalContent(null);
              handleCloseModal();
            }}
          />
        </div>
      </WppModal>
      }
    </>
  )
};

export default Modal;